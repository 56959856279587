import React, { FC } from "react";
import { Grid, Typography } from "utils/material-ui-core";
import CollectionCardDetails from "component-collection-card-details";
import { CollectionCardProps } from "./";
import RHImage from "component-rh-image";
import useMediaString from "hooks/useMediaString";
import { ProductImagePresetKeys } from "utils/getImageUrlWithPreset";
import useRHstyles from "hooks/useRHStyles";
import yn from "yn";
import { useEnv } from "hooks/useEnv";
import { useFetchModel } from "hooks/useFetchModel";
import classNames from "classnames";

export const LandscapeFull: FC<CollectionCardProps> = ({
  data,
  linkUrl,
  readonly,
  handleOnSwatchClick,
  categorySwatchImage,
  categoryImageLoading
}) => {
  const mediaString = useMediaString();
  const env = useEnv();
  const rhStyles = useRHstyles({
    keys: ["collectionGalleryItemContainer"]
  });
  const { pageContent } = useFetchModel("/admin/collections", true, false);
  const isCGColorizationEnabled = yn(env.FEATURE_CG_COLORIZATION);
  // No aspect ratio is give to the image as we don't have any way of knowing
  // what the aspect ratio image is going to be given the data we have.
  // Note the difference between the Modern sofa collections and RH rug collections
  return (
    <div className={`max-w-[1150px] relative`}>
      {!data.colorizable && isCGColorizationEnabled && (
        <div
          className={`
                  absolute
                  flex
                  justify-center
                  items-center
                  w-full
                  right-0
                  left:0
                  h-[15%]
                  bg-black-40
                  z-[99]
            `}
        >
          <Typography
            className={`
                    !uppercase
                    !text-[#fff]
                    !font-primary-rhthin
                    !text-[12px]
                    `}
          >
            {pageContent?.COLOR_PREVIEW_AVAILABLE_SOON ||
              "Color preview available soon"}
          </Typography>
        </div>
      )}
      {/* {categoryImageLoading && data.colorizable && isCGColorizationEnabled && (
        <div
          style={{
            width: "100%",
            height: "100%"
          }}
        >
          <div
            className="cardImages"
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              position: "relative",
              alignItems: "center"
            }}
          >
            <RHSpinner />
          </div>
        </div>
      )} */}
      {!readonly && !categoryImageLoading && (
        <RHImage
          src={categorySwatchImage || data.cgBannerImage}
          alt={data.displayName}
          preset={`cg-banner-full-${mediaString}` as ProductImagePresetKeys}
          width="100%"
          loadingBehavior="eager"
        />
      )}
      <Grid
        container
        direction="column"
        justify="space-around"
        data-cg="flex-column-text"
        className={classNames([
          rhStyles.collectionGalleryItemContainer,
          "absolute max-w-[400px] top-0 bottom-0"
        ])}
      >
        <Grid container item>
          <CollectionCardDetails
            data={data}
            linkUrl={linkUrl}
            handleOnSwatchClick={handleOnSwatchClick}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default LandscapeFull;
