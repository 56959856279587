import React, { FC, useEffect, useRef, useMemo } from "react";
import he from "he";
import {
  IconButton,
  Theme,
  Typography,
  Grid,
  useMediaQuery
} from "utils/material-ui-core";
import { Hidden, processEnvServer } from "hooks/useSsrHooks";
import useState from "hooks/useState";
import PlayIcon from "icon-play";
import RHZoomInIcon from "icon-zoomin";
import useParams from "hooks/useParams";
import useMediaString from "hooks/useMediaString";
import { ProductImagePresetKeys } from "utils/getImageUrlWithPreset";
import { IMAGE_ASPECT_RATIO } from "utils/constants";
import RHImage from "component-rh-image";
import { RHImageContext } from "component-rh-image/RHImageContext";
import memoize from "utils/memoize";
import useTypographyStyles from "hooks/useTypographyStyles";
import cn from "classnames";
import yn from "yn";
import { useEnv } from "hooks/useEnv";
import ImageSkeleton from "skeleton-image";

import RHCarousel from "component-rh-carousel";
// *** create ssr hook for rhcarousel
import PreviousArrow from "component-rh-carousel/PreviousArrow";
import NextArrow from "component-rh-carousel/NextArrow";
import IframeDialog from "dialog-iframe";
import ZoomViewerDialog from "dialog-zoom-viewer";
import ImageThumbnailList from "component-image-thumbnail-list";
import { usePageContent } from "customProviders/LocationProvider";

export interface HeroImageProps {
  images: ProductAlternateImage[] | null; // Noticed that it can happen that null gets passed into images prop in production
  isColorizable?: boolean;
  objectFit?: string;
  selectedSwatchImageUrl?: string;
  productSwatchLoading?: boolean;
  selectedOptionLabel?: boolean;
  selectedLineItemOptionLabel?: boolean;
  onIndexChange?: () => void;
  isRHR?: boolean;
  isBannerVisible?: boolean;
  isSwatchSwitch?: number;
  saleSwatchId?: string;
  selectedSwatch?: ProductSwatch | null;
  changeSwatchImageOnLineItem?: React.Dispatch<React.SetStateAction<string>>;
  imagePresetOverride?: string | null;
}

export const HeroImage: FC<HeroImageProps> = ({
  images,
  isColorizable,
  objectFit,
  selectedSwatchImageUrl,
  productSwatchLoading,
  selectedOptionLabel,
  selectedLineItemOptionLabel,
  onIndexChange,
  isRHR,
  isBannerVisible,
  isSwatchSwitch = 0,
  saleSwatchId,
  selectedSwatch,
  changeSwatchImageOnLineItem,
  imagePresetOverride
}) => {
  const { pageContent } = usePageContent();
  const env = useEnv();
  const mediaString = useMediaString();
  const [swatchImageUrl, setSwatchImageUrl] = useState<string | undefined>("");
  const [isHeroImageUrlFailed, setIsHeroImageUrlFailed] =
    useState<boolean>(false);

  // index is undefined to prevent selection on thumbnail
  const [activeIndex, setActiveIndex] = useState<number | undefined>(0);
  const [isZoomViewerDialogOpen, setIsZoomViewerDialogOpen] = useState(false);
  const [video, setVideo] = useState<string>("");
  const [more, setMore] = useState(false);
  const [needMore, setNeedMore] = useState(false);
  const captionRef = useRef<HTMLSpanElement>(null);
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const isRHRImage = yn(env.FEATURE_RHR_IMAGE);
  const [isLoading, setIsLoading] = useState<boolean>(
    processEnvServer ? false : true
  );

  const isRHRImageStyling = useMemo(
    () => isRHRImage && smDown && isRHR,
    [isRHRImage, isRHR, smDown]
  );

  let [imageList, setImageList] = useState<ProductAlternateImage[] | []>([
    {
      imageUrl: "",
      caption: "",
      video: ""
    }
  ]);

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseCaption", "rhBaseBody1"]
  });
  if (processEnvServer && images?.length) {
    imageList = images;
  }
  useEffect(() => {
    if (images?.length) {
      setImageList(images);
      setIsLoading(false);
    }
  }, [images]);

  const params = useParams({
    fullSkuId: ""
  });

  if (!processEnvServer) {
    useEffect(() => {
      if (!needMore && captionRef.current) {
        const captionStyle = window.getComputedStyle(captionRef.current);
        const lineHeight = parseInt(captionStyle.lineHeight);
        const height = parseInt(captionStyle.height);

        if (lineHeight > 0 && height > lineHeight) {
          setNeedMore(true);
        }
      }
    }, [captionRef, needMore]);
  }

  useEffect(() => {
    // set swatch undefined if swatch is inactive
    if (!selectedSwatchImageUrl) {
      setSwatchImageUrl(undefined);
    } else {
      // set index 0 for page load when swatch is active
      setActiveIndex(0);
      // set swatch if active
      if (selectedSwatchImageUrl !== (images || [])[0]?.imageUrl) {
        setSwatchImageUrl(selectedSwatchImageUrl);
        changeSwatchImageOnLineItem &&
          changeSwatchImageOnLineItem(selectedSwatchImageUrl);
      } else if (selectedSwatchImageUrl === (images || {})[0]?.imageUrl) {
        setSwatchImageUrl((images || [])[0]?.imageUrl);
        changeSwatchImageOnLineItem &&
          changeSwatchImageOnLineItem((images || [])[0]?.imageUrl);
      }
    }
  }, [selectedSwatchImageUrl]);

  const activeImage = imageList[activeIndex || 0];

  const handleOnIndexChange = (index: any) => {
    if (swatchImageUrl && onIndexChange) {
      onIndexChange();
    }
    setActiveIndex(index);
  };

  const handleOnPreviousArrowClick = (index: number) => {
    const i = index - 1;
    if (i >= 0) {
      setActiveIndex(i);
    } else {
      setActiveIndex(imageList.length - 1);
    }
    if (!processEnvServer) {
      document.body.dispatchEvent(
        new CustomEvent("pre-pdp-click", {
          detail: {
            item: {
              action: "Alt Caurosel - Prev Arrow"
            }
          }
        })
      );
    }
  };
  const handleOnNextArrowClick = (index: number) => {
    const i = index + 1;
    if (i <= imageList.length - 1) {
      setActiveIndex(i);
    } else {
      setActiveIndex(0);
    }
    if (!processEnvServer) {
      document.body.dispatchEvent(
        new CustomEvent("pre-pdp-click", {
          detail: {
            item: {
              action: "Alt Caurosel - Next Arrow"
            }
          }
        })
      );
    }
  };
  const productImageUrl = React.useMemo(() => {
    if (isHeroImageUrlFailed) {
      // return default image available soon url when url gets failed to load
      //return "//media.restorationhardware.com/is/image/rhis/4KH?src=ir(rhir/prod27210510_E22519282534_TQ_CO22_RHR?src=rhir/obj=Fabric&src=rhir/1649004_E2219536411_O_Bsq_reapeat&res=300&illum=0&obj=Fabric&req=object&illum=0)&$PDP-IR-1600$";
      return (images || [])[0]?.imageUrl;
    }
    return swatchImageUrl || (images || [])[0]?.imageUrl;
  }, [swatchImageUrl, isHeroImageUrlFailed, images]);

  const swatchSwitchImageUrl = () => {
    if (activeIndex || activeIndex === 0) {
      return activeImage?.imageUrl;
    }
    if (
      !isColorizable &&
      isBannerVisible &&
      (isSwatchSwitch > 1 || selectedLineItemOptionLabel)
    ) {
      return (images || [])[0]?.imageUrl;
    }
    if (Boolean(isSwatchSwitch > 0)) {
      return swatchImageUrl || activeImage?.imageUrl;
    }
    return (images || [])[0]?.imageUrl;
  };
  const showImageCaption = useMemo(() => {
    return (
      (saleSwatchId &&
        selectedSwatch?.swatchId &&
        saleSwatchId === selectedSwatch.swatchId) ||
      ((isSwatchSwitch < 1 ||
        (isSwatchSwitch > 0 &&
          typeof activeIndex === "number" &&
          activeIndex !== 0)) &&
        !selectedLineItemOptionLabel)
    );
  }, [
    saleSwatchId,
    selectedSwatch,
    isSwatchSwitch,
    activeIndex,
    selectedLineItemOptionLabel
  ]);
  return (
    <Grid container id={"component-hero-image"}>
      <Grid
        item
        xs={12}
        className={`${isRHRImageStyling ? "m-auto w-[87%]" : ""} ${
          isRHRImage && isRHR
            ? "relative pt-0 pb-4 px-4 sm:pt-0 sm:pb-6 sm:px-6 md:pt-0 md:pb-4 md:px-0 print:hidden"
            : "relative pb-4 print:hidden"
        }`}
      >
        <div
          className={`relative mt-4 w-full ${
            isRHRImageStyling ? "m-auto" : ""
          } pdpImageWrapper`}
        >
          {!processEnvServer ? (
            <Hidden mdUp={mdUp}>
              <RHCarousel
                index={activeIndex}
                onChangeIndex={index => {
                  setActiveIndex(index);
                }}
              >
                {imageList?.map((image, index) => (
                  <RHImage
                    key={`productImage-${index}`}
                    data-testid={"mobile-pdp-image"}
                    loadingBehavior={index === 0 ? "eager" : "lazy"}
                    objectFit={isRHRImageStyling ? "contain" : ""}
                    setIsHeroImageUrlFailed={setIsHeroImageUrlFailed}
                    failedImageUrl={
                      isHeroImageUrlFailed ? (images || [])[0]?.imageUrl : ""
                    }
                    src={
                      index === 0 &&
                      swatchImageUrl &&
                      imageList === images &&
                      isColorizable
                        ? swatchImageUrl
                        : imageList[index]?.imageUrl
                    }
                    alt={image?.caption || image?.imageUrl}
                    preset={
                      `${
                        imagePresetOverride?.length
                          ? imagePresetOverride
                          : "pdp-hero"
                      }-${mediaString}` as ProductImagePresetKeys
                    }
                    onClick={() => {
                      if (image.video) {
                        setVideo(
                          image.video.includes(".com")
                            ? image.video // scene 7 url
                            : image.video.split("&")[0] // youtube id
                        );
                      }
                    }}
                    aspectRatio={IMAGE_ASPECT_RATIO["heroImage"]}
                  >
                    {image.video !== "" && (
                      <PlayIcon
                        className={
                          "absolute left-0 right-0 top-0 bottom-0  m-auto text-white text-[60px] pointer-events-none"
                        }
                      />
                    )}
                  </RHImage>
                ))}
              </RHCarousel>
            </Hidden>
          ) : (
            ""
          )}
          <Hidden smDown={processEnvServer ? false : smDown}>
            <div className="[&>.carouselArrow]:invisible [&>.carouselArrow]:hover:visible">
              {images && images?.length > 1 && (
                <PreviousArrow
                  className={"carouselArrow"}
                  onClick={() => handleOnPreviousArrowClick(activeIndex || 0)}
                />
              )}
              {images && images?.length > 1 && (
                <NextArrow
                  className={"carouselArrow"}
                  onClick={() => handleOnNextArrowClick(activeIndex || 0)}
                />
              )}
              {isLoading || productSwatchLoading ? (
                <ImageSkeleton aspectRatio={IMAGE_ASPECT_RATIO["heroImage"]} />
              ) : (
                <RHImage
                  key={`productImage-${activeIndex}-${productImageUrl}`}
                  data-testid={"desktop-pdp-image"}
                  objectFit={objectFit}
                  loadingBehavior="eager"
                  src={
                    (activeIndex === 0 || activeIndex === undefined) &&
                    (productImageUrl as string) &&
                    isColorizable
                      ? (productImageUrl as string)
                      : swatchSwitchImageUrl()
                  }
                  alt={activeImage?.caption || activeImage?.imageUrl}
                  preset={
                    `${
                      imagePresetOverride?.length
                        ? imagePresetOverride
                        : "pdp-hero"
                    }-${mediaString}` as ProductImagePresetKeys
                  }
                  onClick={() => {
                    if (activeImage.video) {
                      setVideo(
                        activeImage.video.includes(".com")
                          ? activeImage.video // scene 7 url
                          : activeImage.video.split("&")[0] // youtube id
                      );
                    }
                  }}
                  aspectRatio={IMAGE_ASPECT_RATIO["heroImage"]}
                  imageSkeletonBackgroundColor="#f9f7f4"
                  imageWrapperStyles={{ backgroundColor: "#f9f7f4" }}
                  setIsHeroImageUrlFailed={setIsHeroImageUrlFailed}
                  failedImageUrl={
                    isHeroImageUrlFailed ? (images || [])[0]?.imageUrl : ""
                  }
                >
                  {activeImage.video !== "" && (
                    <PlayIcon
                      className={
                        "absolute left-0 right-0 top-0 bottom-0 m-auto text-white text-[60px] pointer-events-none"
                      }
                    />
                  )}
                </RHImage>
              )}
            </div>
          </Hidden>

          {(!isColorizable &&
            isBannerVisible &&
            (isSwatchSwitch > 1 || selectedLineItemOptionLabel)) ||
          ((selectedSwatchImageUrl == "" || isHeroImageUrlFailed) &&
            isSwatchSwitch > 0) ? (
            <div
              className={
                "absolute top-0 w-full h-16 bg-black-40 flex items-center justify-center"
              }
            >
              <Typography
                className={cn(typographyStyles.rhBaseBody1, "!text-white")}
              >
                {pageContent?.COLOR_PREVIEW_AVAILABLE_SOON}
              </Typography>
            </div>
          ) : null}
        </div>
        <IconButton
          className="!absolute bottom-2 right-2 z-20 !bg-transparent"
          onClick={() => {
            setIsZoomViewerDialogOpen(true);
          }}
          disableRipple
        >
          <RHZoomInIcon className="!h-8 !w-8" />
        </IconButton>
        {!!video && !processEnvServer && (
          <IframeDialog
            src={
              video.includes(".com")
                ? undefined
                : `https://www.youtube.com/embed/${video}?modestbranding=1&rel=0&showinfo=0&autoplay=1&controls=0`
            }
            srcDoc={
              video.includes(".com")
                ? `<div style="
                width: 100%;
                height: calc(100vh - 56px);
                display: flex;
                align-items: center;
                justify-content: center;
            "><video autoplay name="media" loop style="
            object-fit: scale-down;
            width: inherit;
        "><source src="${video}" type="video/mp4"></video></div>`
                : undefined
            }
            open={!!video}
            onClose={() => setVideo("")}
          />
        )}
        <ZoomViewerDialog
          images={imageList}
          index={activeIndex}
          selectedSwatchImageUrl={
            images === imageList ? swatchImageUrl : imageList[0]?.imageUrl
          }
          open={isZoomViewerDialogOpen}
          onClose={() => setIsZoomViewerDialogOpen(false)}
        />
      </Grid>
      <Grid item xs={12} className="!pt-0 !min-h-[2em]">
        {activeIndex === 0 && swatchImageUrl && images !== imageList ? null : (
          <>
            {activeImage?.caption && (
              <Grid
                item
                onClick={() => {
                  if (needMore) {
                    setMore(!more);
                  }
                }}
                xs={true}
                className={`overflow-hidden !mr-4 !mb-4 md:!ml-0 sm:!ml-[30px] !ml-4
               ${
                 needMore && !more
                   ? `flex items-baseline [&>.text]:flex-1]`
                   : ""
               }
                `}
              >
                {/* Hide image caption when it's a SKU search */}
                {showImageCaption && (
                  <Typography
                    ref={captionRef}
                    className={cn([
                      "!capitalize",
                      typographyStyles.rhBaseCaption,
                      "text"
                    ])}
                    noWrap={needMore && !more}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: he.decode(activeImage.caption || "")
                      }}
                      className="normal-case"
                    />
                  </Typography>
                )}
                {!selectedOptionLabel && (
                  <Typography className={typographyStyles.rhBaseCaption}>
                    {needMore && more && (
                      <span className="underline cursor-pointer">
                        {pageContent?.LESS}
                      </span>
                    )}
                    {needMore && !more && (
                      <span className="underline cursor-pointer">
                        {pageContent?.MORE}
                      </span>
                    )}
                  </Typography>
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Hidden smDown>
        <Grid item xs={12}>
          {imageList?.length > 1 && (
            <RHImageContext.Provider value={{ loading: "eager" }}>
              <ImageThumbnailList
                images={imageList}
                index={activeIndex}
                onChangeIndex={handleOnIndexChange}
                imageCarousels={true}
              />
            </RHImageContext.Provider>
          )}
        </Grid>
      </Hidden>
    </Grid>
  );
};

HeroImage.defaultProps = {};

export default memoize(HeroImage);
