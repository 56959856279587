import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  FormControlLabel,
  Checkbox,
  useMediaQuery
} from "utils/material-ui-core";
import RHLink from "component-rh-link";
import {
  BREAKPOINT_SM,
  BREAKPOINT_MD,
  BREAKPOINT_LG,
  BREAKPOINT_XL,
  IMAGE_ASPECT_RATIO,
  EXCLUDED_PRODUCT_CONFIGURATOR_OPTION_SWATCH_DETAILS
} from "utils/constants";
import memoize from "utils/memoize";
import ProductOptionDropdown from "component-product-option-dropdown";
import { sortBy, isEqual } from "lodash";
import he from "he";
import useTypographyStyles from "hooks/useTypographyStyles";
import classNames from "classnames";
import { useEnv } from "hooks/useEnv";
import RHPriceRangeDisplay, {
  RHRUniversalPriceRangeDisplay
} from "component-rh-price-range-display";
import { convertSkuResponsePriceInfoToRange } from "component-price-range-display/utils/convertSkuResponsePriceInfoToRange";
import OptionsItems from "@RHCommerceDev/component-configurator-options/OptionsItems";
import { usePageContent } from "customProviders/LocationProvider";
import {
  getFormattedOptionIds,
  mapOptionStatusToAvailableOptions
} from "@RHCommerceDev/build-lineitem-available-options";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { countryNameMapper } from "resources/countries-config.json";
import RHImage from "@RHCommerceDev/component-rh-image";
import { useRhUserAtomValue } from "hooks/atoms";
import useGetPDPRedirectPath from "hooks/useGetPDPRedirectPath";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: "24px",
      marginBottom: "24px"
    },
    imageWrapper: {
      width: "64px"
    },
    label: {
      color: "#000000",
      textDecoration: "underline"
    },
    priceLabel: {
      textDecoration: "none"
    },
    optionGrid: {
      width: "100%"
    },
    productAddonCheckbox: {
      height: "12px",
      width: "12px",
      margin: "4px 8px 20px 16px !important",
      "&.Mui-checked .MuiSvgIcon-root": {
        fontSize: "10px !important"
      },
      "&.MuiCheckbox-root": {
        border: "0.5px solid #000000 !important"
      },
      "&.MuiCheckbox-root .MuiIconButton-label input": {
        width: "12px",
        height: "12px",
        border: "0.5px solid #000000 !important"
      }
    },
    optionContainer: {
      paddingTop: "16px",
      height: "50px"
    },
    optionDropDown: {
      height: "50px",
      width: "100%",
      [theme.breakpoints.down(BREAKPOINT_XL)]: {
        height: "50px"
        // width: "223px"
      },
      [theme.breakpoints.down(BREAKPOINT_LG)]: {
        height: "50px"
        // width: "191px"
      },
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        height: "50px"
        // width: "193px"
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        height: "50px"
        // width: "214px"
      }
    },
    qtyContainer: {
      paddingLeft: "10px",
      paddingRight: "unset",
      height: "50px",
      width: "114px"
    }
  })
);
export interface AddOnRenderButtonOptionInfo {
  id: Maybe<String>;
  renderButtonOption: Maybe<ProductOptions[]>;
}
export interface AddOnConfigModes {
  mainItemOptionsChanged?: boolean;
  productUnchecked?: boolean;
  quantityChanged?: boolean;
  panelProdComputation?: boolean;
}
export interface AddOnCheckboxProps {
  selectedIds?: ProductAvailableOption[];
  data?: ProductAddonsInfo;
  productId?: Maybe<string>;
  opts?: ProductAvailableOptionSet[];
  imgURL?: Maybe<string>;
  addOnData?: Maybe<AddOnDataType>;
  addOnOptionInfoToRenderButtons?: AddOnRenderButtonOptionInfo;
  productAddonTitle?: Maybe<string>;
  productAddonDescription?: Maybe<string>;
  productAddonMessage?: Maybe<string>;
  handleAddOnData?: (
    addOnData: AddOnDataType,
    addOnConfigModes: AddOnConfigModes
  ) => void;
  loading?: boolean;
  productLineItemOptions?: ProductAvailableOptionSet[];
  mainProductAvailableOptions?: Maybe<Array<ProductAvailableOptionSet>>;
  mainProductAvailableSwatch?: any;
  mainProductChoosenOptions?: any;
  productConfiguration?: any;
  optionId?: any;
  setOptionId?: any;
  fetchOptionStatus?: (variable: any) => Promise<ProductLineItem>;
  isPanel?: boolean;
}

export interface AddOnDataType {
  productSelected?: boolean;
  productId?: string;
  selectedOptions?: ProductAvailableOption[];
  optionsLength?: number;
  qty?: number;
  fullSkuId?: string;
  spo?: Maybe<boolean>;
  preBillMessage?: Maybe<string>;
  pricing?: Maybe<ProductSkuPriceInfo>;
  itemOptions?: ProductAvailableOptionSet[];
  sku?: Maybe<ProductSku>;
  parentBaseId?: string;
  // addonAgreed?: boolean;
  colorizedImgURL?: Maybe<string>;
}

export const AddOnCheckboxProductV2: FC<AddOnCheckboxProps> = memoize(
  ({
    data,
    productId,
    loading,
    handleAddOnData,
    addOnData,
    addOnOptionInfoToRenderButtons,
    mainProductAvailableOptions,
    mainProductAvailableSwatch,
    mainProductChoosenOptions,
    productConfiguration,
    fetchOptionStatus,
    isPanel = false,
    parentBaseId
  }) => {
    const baseId = `${parentBaseId}-addonCheckbox`;
    const env = useEnv();
    const { pageContent } = usePageContent();
    const PDP_DEFAULT_QTY = Number(env.FEATURE_PDP_DEFAULT_QUANTITY);
    const classes = useStyles();
    const { userType } = useRhUserAtomValue();
    const country = getCountryFromUrl();
    const currencyCode = countryNameMapper[country];
    const smOnly = useMediaQuery<Theme>(theme => theme.breakpoints.only("sm"));
    const mdOnly = useMediaQuery<Theme>(theme => theme.breakpoints.only("md"));

    const [qty, setQty] = useState(PDP_DEFAULT_QTY);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    // const getQtyValue = "40";
    const [selectedOptions, setSelectedOptions] = useState({});

    /*
     * Please selectedOptionIds to pass to getSku or other query
     * it contains the id's of selected options
     * */
    const [selectedOptionIds, setSelectedOptionIds] = useState<string[]>([]);

    const [preselectedOpts, setPreselectedOpts] = useState<
      Array<ProductAvailableOption>
    >([]);

    const [availableOptionsAddOn, setAvailableOptionsAddOn] = useState<
      ProductAvailableOptionSet[]
    >(data?.productConfiguration?.optionDetails);

    const typographyStyles = useTypographyStyles({
      keys: [
        "rhBaseBody2",
        "rhBaseBody3",
        "rhBaseBody1",
        "rhBaseCaption",
        "rhBaseH3",
        "rhBaseH2",
        "uppercaseText",
        "reimagineBody1",
        "reimagineBody2"
      ]
    });

    const updateAvailableOptionStatus = async () => {
      const optionIds = getFormattedOptionIds(selectedOptions);
      if (!optionIds?.length) {
        return;
      }

      setSelectedOptionIds(optionIds);

      const optionStatus = await fetchOptionStatus({
        selectedOptionIds: optionIds,
        productId: data?.id,
        fullSkuId: undefined,
        onSale: data?.onSale
      });

      if (optionStatus?.availableOptionsStatus?.allOptions?.length) {
        const { updatedAvailableOptions } = mapOptionStatusToAvailableOptions({
          optionStatus: optionStatus?.availableOptionsStatus,
          availableOptions: availableOptionsAddOn
        });
        setAvailableOptionsAddOn(updatedAvailableOptions);
      }

      // const updatedAvailableOptions = createProductLineItemAvailableOptions({
      //   productConfiguration: productConfiguration,
      //   availableOptions: availableOptionsAddOn,
      //   selectedOptions: selectedOptions,
      //   isInstock: false,
      //   isAddon: true
      // });
      //
      // if (updatedAvailableOptions?.availableOptions) {
      //   setAvailableOptionsAddOn(updatedAvailableOptions?.availableOptions);
      // }
    };

    useEffect(() => {
      updateAvailableOptionStatus();
    }, [selectedOptions]);

    const getPreSelectedOption = useCallback(
      optionsSet => {
        return optionsSet?.map(optionSet => {
          const choosenOptionId =
            optionSet?.type === "Finish"
              ? mainProductChoosenOptions?.finishSwatchGroups
              : mainProductChoosenOptions?.[optionSet?.type];
          return optionSet?.options?.filter(
            option => option.id === choosenOptionId
          );
        });
      },
      [mainProductChoosenOptions]
    );

    useEffect(() => {
      const preSelectedOptionsArray = [
        ...getPreSelectedOption(availableOptionsAddOn)
          .flat()
          .filter(opt => opt)
      ];

      !isEqual(preSelectedOptionsArray, preselectedOpts) &&
        setPreselectedOpts(preSelectedOptionsArray);
    }, [mainProductChoosenOptions]);

    useEffect(() => {
      const preSelectedOptions = preselectedOpts?.reduce((res, opt) => {
        if (opt?.name && opt?.id) {
          res[opt.name] = opt.id;
        }
        return res;
      }, {});
      const preSelectedOptionIds = Object.values(preSelectedOptions)?.flatMap(
        (opt: string) =>
          opt?.includes("_") && !opt?.toLowerCase().includes("m_")
            ? opt?.split("_")
            : opt
      );
      setSelectedOptions(prev => ({
        ...prev,
        ...preSelectedOptions
      }));

      const otherSelectedOptions = Object.entries(selectedOptions)
        .filter(
          ([key, value]) => !Object.keys(preSelectedOptions)?.includes(key)
        )
        .map(([key, value]) => value);

      handleAddOnData(
        {
          productSelected: isChecked,
          productId: productId,
          optionsLength: availableOptionsAddOn?.length,
          selectedOptions: [
            ...preSelectedOptionIds,
            ...otherSelectedOptions
          ]?.map(id => ({ id })),
          qty: 1
        },
        {
          mainItemOptionsChanged: true,
          productUnchecked: false,
          // quantityChanged: false,
          panelProdComputation: isPanel
        }
      );
    }, [preselectedOpts]);

    const setOptionsVisibility = useCallback(
      optionsSet => {
        const choosenOptionId =
          optionsSet?.type?.toLowerCase() === "finish"
            ? mainProductChoosenOptions?.finishSwatchGroups
            : mainProductChoosenOptions?.[optionsSet?.type];
        const optionSetOptionIds = optionsSet?.options?.map(
          option => option?.id
        );

        if (
          !choosenOptionId &&
          EXCLUDED_PRODUCT_CONFIGURATOR_OPTION_SWATCH_DETAILS?.includes(
            optionsSet?.type?.toLowerCase()
          )
        ) {
          const swatchId = Object.values(mainProductChoosenOptions)
            ?.find(option => option?.toString()?.includes("_"))
            ?.toString()
            ?.split("_");

          return {
            // optionTypeId: optionSet.optionTypeId,
            optionType: optionsSet?.type,
            isOptionSetInvisible:
              optionSetOptionIds?.findIndex(optionId =>
                swatchId?.includes(optionId)
              ) >= 0,
            matchedOptionIds: [choosenOptionId]
          };
        }

        return {
          // optionTypeId: optionSet.optionTypeId,
          optionType: optionsSet?.type,
          isOptionSetInvisible:
            optionSetOptionIds?.includes(choosenOptionId) ?? false,
          matchedOptionIds: [choosenOptionId]
        };
      },
      [mainProductAvailableOptions]
    );

    const selectedAddOnDisplayOption = useMemo(
      () =>
        mainProductAvailableOptions?.map(optionSet => {
          const matchingOptionSet = availableOptionsAddOn?.find(
            secondOptionSet =>
              secondOptionSet?.optionTypeId === optionSet?.optionTypeId
          );
          const matchingOptionIds = optionSet?.options
            ?.filter(option => option.status === "selected")
            .map(option => option.id);
          return matchingOptionSet?.options.filter(option =>
            matchingOptionIds?.includes(option.id)
          );
        })?.[0]?.[0]?.value,
      [mainProductAvailableOptions]
    );

    // const getSelectedIds = selectedOptionsSet => {
    //   const uniqueOptionIds = new Set();
    //   selectedOptionsSet?.forEach(obj => {
    //     obj.matchedOptionIds.forEach(optionId => {
    //       uniqueOptionIds.add(optionId);
    //     });
    //   });

    //   const uniqueOptionIdsArray = Array.from(uniqueOptionIds);
    //   return uniqueOptionIdsArray;
    // };

    const handleCheckbox = (event, productId) => {
      // const selectedOptionsSet = setOptionsVisibility(
      //   availableOptionsAddOn
      // )?.filter(opt => opt.isOptionSetVisible);
      // const selectedOptionIds = getSelectedIds(selectedOptionsSet);
      const preSelectedOptions = preselectedOpts?.reduce((res, opt) => {
        if (opt?.name && opt?.id) {
          res[opt.name] = opt.id;
        }
        return res;
      }, {});
      const preSelectedOptionIds = Object.values(preSelectedOptions)?.flatMap(
        (opt: string) =>
          opt?.includes("_") && !opt?.toLowerCase().includes("m_")
            ? opt?.split("_")
            : opt
      );

      isChecked && setSelectedOptions({ ...preSelectedOptions });
      if (!preSelectedOptionIds?.length) {
        setAvailableOptionsAddOn(data?.productConfiguration?.optionDetails);
      }
      handleAddOnData(
        {
          productSelected: event.target.checked,
          productId: productId,
          optionsLength: availableOptionsAddOn?.length,
          selectedOptions: isChecked
            ? [...preSelectedOptionIds]?.map(id => ({ id }))
            : selectedOptionIds?.map(id => ({ id })),
          qty: 1
        },
        {
          mainItemOptionsChanged: false,
          productUnchecked: !event.target.checked,
          // quantityChanged: false,
          panelProdComputation: isPanel
        }
      );

      setIsChecked(!isChecked);
      setQty(!isChecked ? 1 : 0);
    };

    const handleButtonSelection = useCallback(
      option => {
        const preSelectedOptions = preselectedOpts?.reduce((res, opt) => {
          if (opt?.name && opt?.id) {
            res[opt.name] = opt.id;
          }
          return res;
        }, {});

        const previousNonPreselectedOptions = Object.keys(selectedOptions)
          .filter(key => !Object.keys(preSelectedOptions).includes(key))
          .reduce((obj, key) => {
            obj[key] = selectedOptions[key];
            return obj;
          }, {});
        setSelectedOptions({
          ...preSelectedOptions,
          ...previousNonPreselectedOptions,
          [option.type ? option?.type : option?.name]: option?.id
        });

        const selectedOptIds = Object.values({
          ...preSelectedOptions,
          ...previousNonPreselectedOptions,
          [option.type ? option?.type : option?.name]: option?.id
        });
        setIsChecked(true);

        handleAddOnData(
          {
            productSelected: true,
            productId: productId,
            optionsLength: availableOptionsAddOn?.length,
            selectedOptions: [...selectedOptIds]?.map(id => ({ id })),
            qty: 1
          },
          {
            mainItemOptionsChanged: false,
            productUnchecked: false,
            // quantityChanged: false,
            panelProdComputation: isPanel
          }
        );
      },
      [addOnData, availableOptionsAddOn, selectedOptions]
    );

    const onAddOnConfigChange = useCallback(
      (
        value?: string,
        options?: ProductAvailableOption[],
        productId?: string
      ) => {
        // let preSelectedOptions: Array<ProductAvailableOption[]> = [];
        // preSelectedOptions = getPreSelectedOption(availableOptionsAddOn);

        const preSelectedOptions = preselectedOpts?.reduce((res, opt) => {
          if (opt?.name && opt?.id) {
            res[opt.name] = opt.id;
          }
          return res;
        }, {});

        const previousNonPreselectedOptions = Object.keys(selectedOptions)
          .filter(key => !Object.keys(preSelectedOptions).includes(key))
          .reduce((obj, key) => {
            obj[key] = selectedOptions[key];
            return obj;
          }, {});

        const selectedOpt = options?.find(option => option.id === value);

        const unselectedOpt = options?.find(
          option => option.status === "selected"
        ) as ProductAvailableOption;

        const {
          [unselectedOpt?.name || ""]: unselectedOption,
          ...remainingOpts
        } = {
          ...preSelectedOptions,
          ...previousNonPreselectedOptions
        } as Record<string, ProductAvailableOption>;

        setSelectedOptions(
          selectedOpt
            ? {
                ...preSelectedOptions,
                ...previousNonPreselectedOptions,
                [selectedOpt?.name ?? ""]: value
              }
            : {
                ...remainingOpts
              }
        );

        const selectedOptIds = Object.values(
          selectedOpt
            ? {
                ...preSelectedOptions,
                ...previousNonPreselectedOptions,
                [selectedOpt?.name ?? ""]: value
              }
            : {
                ...remainingOpts
              }
        );

        setIsChecked(true);

        handleAddOnData(
          {
            productSelected: true,
            productId: productId,
            optionsLength: availableOptionsAddOn?.length,
            // selectedOptions: selectedOpt
            //   ? [...selectedOptIds]?.map(id => ({ id }))
            //   : selectedOptionIds?.map(id => ({ id })),
            mainItemOptionsChanged: false,
            productUnchecked: value === "",
            // quantityChanged: false,
            selectedOptions: selectedOptIds?.length
              ? [...selectedOptIds]?.map(id => ({ id }))
              : [],
            qty: 1
          },
          {
            mainItemOptionsChanged: false,
            productUnchecked: value === "",
            // quantityChanged: false,
            panelProdComputation: isPanel
          }
        );

        // if (selectedOptionIds?.length === 0) {
        //   setQty(0);
        //   setIsChecked(false);
        //   return;
        // }

        // setQty(prev => (prev === 0 ? 1 : prev));
      },

      [addOnData, availableOptionsAddOn, selectedOptions, qty, handleAddOnData]
    );

    // const handleQuantitySelected = useCallback(
    //   (qty: number) => {
    //     setQty(qty);
    //     handleAddOnData(
    //       {
    //         productId: productId,
    //         productSelected: qty === 0 ? false : isChecked,
    //         selectedOptions:
    //           qty === 0 ? [] : selectedOptionIds?.map(id => ({ id })),
    //         optionsLength: availableOptionsAddOn?.length,
    //         qty: 1
    //       },
    //       {
    //         mainItemOptionsChanged: false,
    //         productUnchecked: false,
    //         quantityChanged: true,
    //         panelProdComputation: isPanel
    //       }
    //     );
    //   },
    //   [addOnData, selectedOptions, availableOptionsAddOn, handleAddOnData]
    // );

    const redirectPath = useGetPDPRedirectPath(data);

    return (
      <>
        <Grid
          container
          xs={12}
          className={classes?.container}
          id="addon-container"
          data-testid="addon-container"
        >
          <Grid
            container
            id="addon-title-message-wrapper"
            data-testid="addon-title-message-wrapper"
          >
            <Grid container>
              <Typography
                className={classNames([
                  typographyStyles?.reimagineBody2,
                  typographyStyles?.uppercaseText
                ])}
                id="addon-title"
                data-testid="addon-title"
                dangerouslySetInnerHTML={{
                  __html: `${data?.productAddonTitle} `
                }}
              />
            </Grid>
            <Grid
              container
              id="addon-message-container"
              data-testid="addon-message-container"
            >
              <Typography
                className={typographyStyles?.reimagineBody2}
                id="addon-message"
                data-testid="addon-message"
                dangerouslySetInnerHTML={{
                  __html: `${data?.productAddonMessage} `
                }}
              />
            </Grid>
          </Grid>
          <Grid
            spacing={2}
            className={classes?.optionGrid}
            id="addons-option-grid"
            data-testid="addons-option-grid"
          >
            <Grid
              container
              item
              style={{ width: "100%", marginTop: "16px" }}
              id="option-container"
              data-tetsid="option-container"
            >
              <Grid
                item
                spacing={2}
                className={classes?.imageWrapper}
                id="image-grid"
                data-testid="image-grid"
              >
                <RHLink
                  data-testid={`productImageLink-${data?.id}`}
                  to={redirectPath}
                  target="_blank"
                >
                  <RHImage
                    parentBaseId={baseId}
                    src={addOnData?.colorizedImgURL ?? data?.imageUrl}
                    alt={data?.displayName}
                    objectFit={"contain"}
                    preset="mobileThumb"
                    aspectRatio={IMAGE_ASPECT_RATIO["thumbnail"]}
                    imageWrapperStyles={{
                      width: "64px",
                      height: "auto"
                    }}
                    imageSkeletonBackgroundColor="white"
                  />
                </RHLink>
              </Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="productaddon-checkbox"
                    key={`${baseId}-productaddon-checkbox`}
                    checked={isChecked}
                    onChange={e => {
                      handleCheckbox(e, productId);
                      setIsChecked(!isChecked);
                    }}
                    className={classes?.productAddonCheckbox}
                    style={{
                      cursor: !loading ? "pointer" : "default",
                      height: "12px",
                      width: "12px"
                    }}
                  />
                }
                label={
                  <Grid container>
                    <Grid
                      item
                      style={{
                        width: smOnly ? "100%" : ""
                      }}
                    >
                      <RHLink
                        data-testid={`productImageLink-${productId}`}
                        to={redirectPath}
                        target="_blank"
                      >
                        <Typography
                          className={typographyStyles?.reimagineBody1}
                          display="inline"
                          dangerouslySetInnerHTML={{
                            __html: `${pageContent?.ADD} `
                          }}
                        />
                        <Typography
                          className={classNames([
                            classes?.label,
                            typographyStyles?.reimagineBody1
                          ])}
                          id="display-name"
                          data-testid="display-name"
                          display="inline"
                          dangerouslySetInnerHTML={{
                            __html: `${data?.displayName}`
                          }}
                        />
                        {selectedAddOnDisplayOption && (
                          <Typography
                            className={classNames([
                              classes?.label,
                              typographyStyles?.reimagineBody1
                            ])}
                            id="selected-addon-option"
                            data-testid="selected-addon-option"
                            display="inline"
                            dangerouslySetInnerHTML={{
                              __html: ` - ${selectedAddOnDisplayOption}`
                            }}
                          />
                        )}
                      </RHLink>
                    </Grid>

                    <Grid
                      item
                      className={classNames([classes?.priceLabel])}
                      id="price-label "
                      data-testid="price-label"
                    >
                      {addOnData?.pricing ? (
                        <>
                          <RHRUniversalPriceRangeDisplay
                            topLabel={pageContent?.CONFIGURES_PRICE}
                            display="inline"
                            variant="medium"
                            range={convertSkuResponsePriceInfoToRange?.(
                              null,
                              addOnData?.pricing ?? {},
                              userType
                            )}
                            currencyLabel={
                              addOnData?.pricing?.currencyLabel || ""
                            }
                            currencySymbol={
                              addOnData?.pricing?.currencySymbol || "$"
                            }
                            isSkuLevel={true}
                            currency={currencyCode}
                            addOnV2Pricing={true}
                          />
                        </>
                      ) : (
                        <>
                          <RHPriceRangeDisplay
                            data={data?.priceRangeDisplay}
                            topLabel={pageContent?.FOR}
                            display={"inline"}
                            variant={"medium"}
                            memberPrice={
                              data?.priceRangeDisplay?.priceRange
                                ?.memberLowestListPrice
                            }
                            isMetaProduct={data?.metaProduct!}
                            nextGenDrivenOnSale={
                              data?.priceRangeDisplay?.nextGenDrivenOnSale!
                            }
                            skuOnSalePercentage={parseInt(
                              data?.saleInfo?.percentSaleSkus!
                            )}
                            currency={currencyCode}
                            addOnV2Pricing={true}
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>
                }
                style={{
                  cursor: !loading ? "pointer" : "default",
                  width: isPanel
                    ? "calc(100% - 64px)"
                    : smOnly
                    ? "635px"
                    : mdOnly
                    ? "250px"
                    : "271px",
                  margin: "0px",
                  alignItems: "unset"
                }}
              />
            </Grid>
            {availableOptionsAddOn?.map((availableOption, index) => {
              let options = availableOption.options?.filter(
                option => option.status !== "unavailable"
              );

              if (availableOption.type === "Color") {
                options = sortBy(options, o => o.value);
              }

              const selected =
                options.find(option => option.status === "selected") ||
                ({} as ProductAvailableOption);
              const optionsVisibility = setOptionsVisibility(availableOption);
              const shouldHideOption = optionsVisibility?.isOptionSetInvisible;

              if (shouldHideOption) {
                return null;
              }

              if (
                addOnOptionInfoToRenderButtons?.id === productId &&
                availableOption?.type ===
                  addOnOptionInfoToRenderButtons?.renderButtonOption?.type
              ) {
                return (
                  <OptionsItems
                    Option={availableOption}
                    checked={isChecked}
                    handleSelection={handleButtonSelection}
                    isAddOnOption={true}
                    isAddOnPanelOption={isPanel}
                  />
                );
              }
              return (
                <>
                  <Grid xs={12} container className={classes?.optionContainer}>
                    <Grid item className={classes?.optionDropDown}>
                      <Grid key={`availableOption-${index}`} item xs={12}>
                        <ProductOptionDropdown
                          loading={loading}
                          id={`optionSelect-${productId}-${availableOption.type}`}
                          label={availableOption.type}
                          disabled={
                            options.length < 1 &&
                            selected?.status === "selected"
                          }
                          options={options.map(option => ({
                            key: `${availableOption.type}-${option.id}`,
                            value: option.id,
                            display: he.decode(option.value)
                          }))}
                          value={selected?.id || ""}
                          onChange={value => {
                            onAddOnConfigChange(value, options, productId);
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Grid>
      </>
    );
  }
);

export default AddOnCheckboxProductV2;
