import React, { useMemo, useCallback, useEffect } from "react";
import {
  Grid,
  useTheme,
  Typography,
  useMediaQuery,
  Theme
} from "@material-ui/core";
import { useQuery } from "@apollo/client";

import { queryLineItem } from "graphql-client-product";
import { SaleContextFilter } from "graphql-client/queries/app";

import { processEnvServer } from "hooks/useSsrHooks";
import { useState } from "hooks/useState";
import useDidMountEffect from "hooks/useDidMountEffect";

import { getCookie } from "utils/cookies";
import {
  MONOGRAM_ORDER_DEFAULT,
  SHADE_TYPE_MAPPER,
  CONTROL_STANDARD
} from "utils/constants";

import { MonogramOrderProps } from "page-monogram-dialog";
import RelatedProductActions from "component-related-product-actions";
import RHDivider from "component-rh-divider";
import Sku from "component-sku";
import AddToCartDialog from "dialog-add-to-cart";
import AddToWishlistDialog from "dialog-add-to-wishlist";
import AddToGiftRegistryDialog from "dialog-add-to-gift-registry";
import PostalCodeDialog from "dialog-postal-code";
import analyticsLoader from "analytics/loader";
import CustomProductCard from "./CustomProductCard";
import {
  getFormattedWindowLabel,
  clearCustomizationInput
} from "utils/customProductHelper";
import memoize from "utils/memoize";
import ProductSkuPriceInfo from "component-product-sku-price-info";
import RHStandardSelect from "component-rh-standard-select";
import yn from "yn";
import { useEnv } from "hooks/useEnv";
import useMeasurementType from "hooks/useMeasurementType";
import useTypographyStyles from "hooks/useTypographyStyles";
import useLocale from "hooks-use-locale/useLocale";
import useSite from "hooks/useSite";
import { customConfigHelper } from "./customConfigHelper";
import InternationalPostalCodeDialog from "dialog-international-postal-code";
import { usePageContent } from "customProviders/LocationProvider";
import { countryCurrencyMapper } from "resources/countries-config.json";
import useUserPreferences from "hooks/useUserPreferences";
import { useRhUserAtomValue } from "hooks/atoms";

interface ICustomProductProps {
  data?: Product;
  fullSkuId?: string;
  authenticated?: boolean;
  loading?: boolean;
  postalCode?: string;
  saleContextFilter?: SaleContextFilter;
  productSwatchImage: Maybe<ProductImage>;
  swatchGroups?: ProductSwatchGroup[];
  selectedSwatches?: {
    [groupMaterial: string]: ProductSwatch;
  };
  handleOnSwatchClick?: {
    (data: {
      swatchGroup: ProductSwatchGroup;
      selectedSwatch: ProductSwatch;
    }): void;
  };
}

export interface CustomProductConfiguration {
  colorId?: string;
  finish?: CustomOption;
  mountTypes?: CustomOption;
  panels?: CustomOption;
  controlTypes?: CustomOption;
  rollTypes?: CustomOption;
  tiltTypes?: CustomOption;
  controlsAndTilts?: CustomOption;
  bracketColors?: CustomOption;
  controlPositions?: CustomOption;
  linings?: CustomOption;
  width?: number;
  length?: number;
  controlLength?: number;
  widthControlGap?: number;
  lengthControlGap?: number;
  displayWidth?: string;
  displayLength?: string;
  displayControlLength?: CustomOption;
  bracketShape?: CustomOption;
  rodSize?: CustomOption;
  rodType?: CustomOption;
  roomLabel?: string;
  roomLabelDescription?: string;
  isCustom?: boolean;
  isMotorized?: boolean;
  isContiniousLoop?: boolean;
}

export interface CustomProductMapper {
  shade?: boolean;
  rug?: boolean;
  customProductTrimColor?: string;
  drape?: boolean;
  hardwareTypeId?: string;
  drapeWidth?: number;
  drapeLength?: number;
  partsParentFullSkuId?: string;
  panelId?: string;
  finishId?: string;
  styleId?: string;
  personalizeAll?: boolean;
  customProductType?: string;
  customHardware?: boolean;
  customProduct?: boolean;
  cogitem?: boolean;
  mountTypeId?: string;
  controlLength?: number;
  controlTypeId?: string;
  controlPositionId?: string;
  liningId?: string;
  trimId?: string;
  bracketColorId?: string;
  rollTypeId?: string;
  tiltTypeId?: string;
  controlAndTiltId?: string;
  roomLabel?: string;
  shadeWidth?: number;
  shadeLength?: number;
  customHardwareLength?: number;
  customProductTrim?: string;
  customProductDiameter?: string;
  customProductLength?: string;
  customProductWidth?: string;
  trackHardwareLength?: number;
  acceptanceRequired?: boolean;
  termsAccepted?: boolean;
  termsItemId?: string;
  trackHardware?: boolean;
  acceptSpoTerms?: boolean;
}

const defaultConfiguration: CustomProductConfiguration = {
  width: 0,
  length: 0,
  controlLength: 0,
  widthControlGap: 0,
  lengthControlGap: 0,
  roomLabel: "",
  roomLabelDescription: "",
  isMotorized: false,
  isContiniousLoop: false
};

const CustomProduct: React.FC<ICustomProductProps> = ({
  data,
  fullSkuId,
  authenticated,
  loading,
  postalCode,
  saleContextFilter,
  productSwatchImage,
  selectedSwatches,
  swatchGroups,
  handleOnSwatchClick
}) => {
  const { pageContent } = usePageContent();
  const [pli, setPli] = useState<ProductLineItem>({});
  const [qty, setQty] = useState(1);
  const [monogramOrder, setMonogramOrder] = useState<MonogramOrderProps>(
    MONOGRAM_ORDER_DEFAULT
  );
  const [skuInfo, setSkuInfo] = useState<ProductSkuInfo>({} as ProductSkuInfo);
  const [cartDialog, setCartDialog] = useState("");
  const [reqProdId, setReqProdId] = useState("");
  const [reqSkuId, setReqSkuId] = useState("");
  const [reqAtgSkuId, setReqAtgSkuId] = useState("");
  const [wishlistDialog, setWishlistDialog] = useState(false);
  const [postalCodeDialog, setPostalCodeDialog] = useState(false);
  const [giftRegistryDialog, setGiftRegistryDialog] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [atgSkuId, setAtgSkuId] = useState(
    data?.productLineItem?.sku?.inventory?.atgSkuId
  );
  const [confirmed, setConfirmed] = useState(false);
  //fullSkuId
  const [_fullSkuId, setFullSkuId] = useState(
    fullSkuId || data?.productLineItem?.sku?.inventory?.fullSkuId
  );
  const [swatchErrorMessage, setSwatchErrorMessage] = useState(false);
  const [configuration, setConfiguration] =
    useState<CustomProductConfiguration>({
      ...defaultConfiguration
    });

  const theme = useTheme();
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
  const pc = getCookie("pc");
  const { userType } = useRhUserAtomValue();
  const env = useEnv();
  const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);
  const locale = useLocale();
  const measureSystem = useMeasurementType();
  const siteId = useSite();
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseBody2", "rhBaseCaption", "rhBaseBody1"]
  });
  const {
    previousState: { country }
  } = useUserPreferences();
  const currencyCode = countryCurrencyMapper[country];

  const {
    loading: loadingLineItem,
    data: { productLineItem } = {} as Query,
    refetch
  } = useQuery<Query>(queryLineItem, {
    variables: {
      productId: data.id,
      monogrammable: data?.personalizeInfo?.monogrammable,
      postalCode: postalCode || pc,
      qty: 1,
      userType: userType,
      filter: saleContextFilter,
      siteId,
      measureSystem,
      locale: FEATURE_INTERNATIONAL ? locale : undefined,
      nextGenDriven: false,
      shouldFetchCustomProductOptions: true,
      currencyCode,
      isCustom: true
    },
    skip: !data?.id,
    fetchPolicy: "cache-and-network"
  });

  const singleOptions = useMemo(
    () =>
      pli.availableOptions?.reduce((acc, cur) => {
        const options = cur.options.filter(o => o.status !== "unavailable");
        if (
          options.length === 1 &&
          !selectedOptions.find(option => option.id === options[0].id)
        ) {
          return [...acc, options[0]];
        }
        return acc;
      }, [] as ProductAvailableOption[]) ?? [],
    [pli?.availableOptions, selectedOptions]
  );

  useDidMountEffect(async () => {
    const allOptions = [...singleOptions, ...selectedOptions];
    let selectedOptionIds: string[] = allOptions.map(value => value.id);
    selectedOptionIds = [...new Set(selectedOptionIds)];

    const opts =
      selectedOptionIds?.length > 0
        ? {
            productId: data.id,
            selectedOptionIds,
            qty
          }
        : {
            productId: data.id,
            selectedOptionIds: undefined,
            qty
          };

    if (opts) {
      const {
        width,
        length,
        isContiniousLoop: continuousLoop,
        isMotorized: motorized
      } = configuration;

      let finalOptions: QueryProductLineItemArgs = {
        ...opts,
        continuousLoop,
        motorized,
        userType: userType
      };

      const { customProductType } =
        pli?.customProductOptions?.customProductInformation ?? {};

      if (["trackHardware", "customHardware"].includes(customProductType)) {
        const parsedLength = Number(length);
        finalOptions = {
          ...finalOptions,
          ...(!isNaN(Number(parsedLength)) && parsedLength > 0
            ? { length: parsedLength }
            : null),
          isCustom: selectedOptions?.length > 0 && parsedLength > 0
        };
      } else if (["drape", "shade"].includes(customProductType)) {
        const parsedLength = Number(length);
        const parsedWidth = Number(width);
        finalOptions = {
          ...finalOptions,
          ...(!isNaN(parsedWidth) && parsedWidth > 0
            ? { width: parsedWidth }
            : null),
          ...(!isNaN(parsedLength) && parsedLength > 0
            ? { length: parsedLength }
            : null),
          isCustom:
            selectedOptions?.length > 0 &&
            Number(width) > 0 &&
            Number(length) > 0
        };
      }
      finalOptions = {
        ...finalOptions,
        shouldFetchSku:
          pli?.availableOptions?.length === selectedOptions?.length
      };
      await refetch(finalOptions);
    }
  }, [
    postalCode,
    singleOptions,
    selectedOptions,
    configuration?.length,
    configuration?.width,
    configuration.isContiniousLoop,
    configuration.isMotorized,
    userType,
    pli?.customProductOptions?.customProductInformation?.customProductType
  ]);

  useEffect(() => {
    if (productLineItem) {
      const { atgSkuId: newAtgSkuId, fullSkuId: newFullSkuId } =
        productLineItem?.sku?.inventory || {};
      setPli(productLineItem);
      setAtgSkuId(newAtgSkuId);
      setFullSkuId(newFullSkuId);
      if (productLineItem?.sku?.info) setSkuInfo(productLineItem?.sku?.info);
    }
  }, [productLineItem, pli]);

  const isValidCustomHardwareConfig: boolean = useMemo(() => {
    if (selectedOptions?.length === pli?.availableOptions?.length) {
      if (configuration?.roomLabel && configuration?.length > 0) {
        return true;
      }
    }
    return false;
  }, [selectedOptions, configuration, pli]);

  const isValidShadeConfig = useMemo((): boolean => {
    const customOptions = pli?.customProductOptions?.customProductInformation;
    if (configuration?.width <= 0 || configuration?.length <= 0) {
      return false;
    }
    if (!selectedOptions?.find(option => option.type === "Color")) {
      return false;
    }
    if (customOptions?.mountTypes && !configuration?.mountTypes) {
      return false;
    }
    if (customOptions?.controlTypes && !configuration?.controlTypes) {
      return false;
    }
    if (customOptions?.controlPositions && !configuration?.controlPositions) {
      return false;
    }
    if (customOptions?.linings && !configuration?.linings) {
      return false;
    }
    if (customOptions?.rollTypes && !configuration?.rollTypes) {
      return false;
    }
    if (customOptions?.tiltTypes && !configuration?.tiltTypes) {
      return false;
    }
    if (customOptions?.controlsAndTilts && !configuration?.controlsAndTilts) {
      return false;
    }
    if (customOptions?.bracketColors && !configuration?.bracketColors) {
      return false;
    }
    if (!configuration?.roomLabel?.trim()) {
      return false;
    }
    if (
      customOptions?.controlTypes &&
      configuration?.controlTypes?.value === CONTROL_STANDARD &&
      !configuration?.controlLength
    ) {
      return false;
    }
    return true;
  }, [selectedOptions, configuration, pli]);

  const isValidTrackHardwareConfig = useMemo((): boolean => {
    const customOptions = pli?.customProductOptions?.customProductInformation;
    if (!configuration?.length) {
      return false;
    }
    if (!selectedOptions?.find(option => option.type === "Finish")) {
      return false;
    }
    if (customOptions?.mountTypes && !configuration?.mountTypes) {
      return false;
    }
    if (customOptions?.panels && !configuration?.panels) {
      return false;
    }
    if (customOptions?.controlTypes && !configuration?.controlTypes) {
      return false;
    }
    if (!configuration?.roomLabel?.trim()) {
      return false;
    }
    return true;
  }, [selectedOptions, configuration, pli]);

  const isValidDrapeConfig = useMemo((): boolean => {
    const customOptions = pli?.customProductOptions?.customProductInformation;
    if (configuration?.width <= 0 || configuration?.length <= 0) {
      return false;
    }
    if (selectedOptions?.length !== pli?.availableOptions?.length) {
      return false;
    }
    if (customOptions?.panels && !configuration?.panels) {
      return false;
    }
    if (!configuration?.roomLabel?.trim()) {
      return false;
    }
    if (customOptions?.finish && !configuration?.finish) {
      return false;
    }
    return true;
  }, [selectedOptions, configuration, pli]);

  const handleAddToWishlistClick = useCallback((): void => {
    setWishlistDialog(true);
  }, [wishlistDialog]);

  const handleAddToGiftRegistryClick = useCallback((): void => {
    setGiftRegistryDialog(true);
  }, [giftRegistryDialog]);

  const handleAddToCartClick = useCallback((): void => {
    setCartDialog("addToCart");
  }, [cartDialog]);

  const handleRequestSwatch = useCallback(
    (event): void => {
      event.preventDefault();
      let swatchId = selectedSwatches?.Fabric
        ? selectedSwatches?.Fabric?.swatchId
        : selectedSwatches?.Color?.swatchId;
      const selectedSwatchValue = data?.swatchesToBuy?.find(
        option => option?.swatchId === swatchId
      );
      if (selectedSwatchValue) {
        setReqProdId(selectedSwatchValue.productId);
        setReqSkuId(selectedSwatchValue.fullSkuId);
        setReqAtgSkuId(selectedSwatchValue.atgSkuId);
        setCartDialog("requestSwatch");
        setSwatchErrorMessage(false);
      } else {
        setSwatchErrorMessage(true);
      }
    },
    [data, selectedSwatches]
  );

  const handleQuantityChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setQty(Number(event?.target?.value));
    },
    [setQty]
  );

  const handleCloseCartDialog = useCallback((): void => {
    setCartDialog("");
    setConfirmed?.(false);
  }, [cartDialog]);

  const handleCloseWishlistDialog = useCallback((): void => {
    setWishlistDialog(false);
  }, [wishlistDialog]);

  const handleCloseGiftRegistryDialog = useCallback((): void => {
    setGiftRegistryDialog(false);
  }, [giftRegistryDialog]);

  const handlePostalDialogClose = useCallback((): void => {
    setPostalCodeDialog(false);
  }, [postalCodeDialog]);

  const handlePostalCodeChange = useCallback((): void => {
    setPostalCodeDialog(true);
  }, [postalCodeDialog]);

  const onAddToCartComplete = useCallback(({ cartDetails }) => {
    const pricing = skuInfo?.skuPriceInfo;
    if (!processEnvServer) {
      analyticsLoader(a =>
        a.emitAnalyticsEvent(
          document.querySelector<HTMLInputElement>("#spa-root > *")!,
          a.EVENTS.GA4_ADD_TO_CART.INT_TYPE,
          {
            cartdetails: {
              cartId: cartDetails?.id,
              cartCreationDate: cartDetails?.createdAt,
              cartUpdationDate: cartDetails?.lastModifiedAt
            },
            item: {
              name: data?.displayName,
              id: data?.id,
              quantity: qty,
              sku: pli.sku?.inventory.fullSkuId ?? "",
              pricing: (pli.sku?.info?.skuPriceInfo?.listPrice || 0) * qty,
              currencyCode: cartDetails?.cartPrice?.currencyCode,
              color: pli?.availableOptions
                ?.find(opt => opt.type?.includes("Color"))
                ?.options.find(opt => opt.status === "selected")?.value
            },
            store_number: "Website",
            item_list_name: "Custom Product"
          }
        )
      );
    }
  }, []);

  const isDisabled = useMemo((): boolean => {
    let disabled = true;
    if (
      !pli?.sku ||
      qty <= 0 ||
      !Number(pli?.sku?.inventory?.inventoryRemaining) ||
      loadingLineItem
    ) {
      return true;
    }
    switch (
      pli?.customProductOptions?.customProductInformation?.customProductType
    ) {
      case "customHardware":
        disabled = !isValidCustomHardwareConfig;
        break;

      case "shade":
        disabled = !isValidShadeConfig;
        break;

      case "drape":
        disabled = !isValidDrapeConfig;
        break;

      case "trackHardware":
        disabled = !isValidTrackHardwareConfig;
        break;
    }
    return disabled;
  }, [qty, pli, configuration, loadingLineItem]);

  // on swatch selection
  const onSwatchSelection = useCallback(
    (swatchData: {
      swatchGroup: ProductSwatchGroup;
      selectedSwatch: ProductSwatch;
    }) => {
      const matchingType = pli?.availableOptions?.find(
        option => option.type === "Color" || option.type === "Finish"
      );
      const selectedOption = matchingType?.options?.find(
        option =>
          option?.id ===
          swatchData?.selectedSwatch?.options.filter(
            option =>
              option?.optionType === "Color" || option?.optionType === "Finish"
          )[0]?.id
      );
      onValueChange(selectedOption?.id, matchingType?.options);
      swatchErrorMessage && setSwatchErrorMessage(false);
      handleOnSwatchClick(swatchData);
    },
    [pli, selectedOptions, swatchErrorMessage]
  );

  // on value change from available options then refetch line item
  const onValueChange = useCallback(
    (value: string, options: ProductAvailableOption[]) => {
      const selectedOption = options?.find(option => option.id === value);
      let selectedIds = [];
      if (selectedOption?.id) {
        selectedIds = selectedOptions?.filter(
          option => option.type !== selectedOption?.type
        );
        selectedIds = [...singleOptions, ...selectedIds, selectedOption];
        selectedIds = selectedIds
          ?.map(e => e["id"])
          ?.map((e, i, final) => final.indexOf(e) === i && i)
          ?.filter(e => selectedIds[e])
          ?.map(e => selectedIds[e]);
      } else {
        selectedOptions?.forEach(option => {
          if (!options?.find(o => o.id === option.id)) {
            selectedIds.push(option);
          }
        });
      }
      const matchingType = pli?.availableOptions?.find(
        option => option.type === "Color" || option.type === "Finish"
      );
      const swatchData = selectedSwatches;
      const selectedOptionType = matchingType?.options?.find(
        option =>
          option?.id === swatchData?.Finish?.primaryOptionId ||
          swatchData?.Color?.primaryOptionId
      );
      if (
        selectedOptionType &&
        !selectedIds.find(
          option => option.type === "Color" || option.type === "Finish"
        )
      ) {
        selectedIds.push(selectedOptionType);
      }
      setSelectedOptions(selectedIds);
    },
    [pli?.availableOptions, selectedSwatches, selectedOptions, singleOptions]
  );

  const onProductConfigChange = useCallback(
    (
      key: string,
      value: string | number | ProductAvailableOption | CustomOption,
      customConfiguration: CustomProductConfiguration = { isCustom: false }
    ) => {
      setConfiguration(previousConfiguration => ({
        ...previousConfiguration,
        [key]: value,
        ...customConfiguration
      }));
    },
    []
  );

  const getAllShadeOptions = useMemo((): CustomOption[] => {
    const shadeOptions: CustomOption[] = [];

    const {
      mountTypes,
      controlsAndTilts,
      controlTypes,
      tiltTypes,
      controlPositions,
      linings,
      width,
      length,
      displayWidth,
      displayLength,
      displayControlLength,
      rollTypes,
      bracketColors
    } = configuration;

    mountTypes && shadeOptions.push(mountTypes);

    controlTypes && shadeOptions.push(controlTypes);

    tiltTypes && shadeOptions.push(tiltTypes);

    displayControlLength && shadeOptions.push(displayControlLength);

    controlPositions && shadeOptions.push(controlPositions);

    linings && shadeOptions.push(linings);

    controlsAndTilts && shadeOptions.push(controlsAndTilts);

    rollTypes && shadeOptions.push(rollTypes);

    bracketColors && shadeOptions.push(bracketColors);

    width &&
      shadeOptions.push({
        id: "",
        type: "Width",
        value: displayWidth
      });

    length &&
      shadeOptions.push({
        id: "",
        type: "Length",
        value: displayLength
      });

    return shadeOptions;
  }, [configuration]);

  const getAllDrapeOptions = useMemo((): CustomOption[] => {
    const drapeOptions: CustomOption[] = [];

    const { width, length, displayWidth, displayLength, panels, finish } =
      configuration;

    panels && drapeOptions.push(panels);

    finish && drapeOptions.push(finish);

    width &&
      drapeOptions.push({
        id: "",
        type: "Width",
        value: displayWidth
      });

    length &&
      drapeOptions.push({
        id: "",
        type: "Length",
        value: displayLength
      });

    return drapeOptions;
  }, [configuration]);

  const getAllCustomHardwareOptions = useMemo((): CustomOption[] => {
    const customHardwareOptions: CustomOption[] = [];
    configuration?.length &&
      customHardwareOptions.push({
        id: "",
        type: "Length",
        value: configuration?.displayLength
      });

    return customHardwareOptions;
  }, [configuration]);

  const getAllTrackHardwareOptions = useMemo((): CustomOption[] => {
    const trackHardwareOptions: CustomOption[] = [];

    const { mountTypes, controlTypes, panels, length, displayLength } =
      configuration;

    mountTypes && trackHardwareOptions.push(mountTypes);

    controlTypes && trackHardwareOptions.push(controlTypes);

    panels && trackHardwareOptions.push(panels);

    length &&
      trackHardwareOptions.push({
        id: "",
        type: "Width",
        value: displayLength
      });

    return trackHardwareOptions;
  }, [configuration]);

  // this will return all options to display
  const getSelectedOptions = useMemo(() => {
    let availableOptions: ProductAvailableOption[] = [
      ...singleOptions,
      ...selectedOptions
    ];

    let customOptions: (ProductAvailableOption | CustomOption)[] = [];
    availableOptions = availableOptions?.filter(
      (v, i, a) => a?.findIndex(t => t?.type === v?.type) === i
    );

    switch (
      pli?.customProductOptions?.customProductInformation?.customProductType
    ) {
      case "customHardware":
        customOptions = [...customOptions, ...getAllCustomHardwareOptions];
        break;

      case "shade":
        customOptions = [...customOptions, ...getAllShadeOptions];
        break;

      case "drape":
        customOptions = [...customOptions, ...getAllDrapeOptions];
        break;

      case "trackHardware":
        customOptions = [...customOptions, ...getAllTrackHardwareOptions];
        break;
    }

    // window label
    configuration?.roomLabel &&
      customOptions.push({
        id: "",
        type: "Window Label",
        value: getFormattedWindowLabel(
          configuration?.roomLabel,
          configuration?.roomLabelDescription
        )
      });

    return [...availableOptions, ...customOptions];
  }, [configuration, selectedOptions, singleOptions]);

  // custom shade fields mapper
  const getMappedShadeFields = useMemo(() => {
    let newCartBrokerFlag: boolean = false;
    if (giftRegistryDialog) {
      newCartBrokerFlag = yn(env.FEATURE_REACT_GIFT_REGISTRY);
    } else {
      newCartBrokerFlag = yn(env.FEATURE_PDP_CART_BROKER);
    }
    return clearCustomizationInput(
      newCartBrokerFlag
        ? {
            type: "shade",
            mountType: configuration?.mountTypes?.value,
            controlType: configuration?.controlTypes?.value,
            controlPosition: configuration?.controlPositions?.value,
            controlLength: configuration?.controlLength,
            lining: configuration?.linings?.value,
            bracketColor: configuration?.bracketColors?.value,
            rollType: configuration?.rollTypes?.value,
            tiltType: configuration?.tiltTypes?.value,
            controlAndTilt: configuration?.controlsAndTilts?.value,
            width: configuration?.width,
            length: configuration?.length,
            fulfillmentCode:
              pli?.customProductOptions?.customProductInformation
                ?.cwCustomProductCode ?? ""
          }
        : {
            shade: true,
            customProductType: "shade",
            mountTypeId: configuration?.mountTypes?.id,
            controlTypeId: configuration?.controlTypes?.id,
            controlPositionId: configuration?.controlPositions?.id,
            controlLength: configuration?.controlLength,
            liningId: configuration?.linings?.id,
            bracketColorId: configuration?.bracketColors?.id,
            rollTypeId: configuration?.rollTypes?.id,
            tiltTypeId: configuration?.tiltTypes?.id,
            controlAndTiltId: configuration?.controlsAndTilts?.id,
            shadeWidth: configuration?.width,
            shadeLength: configuration?.length
          }
    );
  }, [configuration, giftRegistryDialog, pli]);

  // custom hardware fields mapper
  const getMappedCustomHardwareFields:
    | CustomProductMapper
    | SkuCustomizationType = useMemo(() => {
    let newCartBrokerFlag: boolean = false;
    if (giftRegistryDialog) {
      newCartBrokerFlag = yn(env.FEATURE_REACT_GIFT_REGISTRY);
    } else {
      newCartBrokerFlag = yn(env.FEATURE_PDP_CART_BROKER);
    }
    return clearCustomizationInput(
      newCartBrokerFlag
        ? {
            type: "hardware",
            length: configuration?.length,
            fulfillmentCode:
              pli?.customProductOptions?.customProductInformation
                ?.cwCustomProductCode ?? ""
          }
        : {
            customHardware: true,
            customProductType: "customHardware",
            customHardwareLength: configuration?.length
          }
    );
  }, [configuration, pli]);

  // custom track hardware fields mapper
  const getMappedTrackHardwareFields:
    | CustomProductMapper
    | SkuCustomizationType = useMemo(() => {
    let newCartBrokerFlag: boolean = false;
    if (giftRegistryDialog) {
      newCartBrokerFlag = yn(env.FEATURE_REACT_GIFT_REGISTRY);
    } else {
      newCartBrokerFlag = yn(env.FEATURE_PDP_CART_BROKER);
    }
    return clearCustomizationInput(
      newCartBrokerFlag
        ? {
            type: "trackHardware",
            panel: configuration?.panels?.code
              ? configuration?.panels?.code
              : configuration?.panels?.value,
            controlType: configuration?.controlTypes?.value,
            mountType: configuration?.mountTypes?.code
              ? configuration?.mountTypes?.code
              : configuration?.mountTypes?.value,
            length: configuration?.length,
            fulfillmentCode:
              pli?.customProductOptions?.customProductInformation
                ?.cwCustomProductCode ?? ""
          }
        : {
            customProductType: "trackHardware",
            trackHardware: true,
            panelId: configuration?.panels?.id,
            controlTypeId: configuration?.controlTypes?.id,
            mountTypeId: configuration?.mountTypes?.id,
            trackHardwareLength: configuration?.length
          }
    );
  }, [configuration]);

  // custom drape field mapper
  const getMappedDrapeFields: CustomProductMapper | SkuCustomizationType =
    useMemo(() => {
      let newCartBrokerFlag: boolean = false;
      if (giftRegistryDialog) {
        newCartBrokerFlag = yn(env.FEATURE_REACT_GIFT_REGISTRY);
      } else {
        newCartBrokerFlag = yn(env.FEATURE_PDP_CART_BROKER);
      }
      return clearCustomizationInput(
        newCartBrokerFlag
          ? {
              type: "drape",
              width: configuration?.width,
              length: configuration?.length,
              panel: configuration?.panels?.code
                ? configuration?.panels?.code
                : configuration?.panels?.value,
              finish: configuration?.finish?.value,
              fulfillmentCode:
                pli?.customProductOptions?.customProductInformation
                  ?.cwCustomProductCode ?? ""
            }
          : {
              customProductType: "drape",
              drape: true,
              drapeWidth: configuration?.width,
              drapeLength: configuration?.length,
              panelId: configuration?.panels?.id,
              liningId: configuration?.linings?.id,
              finishId: configuration?.finish?.id
            }
      );
    }, [configuration, pli]);

  // this will return mapped product config for add to cart
  const getCustomProductConfig = useMemo(() => {
    let customConfig: CustomProductMapper | SkuCustomizationType = {
      roomLabel: getFormattedWindowLabel(
        configuration?.roomLabel,
        configuration?.roomLabelDescription
      )
    };
    switch (
      pli?.customProductOptions?.customProductInformation?.customProductType
    ) {
      case "customHardware":
        customConfig = { ...customConfig, ...getMappedCustomHardwareFields };
        break;
      case "shade":
        customConfig = { ...customConfig, ...getMappedShadeFields };
        break;
      case "drape":
        customConfig = { ...customConfig, ...getMappedDrapeFields };
        break;
      case "trackHardware":
        customConfig = { ...customConfig, ...getMappedTrackHardwareFields };
        break;
    }
    return customConfigHelper(customConfig);
  }, [
    configuration,
    getMappedDrapeFields,
    getMappedTrackHardwareFields,
    getMappedShadeFields,
    getMappedCustomHardwareFields
  ]);

  return (
    <>
      <Grid className="grow mb-16" container id={"component-custom-product"}>
        <CustomProductCard
          loading={loading}
          data={data}
          pli={pli}
          productSwatchImage={productSwatchImage}
          selectedSwatches={selectedSwatches}
          swatchGroups={swatchGroups}
          configuration={configuration}
          handleRequestSwatch={handleRequestSwatch}
          shadeType={
            SHADE_TYPE_MAPPER[
              pli?.customProductOptions?.customProductInformation
                ?.cwCustomProductCode
            ]
          }
          customProductType={
            pli?.customProductOptions?.customProductInformation
              ?.customProductType
          }
          handleOnSwatchClick={onSwatchSelection}
          onConfigChange={onProductConfigChange}
          onChange={onValueChange}
          swatchErrorMessage={swatchErrorMessage}
        />

        {/* PRICE CONFIGURATION SECTION */}
        {!loading && pli?.sku && (
          <>
            <Grid container className="justify-end mb-2 mt-5">
              <Grid item xs={12} sm={12} md={12}>
                {!isDisabled && (
                  <Typography className={typographyStyles.rhBaseBody1}>
                    {pageContent?.PRICE_AS_CONFIGURED}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={smDown ? 5 : 2}>
              <Grid item xs={12} sm={3}>
                {!isDisabled && skuInfo?.skuPriceInfo && (
                  <ProductSkuPriceInfo
                    data={skuInfo?.skuPriceInfo}
                    salePriceLabel={
                      data?.priceRangeDisplay?.salePriceLabel ?? ""
                    }
                  />
                )}
              </Grid>
              <Grid item xs={5} sm={3}>
                <Grid
                  container
                  className="items-center sm:justify-center justify-start"
                >
                  <RHStandardSelect
                    id={`quantity_custom_product`}
                    SelectProps={{
                      classes: {
                        root: "!text-[1rem] !text-[#000] !pt-[0.3333333333333333rem] !pb-0",
                        icon: `!top-[initial] !text-[7px] !right-[1px]`
                      }
                    }}
                    InputProps={{
                      style: {
                        background: theme?.palette?.background?.default
                      },
                      classes: {
                        underline: `!pb-[0.3333333333333333rem]`
                      }
                    }}
                    disabled={!Boolean(pli?.sku?.inventory?.inventoryRemaining)}
                    value={qty}
                    onChange={handleQuantityChange}
                    data-testid="quantity-select"
                  >
                    {Array.from({
                      length:
                        Number(pli?.sku?.inventory?.inventoryRemaining ?? 0) + 1
                    }).map((_, index) => (
                      <option key={`qty-${index}`} value={index}>
                        {index}
                      </option>
                    ))}
                  </RHStandardSelect>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <RelatedProductActions
                  isDisabled={isDisabled}
                  isAuthenticated={authenticated}
                  hasInventory={!!pli?.sku?.inventory}
                  isPreOrder={pli?.sku?.inventory?.preOrder}
                  qty={qty}
                  productLineItem={pli}
                  handleAddToCartClick={handleAddToCartClick}
                  handleAddToWishlistClick={handleAddToWishlistClick}
                  handleAddToGiftRegistryClick={handleAddToGiftRegistryClick}
                  options={getSelectedOptions}
                  customInfo={getCustomProductConfig}
                  isCustomProduct
                />
              </Grid>
            </Grid>
          </>
        )}

        {!isDisabled && skuInfo?.skuPriceInfo && (
          <>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Grid
                  style={{
                    fontFamily: "RHSans"
                  }}
                  className="!text-sm !font-medium !mb-3 sm:!mt-4 !mt-3"
                >
                  <RHDivider className="opacity-100 text-gray-200" />
                </Grid>

                <Sku
                  skuInventoryMessage={pli?.sku?.inventory?.inventoryMessage}
                  sku={pli?.sku}
                  hideRestrictions={false}
                  showSPOmsg={!isDisabled}
                  onPostalCode={handlePostalCodeChange}
                />
                <Typography className={typographyStyles.rhBaseCaption}>
                  {pageContent?.ITEM_TEXT} {pli?.sku?.inventory?.fullSkuId}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}

        {cartDialog && (
          <AddToCartDialog
            monogramOrder={monogramOrder}
            open={!!cartDialog}
            productId={cartDialog === "addToCart" ? data?.id : reqProdId}
            fullSkuId={cartDialog === "addToCart" ? _fullSkuId : reqSkuId}
            atgSkuId={cartDialog === "addToCart" ? atgSkuId : reqAtgSkuId}
            spo={
              cartDialog === "addToCart" ? pli?.sku?.restrictions?.spo : false
            }
            productDisplayName={data.displayName}
            onClose={handleCloseCartDialog}
            onCompleted={onAddToCartComplete}
            preBillMessage={pli?.sku?.restrictions?.preBillMessage}
            options={getSelectedOptions}
            qty={cartDialog === "addToCart" ? qty : 1}
            salePriceLabel={data?.priceRangeDisplay?.salePriceLabel}
            pricing={
              cartDialog === "addToCart" ? skuInfo?.skuPriceInfo : undefined
            }
            giftCardTo={""}
            giftCardFrom={""}
            customInfo={
              cartDialog === "addToCart" ? getCustomProductConfig : undefined
            }
            setConfirmed={setConfirmed}
            confirmed={confirmed}
          />
        )}
      </Grid>
      {postalCodeDialog ? (
        FEATURE_INTERNATIONAL ? (
          <InternationalPostalCodeDialog
            open={postalCodeDialog}
            style={{ paddingBottom: smUp ? "0" : "60%" }}
            onClose={() => setPostalCodeDialog(false)}
          />
        ) : (
          <PostalCodeDialog
            open={postalCodeDialog}
            style={{ paddingBottom: smUp ? "0" : "60%" }}
            onClose={() => setPostalCodeDialog(false)}
          />
        )
      ) : null}
      {wishlistDialog && (
        <AddToWishlistDialog
          open={wishlistDialog}
          onClose={handleCloseWishlistDialog}
          productId={data.id}
          fullSkuId={_fullSkuId ?? ""}
          atgSkuId={atgSkuId ?? ""}
          qty={qty}
          customInfo={getCustomProductConfig}
        />
      )}
      {giftRegistryDialog && (
        <>
          <AddToGiftRegistryDialog
            open={giftRegistryDialog}
            onClose={handleCloseGiftRegistryDialog}
            productId={data.id}
            fullSkuId={_fullSkuId ?? ""}
            atgSkuId={atgSkuId ?? ""}
            qty={qty}
            customInfo={getCustomProductConfig}
            monogramOrder={monogramOrder}
          />
        </>
      )}
    </>
  );
};

export default memoize(CustomProduct);
