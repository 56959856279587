import { Button, Tooltip, makeStyles } from "@material-ui/core";
import { Theme } from "@mui/joy";
import React, { useState } from "react";
import {
  SEE_ALL_OPTIONS_TOOLTIP,
  UPDATE_OTHER_OPTIONS_TOOLTIP
} from "utils/constants";

const useStyles = makeStyles((theme: Theme) => ({
  selectedButton: {
    maxWidth: "110px",
    borderRadius: "0px !important",
    border: "0.5px solid var(--Reimagine-Warm-RH-Black, #000 )!important",
    background: "var(--Reimagine-Warm-RH-White, #FFF)",
    "&:hover": {
      background: "var(--Reimagine-Warm-RH-White, #FFF)"
    },
    "& > button": {
      border: "unset !important"
    }
  },
  unselectedButton: {
    maxWidth: "105px",
    borderRadius: "0px !important",
    borderColor: "#F1F0ED !important",
    background: "#F1F0ED !important"
  },
  unavailableButton: {
    maxWidth: "105px",
    color: "#898886 !important",
    textDecoration: "line-through !important"
  },
  customTooltip: {
    padding: "8px 10px",
    color: "var(--Reimagine-Warm-RH-White, #FFF)",
    fontSize: "11px",
    fontFamily: "RHSans-Light",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "-0.11px",
    zIndex: 1,
    background: "#898886",
    fontWeight: 300,
    marginBottom: "4px"
  },
  arrow: {
    color: "#898886"
  }
}));

const OptionSelector = ({ selectedOption, opt, handleOptionSelect }) => {
  const classes = useStyles();

  const [isHovering, setIsHovering] = useState(false);

  const handleButtonClick = () => {
    if (opt?.status === "unavailable") {
      setIsHovering(true);
      handleOptionSelect(opt);
    } else {
      handleOptionSelect(opt);
    }
  };

  return (
    <div
      className={[
        selectedOption === opt.id
          ? classes?.selectedButton
          : classes?.unselectedButton
      ].join(" ")}
      onMouseEnter={() => setIsHovering(opt.status === "unavailable")}
      onMouseLeave={() => setIsHovering(false)}
      id="tooltip-container"
      data-testid="tooltip-container"
    >
      <Tooltip
        open={isHovering}
        title={
          opt?.status === "unavailable"
            ? selectedOption === opt.id
              ? UPDATE_OTHER_OPTIONS_TOOLTIP
              : SEE_ALL_OPTIONS_TOOLTIP
            : ""
        }
        arrow
        classes={{
          tooltip: classes.customTooltip,
          arrow: classes.arrow
        }}
        placement="top"
        enterDelay={500}
        leaveDelay={2000}
      >
        <Button
          style={{
            fontFamily: "RHSans-Light"
          }}
          id={`configurator_option_value_${opt.id}`}
          value={opt.value}
          data-testid={"configurator_option_value"}
          className={[
            selectedOption === opt.id
              ? classes?.selectedButton
              : classes?.unselectedButton,
            opt.status === "unavailable" ? classes?.unavailableButton : ""
          ].join(" ")}
          disableRipple
          onClick={handleButtonClick}
        >
          <p
            style={{ margin: "0px" }}
            className={
              opt.status === "unavailable" ? classes?.unavailableButton : ""
            }
            dangerouslySetInnerHTML={{ __html: opt.value }}
          />
        </Button>
      </Tooltip>
    </div>
  );
};

export default OptionSelector;
