import React, {
  FC,
  useEffect,
  useRef,
  useMemo,
  Dispatch,
  SetStateAction,
  ReactNode,
  useCallback
} from "react";
import he from "he";
import {
  useTheme,
  IconButton,
  Theme,
  Typography,
  Grid,
  useMediaQuery,
  makeStyles,
  createStyles,
  ButtonBase
} from "utils/material-ui-core";
import { Hidden, processEnvServer } from "hooks/useSsrHooks";
import useState from "hooks/useState";
// import RHZoomInIcon from "icon-zoomin";
import useParams from "hooks/useParams";
import useMediaString from "hooks/useMediaString";
import { ProductImagePresetKeys } from "utils/getImageUrlWithPreset";
import { IMAGE_ASPECT_RATIO } from "utils/constants";
import RHImage from "component-rh-image";
import {
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
  BREAKPOINT_LG,
  BREAKPOINT_XS
} from "utils/constants";
import memoize from "utils/memoize";
import useTypographyStyles from "hooks/useTypographyStyles";
import classNames from "classnames";
import yn from "yn";
import { useEnv } from "hooks/useEnv";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";
// const RHCarousel = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "component-rh-carousel" */ "component-rh-carousel"
//     ),
//   {
//     fallback: (
//       <RHImage
//         key={`productImage-0`}
//         aspectRatio={IMAGE_ASPECT_RATIO["heroImage"]}
//       />
//     )
//   }
// );

// // *** create ssr hook for rhcarousel

// const PreviousArrow = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "component-rh-carousel/PreviousArrow" */ "component-rh-carousel/PreviousArrow"
//     )
// );
// const NextArrow = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "component-rh-carousel/NextArrow" */ "component-rh-carousel/NextArrow"
//     )
// );

// const IframeDialog = loadable(
//   () => import(/* webpackChunkName: "dialog-iframe" */ "dialog-iframe"),
//   {
//     fallback: <></>
//   }
// );
// const ZoomViewerDialog = loadable(
//   () =>
//     import(/* webpackChunkName: "dialog-zoom-viewer" */ "dialog-zoom-viewer"),
//   {
//     fallback: <></>
//   }
// );
// const ImageThumbnailList = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "component-image-thumbnail-list" */ "component-image-thumbnail-list"
//     ),
//   { fallback: <></> }
// );

// *** create ssr hook for rhcarousel
import PreviousArrow from "component-rh-carousel/PreviousArrow";
import NextArrow from "component-rh-carousel/NextArrow";
import IframeDialog from "dialog-iframe";
import ZoomViewerDialog from "dialog-zoom-viewer";
import { usePageContent } from "customProviders/LocationProvider";
import PlayIconV2 from "icons/PlayIconV2";
import RHZoomLensInIcon from "icons/RHZoomLensInIcon";
import { RHZoomLenseOutIcon } from "icons/RHZoomLensOutIcon";
import VideoAutoPlayer from "./VideoAutoPlayer";
import CarouselArrowIcon from "icons/CarouselArrowIcon";

export interface HeroImageV2Props {
  images: ProductAlternateImage[] | null; // Noticed that it can happen that null gets passed into images prop in production
  isColorizable?: boolean;
  objectFit?: string;
  selectedSwatchImageUrl?: string;
  selectedOptionLabel?: boolean;
  onIndexChange?: () => void;
  isRHR?: boolean;
  imageLayout?: string;
  filmUrl?: string | null;
  setFilmUrl?: Dispatch<SetStateAction<string | null>>;
  autoplay?: boolean;
  isBannerVisible?: boolean;
  isSwatchSwitch?: number;
  isSwatchPanelDialogOpen?: boolean;
  priceComponent?: ReactNode;
  skuComponent?: ReactNode;
  imageCaptionText?: string;
  isColorized?: boolean;
  isNewPdpLayout?: boolean;
  romanceCopy?: JSX.Element;
  parentBaseId?: string;
  isNewPdpDots?: boolean;
}

const useStyles = (isSwatchPanelDialogOpen: Boolean | undefined) =>
  makeStyles((theme: Theme) =>
    createStyles({
      colorPreviewUnavailiable: {
        position: "absolute",
        top: 0,
        width: "100%",
        height: `${theme.spacing(8)}px !important`,
        backgroundColor: "rgba(0,0,0,0.4)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
      playArrow: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
        color: "#FFF",
        fontSize: 60,
        pointerEvents: "none"
      },
      playIconStyle: {
        zIndex: 10,
        height: "22px",
        width: "187px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
        gap: "1rem",
        // pointerEvents: "none"
        cursor: "pointer"
      },
      playIconStylePDPV3: {
        height: "22px !important",
        "& *": {
          height: "unset !important"
        }
      },
      playText: {
        color: "white",
        fontFamily: "RHSans-Light",
        fontSize: "15px",
        lineHeight: "10px",
        letterSpacing: "0.04em",
        pointer: "cursor"
      },
      onHeroImageHover: {
        "& .carouselArrow": {
          visibility: "hidden"
        },
        "&:hover .carouselArrow": {
          visibility: "visible"
        }
      },
      captionV2: {
        overflow: "hidden",
        marginTop: "20px",
        marginBottom: "17px",
        [theme.breakpoints.down(BREAKPOINT_MD)]: {
          marginBottom: "36px",
          marginLeft: "24px",
          marginRight: "24px"
        },
        [theme.breakpoints.down(BREAKPOINT_SM)]: {
          marginLeft: "16px",
          marginRight: "16px"
        }
      },
      captionMore: {
        display: "flex",
        alignItems: "baseline",
        "& .text": {
          flex: 1
        }
      },
      thumbnailStyle: {
        marginBottom: "20px"
      },

      nonrhrheroImage: {
        position: "relative",
        paddingBottom: "16px",
        [theme.breakpoints.up(BREAKPOINT_XS)]: {
          maxWidth: "375px !important",
          height: "375px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          maxWidth: "704px !important",
          height: "508px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          maxWidth: "555px !important",
          height: "401px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_LG)]: {
          maxWidth: "733px !important",
          height: "529px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          maxWidth: "1030px !important",
          height: "742px !important"
        }
      },
      setHeight: {},
      heroContainer: {
        [theme.breakpoints.up(BREAKPOINT_XS)]: {
          maxWidth: "375px !important",
          height: "375px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          maxWidth: "704px !important",
          height: "508px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          maxWidth: "555px !important",
          height: "401px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_LG)]: {
          maxWidth: "733px !important",
          height: "529px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          maxWidth: `1030px !important`,
          height: isSwatchPanelDialogOpen
            ? "50vw !important"
            : "742px !important"
        }
      },
      setPaddingZero: {
        padding: "0px !important"
      },
      rhHeroImageZoomIn: {
        [theme.breakpoints.down(BREAKPOINT_SM)]: {
          height: "43.9vh"
        },
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          height: "46.56vh"
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          height: "54.09vh"
        },
        [theme.breakpoints.up(BREAKPOINT_LG)]: {
          height: "71.09vh"
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          height: "69.86vh"
        },
        "& #image-container": {
          width: "fit-content",
          transform: "scale(2.5)",
          transformOrigin: "center center"
        },
        "& #image-container.rightCenter": {
          transformOrigin: "right center"
        }
      },
      rhHeroImageZoomOut: {
        "& #image-container": {
          width: "100%",
          transform: "scale(1)"
        }
      },
      rhrheroImage: {
        position: "relative",
        "& .slick-track": {
          display: "flex",
          alignItems: "center"
        }
      },
      rhrheroImageForZoom: {
        position: "relative",
        transition: "transform 0.5s ease-in-out",
        overflow: "hidden",
        "& *": {
          height: "100%"
        }
      },
      rhHeroImageHorizontalZoomed: {
        padding: "10% 3.6% 10% 0px !important"
      },
      rhHeroImageHorizontal: {
        padding: "3.6% !important"
      },
      rhrZoomLensButton: {
        color: "transparent",
        padding: "0px"
      },
      rhrZoomInButton: {
        position: "absolute",
        bottom: theme.spacing(1),
        right: theme.spacing(1),
        zIndex: 20,
        backgroundColor: "transparent",
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          bottom: theme.spacing(1.8),
          right: theme.spacing(1.8)
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          bottom: theme.spacing(0.7),
          right: theme.spacing(-1.2)
        }
      },

      verticalImage: {
        "&#component-rh-image #component-rh-image_wrapper": {
          [theme.breakpoints.up(BREAKPOINT_XS)]: {
            paddingTop: "7px",
            paddingBottom: "7px"
          },
          [theme.breakpoints.up(BREAKPOINT_SM)]: {
            paddingTop: "14px",
            paddingBottom: "14px"
          },
          [theme.breakpoints.up(BREAKPOINT_MD)]: {
            paddingTop: "11px",
            paddingBottom: "11px"
          },
          [theme.breakpoints.up(BREAKPOINT_LG)]: {
            paddingTop: "14px",
            paddingBottom: "14px"
          },
          [theme.breakpoints.up(BREAKPOINT_XL)]: {
            paddingTop: "20px",
            paddingBottom: "20px"
          }
        }
      },

      horizontalImage: {
        "&#component-rh-image #component-rh-image_wrapper": {
          [theme.breakpoints.up(BREAKPOINT_XS)]: {
            paddingRight: "7px",
            paddingLeft: "7px"
          },
          [theme.breakpoints.up(BREAKPOINT_SM)]: {
            paddingRight: "14px",
            paddingLeft: "14px"
          },
          [theme.breakpoints.up(BREAKPOINT_MD)]: {
            paddingRight: "11px",
            paddingLeft: "11px"
          },
          [theme.breakpoints.up(BREAKPOINT_LG)]: {
            paddingRight: "14px",
            paddingLeft: "14px"
          },
          [theme.breakpoints.up(BREAKPOINT_XL)]: {
            paddingRight: "20px",
            paddingLeft: "20px"
          }
        }
      },

      squareImage: {
        "&#component-rh-image #component-rh-image_wrapper": {
          [theme.breakpoints.up(BREAKPOINT_XS)]: {
            padding: "22px"
          },
          [theme.breakpoints.up(BREAKPOINT_SM)]: {
            padding: "41px"
          },
          [theme.breakpoints.up(BREAKPOINT_MD)]: {
            padding: "32px"
          },
          [theme.breakpoints.up(BREAKPOINT_LG)]: {
            padding: "43px"
          },
          [theme.breakpoints.up(BREAKPOINT_XL)]: {
            padding: "60px"
          }
        }
      },
      rhCaptionPrice: {
        fontFamily: "RHSans !important",
        color: "#404040 !important",
        fontSize: "9.39px !important",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "120% !important",
        letterSpacing: "0.376px !important"
      },
      priceStartAt: {
        fontFamily: "RHSans",
        color: "#000",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: 100,
        lineHeight: "13.2px",
        letterSpacing: "0.44px",
        textTransform: "uppercase",
        textAlign: "center"
      },
      memberPrice: {
        fontFamily: "RHSans",
        color: "#000",
        fontSize: "20px",
        fontWeight: 100,
        lineHeight: "23px",
        letterSpacing: "-0.4px",
        textTransform: "uppercase",
        textAlign: "center"
      },

      pinchDialogBox: {
        zIndex: 10,
        height: "22px",
        width: "187px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        //  marginTop: "437px",
        left: 0,
        right: 0,
        // top: 0,
        bottom: 30,
        margin: "auto",
        gap: "1rem",
        pointerEvents: "none",
        background: "#808080"
      },
      pinchDialog: {
        color: "var(--colour-background-panel, #FFF)",
        textAlign: "center",
        fontFamily: "Roboto Mono",
        fontSize: "6px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal"
      },
      heroWrapperStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
      heroWrapperStylePdpV3: {
        height: "100%",
        "& *": {
          height: "100%"
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          height: "calc(100vh - 180px - 76px)",
          "& *": {
            height: "calc(100vh - 180px - 76px)"
          },
          "& #component-rh-carousel *": {
            height: "unset !important"
          }
        }
      },
      videoContainerStyles: {
        width: "100% !important",
        display: "flex !important",
        justifyContent: "center",
        alignItems: "center"
      },
      magicSlider: {
        "& .slick-dots": {
          "& > div": {
            height: "20px !important"
          }
        },
        "& .magic-dots ": {
          width: "145px !important",
          height: "20px !important",
          bottom: "0px !important",

          "& > ul ": {
            height: "20px !important",
            gap: "9px"
          },
          "& > ul > li ": {
            fontSize: 10,
            width: "10px",
            height: "10px !important"
          },
          "& > ul > li > button ": {
            fontSize: 10,
            width: "10px",
            height: "10px !important"
          },
          "& > ul > li  > button:before": {
            fontSize: 10,
            width: "10px",
            height: "10px !important"
          },
          "& > ul > .small  > button": {
            fontSize: 6,
            width: "6px",
            height: "6px !important"
          },
          "& > ul > .small  > button:before": {
            fontSize: 6,
            width: "6px",
            height: "6px"
          },
          "& > ul .slick-active > button:before": {
            color: "black",
            fontSize: 10,
            width: "10px",
            height: "10px !important"
          }
        }
      },
      pdpImageWrapper: {
        "& > div > button": {
          display: "none !important"
        }
      }
    })
  );

export const HeroImageV2: FC<HeroImageV2Props> = ({
  images,
  isColorizable,
  objectFit,
  selectedSwatchImageUrl,
  selectedOptionLabel,
  onIndexChange,
  isRHR,
  imageLayout,
  filmUrl,
  setFilmUrl,
  autoplay = false,
  isBannerVisible,
  isSwatchSwitch,
  isSwatchPanelDialogOpen,
  priceComponent,
  skuComponent,
  imageCaptionText,
  isColorized,
  isNewPdpLayout,
  romanceCopy,
  parentBaseId
}) => {
  const baseId = `${parentBaseId}-heroImageV2`;

  const { pageContent } = usePageContent();
  const env = useEnv();
  const theme = useTheme();
  const classes = useStyles(isSwatchPanelDialogOpen)();
  const mediaString = useMediaString();
  const [swatchImageUrl, setSwatchImageUrl] = useState<string | undefined>("");
  // index is undefined to prevent selection on thumbnail
  const [activeIndex, setActiveIndex] = useState<number | undefined>(0);
  const [isZoomViewerDialogOpen, setIsZoomViewerDialogOpen] = useState(false);
  const [video, setVideo] = useState<string>("");
  const [more, setMore] = useState(false);
  const [needMore, setNeedMore] = useState(false);
  const [isHeroImageHovered, setHeroImageHovered] = useState<boolean>(false);
  const captionRef = useRef<HTMLSpanElement>(null);
  const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const xsUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("xs"));
  const isRHRImage = yn(env.FEATURE_RHR_IMAGE);
  const [swatchImageChangeFlag, setSwatchImageChangeFlag] =
    useState<boolean>(false);
  const [isHeroImageUrlFailed, setIsHeroImageUrlFailed] =
    useState<boolean>(false);
  const sliderRef = useRef<Slider>(null);
  const magicSliderRef = useRef<Slider>(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  // Custom previous arrow component
  const CustomPrevArrow = () => {
    const handleOnClick = useCallback(() => {
      if (magicSliderRef?.current) {
        if (activeSlideIndex === 0) {
          return;
        } else {
          setActiveSlideIndex(activeSlideIndex - 1);
          return magicSliderRef.current?.slickGoTo(activeSlideIndex - 1);
        }
      }
    }, []);
    return (
      <ButtonBase
        id="carousel-arrow-button"
        onClick={handleOnClick}
        style={{ height: 20, float: "inline-end" }}
      >
        <CarouselArrowIcon style={{ transform: "rotate(180deg)" }} />
      </ButtonBase>
    );
  };

  // Custom next arrow component
  const CustomNextArrow = () => {
    const handleOnClick = useCallback(() => {
      if (magicSliderRef?.current) {
        if (
          [...imageList.slice(0, 1), ...imageList.slice(2)]?.length ===
          activeSlideIndex
        ) {
          return;
        } else {
          setActiveSlideIndex(activeSlideIndex + 1);
          return magicSliderRef.current?.slickGoTo(activeSlideIndex + 1);
        }
      }
    }, []);
    return (
      <ButtonBase
        id="carousel-arrow-button"
        onClick={handleOnClick}
        style={{ height: 20, float: "inline-start" }}
      >
        <CarouselArrowIcon />
      </ButtonBase>
    );
  };

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: index => setActiveSlideIndex(index),
    appendDots: dots => {
      return (
        <div
          id="MagicSliderV2"
          style={{
            display: "flex",
            height: 20,
            gap: 40,
            margin: "40 0 40 0",
            justifyContent: "center",
            marginTop: "40px"
          }}
        >
          <div style={{ height: "20px !important" }}>
            <CustomPrevArrow />
          </div>
          <div>
            <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
          </div>
          <div>
            <CustomNextArrow />
          </div>
        </div>
      );
    }
  };

  const isRHRImageStyling = useMemo(
    () => isRHRImage && smDown && isRHR,
    [isRHRImage, isRHR, smDown]
  );

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseCaption", "rhBaseBody1"]
  });

  const imageList = useMemo(() => {
    const imagesWithSelectedImageUrl = [
      {
        imageUrl: selectedSwatchImageUrl || "",
        caption: "",
        video: "",
        // Other optional properties with default values
        autoplay: false,
        lifestyleImage: false,
        pdpImageLayout: "",
        sbrId: "",
        sliderCss: "width: 100%; height: auto;"
      },
      ...(images ? images : [])
    ].filter(image => !image?.lifestyleImage);

    return imagesWithSelectedImageUrl?.length
      ? imagesWithSelectedImageUrl
      : [
          {
            imageUrl: `//media.restorationhardware.com/is/image/rhis/ImageUnavailable_Modern?wid=2000&qlt=80`,
            caption: "Image Unavailable",
            video: ""
          }
        ];
  }, [images, selectedSwatchImageUrl]);

  const genratedFilmUrl = useMemo((): string => {
    if (video) {
      return video.includes(".com")
        ? video
        : `https://www.youtube.com/embed/${video}?modestbranding=1&rel=0&showinfo=0&autoplay=1&controls=0`;
    } else if (filmUrl) {
      return filmUrl.includes(".com")
        ? filmUrl
        : `https://www.youtube.com/embed/${filmUrl}?modestbranding=1&rel=0&showinfo=0&autoplay=1&controls=0`;
    }
    return "";
  }, [video, filmUrl]);

  const params = useParams({
    fullSkuId: ""
  });

  if (!processEnvServer) {
    useEffect(() => {
      if (!needMore && captionRef.current) {
        const captionStyle = window.getComputedStyle(captionRef.current);
        const lineHeight = parseInt(captionStyle.lineHeight);
        const height = parseInt(captionStyle.height);

        if (lineHeight > 0 && height > lineHeight) {
          setNeedMore(true);
        }
      }
    }, [captionRef, needMore]);
  }

  useEffect(() => {
    if (swatchImageChangeFlag) {
      if (sliderRef?.current) {
        setActiveIndex(undefined);
        sliderRef.current?.slickGoTo(0);
      }
      setSwatchImageChangeFlag(false);
    }
  }, [swatchImageChangeFlag]);

  const handleSwatchImageLoad = () => {
    setSwatchImageChangeFlag(true);
  };

  const activeImage = imageList[activeIndex || 0];

  const handleOnIndexChange = (index: any) => {
    // if (swatchImageUrl && onIndexChange) {
    //   onIndexChange();
    // }

    if (sliderRef?.current) {
      sliderRef?.current?.slickGoTo(index);
    }
    setActiveIndex(index);
  };

  // const handleOnPreviousArrowClick = (index: number) => {
  //   const i = index - 1;
  //   if (i >= 0) {
  //     handleOnIndexChange(i);
  //   } else {
  //     handleOnIndexChange(imageList.length - 1);
  //   }
  //   if (!processEnvServer) {
  //     document.body.dispatchEvent(
  //       new CustomEvent("pre-pdp-click", {
  //         detail: {
  //           item: {
  //             action: "Alt Caurosel - Prev Arrow"
  //           }
  //         }
  //       })
  //     );
  //   }
  // };

  // const handleOnNextArrowClick = (index: number) => {
  //   const i = index + 1;

  //   if (i <= imageList.length - 1) {
  //     handleOnIndexChange(i);
  //   } else {
  //     handleOnIndexChange(0);
  //   }
  //   if (!processEnvServer) {
  //     document.body.dispatchEvent(
  //       new CustomEvent("pre-pdp-click", {
  //         detail: {
  //           item: {
  //             action: "Alt Caurosel - Next Arrow"
  //           }
  //         }
  //       })
  //     );
  //   }
  // };

  useEffect(() => {
    // set swatch undefined if swatch is inactive
    if (!selectedSwatchImageUrl) {
      setSwatchImageUrl(undefined);
    } else {
      // set index undefined if swatch is active
      setActiveIndex(undefined);
      // set swatch if active
      if (selectedSwatchImageUrl !== (images || [])[0]?.imageUrl) {
        setSwatchImageUrl(selectedSwatchImageUrl);
      } else if (selectedSwatchImageUrl === (images || {})[0]?.imageUrl) {
        setSwatchImageUrl((images || [])[0]?.imageUrl);
      }
    }
  }, [selectedSwatchImageUrl]);
  const productImageUrl = React.useMemo(() => {
    if (isHeroImageUrlFailed) {
      // return default image available soon url when url gets failed to load
      return "//media.restorationhardware.com/is/image/rhis/4KH?src=ir(rhir/prod27210510_E22519282534_TQ_CO22_RHR?src=rhir/obj=Fabric&src=rhir/1649004_E2219536411_O_Bsq_reapeat&res=300&illum=0&obj=Fabric&req=object&illum=0)&$PDP-IR-1600$";
    }
    return swatchImageUrl || (images || [])[0]?.imageUrl;
  }, [swatchImageUrl, isHeroImageUrlFailed, images]);

  useEffect(() => {
    if (!isSwatchPanelDialogOpen && isZoomViewerDialogOpen) {
      setIsZoomViewerDialogOpen(false);
    }
  }, [isSwatchPanelDialogOpen]);
  const imageCaptionUi = useMemo(() => {
    if (isSwatchPanelDialogOpen) {
      return (
        <Grid
          item
          xs={12}
          style={{
            paddingTop: 0,
            minHeight: "inherit"
          }}
        >
          <Grid
            item
            onClick={() => {
              if (needMore) {
                setMore(!more);
              }
            }}
            xs={true}
            className={`${needMore && !more ? classes.captionMore : ""}`}
            // id={baseId}
            // key={`${baseId}-container-caption-container`}
            // data-testid={baseId}
          >
            <Typography
              style={{
                textTransform: "capitalize",
                textAlign: "center",
                fontFamily: "RHSans",
                color: "#404040",
                fontSize: "9.39px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "120%",
                letterSpacing: "0.376px"
              }}
              ref={captionRef}
              className={classNames([
                typographyStyles.rhBaseCaption,
                "text",
                classes.rhCaptionPrice
              ])}
              //   id={baseId}
              // key={`${baseId}-container-caption-container-showCaption`}
              // data-testid={baseId}
            >
              <span
                style={{ textTransform: "none" }}
                dangerouslySetInnerHTML={{
                  __html: he.decode(
                    imageCaptionText
                      ? `${pageContent?.ShowIn} ${imageCaptionText}`
                      : activeImage.caption ?? ""
                  )
                }}
              ></span>
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid
        item
        xs={12}
        style={{
          paddingTop: 0,
          minHeight: "2em"
        }}
      >
        {imageCaptionText && (
          <Grid
            item
            onClick={() => {
              if (needMore) {
                setMore(!more);
              }
            }}
            xs={true}
            className={`${needMore && !more ? classes.captionMore : ""}`}
          >
            <Typography
              style={{
                // textTransform: "capitalize",
                fontFamily: "RHSans",
                color: "#404040",
                fontSize: "9.39px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "120%",
                letterSpacing: "0.376px"
              }}
              ref={captionRef}
              className={classNames([
                typographyStyles.rhBaseCaption,
                "text",
                classes.rhCaptionPrice
              ])}
            >
              <span style={{ textTransform: "none" }}>
                {imageCaptionText &&
                  `${pageContent?.ShowIn} ${imageCaptionText}`}
              </span>
            </Typography>
          </Grid>
        )}
        {!imageCaptionText && activeIndex === 0 && swatchImageUrl && (
          <>
            {activeImage.caption && (
              <Grid
                item
                onClick={() => {
                  if (needMore) {
                    setMore(!more);
                  }
                }}
                xs={true}
                className={`${classes.captionV2} ${
                  needMore && !more ? classes.captionMore : ""
                }`}
              >
                {/* Hide image caption when it's a SKU search */}
                {!params.fullSkuId && (
                  <Typography
                    style={{
                      fontFamily: "RHSans",
                      color: "#404040",
                      fontSize: "9.39px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "120%",
                      letterSpacing: "0.376px"
                    }}
                    ref={captionRef}
                    className={classNames([
                      typographyStyles.rhBaseCaption,
                      "text"
                    ])}
                    noWrap={needMore && !more}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: he.decode(
                          (!selectedOptionLabel && activeImage.caption) || ""
                        )
                      }}
                      style={{ textTransform: "none" }}
                    />
                  </Typography>
                )}
                {!selectedOptionLabel && (
                  <Typography className={typographyStyles.rhBaseCaption}>
                    {needMore && (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          textTransform: "lowercase"
                        }}
                      >
                        {more ? pageContent?.LESS : pageContent?.MORE}
                      </span>
                    )}
                  </Typography>
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>
    );
  }, [
    isSwatchPanelDialogOpen,
    needMore,
    more,
    mdUp,
    xsDown,
    selectedOptionLabel,
    imageCaptionText,
    activeIndex,
    swatchImageUrl,
    classes
  ]);
  const swatchOpenPriceContent = useMemo(() => {
    return (
      <Grid
        container
        data-testid="image-containter-price-content"
        style={{
          display: "flex",
          gap: "24px",
          marginTop: "20px",
          textAlign: "center",
          justifyContent: "center"
        }}
      >
        <Grid item xs={12}>
          <Grid
            container
            style={{ display: "flex", gap: "16px", flexDirection: "column" }}
          >
            {imageCaptionUi}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <IconButton
                className={classes.rhrZoomLensButton}
                onClick={() => {
                  setIsZoomViewerDialogOpen(val => !val);
                }}
                disableRipple
              >
                {isZoomViewerDialogOpen ? (
                  <RHZoomLensInIcon style={{ height: "20px", width: "20px" }} />
                ) : (
                  <RHZoomLenseOutIcon
                    style={{ height: "20px", width: "20px" }}
                  />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            style={{ display: "flex", gap: "4px", flexDirection: "column" }}
          >
            {priceComponent}
          </Grid>
        </Grid>
        {skuComponent}
      </Grid>
    );
  }, [needMore, more, mdUp, xsDown, selectedOptionLabel, pageContent]);

  const sliderSettings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: !!(
        !isSwatchPanelDialogOpen &&
        images &&
        images?.length > 1 &&
        isHeroImageHovered
      ),
      nextArrow: (
        <NextArrow
          className={"carouselArrow"}
          // handleNext={() => handleOnNextArrowClick(activeIndex || 0)}
        />
      ),
      prevArrow: (
        <PreviousArrow
          className={"carouselArrow"}
          // handlePrev={() => handleOnPreviousArrowClick(activeIndex || 0)}
        />
      )
    }),
    [isSwatchPanelDialogOpen, images, activeIndex, isHeroImageHovered]
  );

  return (
    <Grid
      container
      className={`${classes.heroWrapperStyle} ${classes.heroWrapperStylePdpV3}`}
      id="hero-image-container"
      key={`${baseId}-heroImage-container`}
      data-testid="hero-image-container"
    >
      <Grid
        item
        xs={12}
        id={`${baseId}-rhHeroImageZoomOut`}
        key={`${baseId}-rhHeroImageZoomOut`}
        data-testid={`${baseId}-rhHeroImageZoomOut`}
        style={{
          margin: isRHRImageStyling ? "auto" : "",
          width: isRHRImageStyling ? "87%" : ""
        }}
        className={classNames([
          isRHRImage && isRHR ? classes.rhrheroImage : "",
          ...[
            isSwatchPanelDialogOpen
              ? [classes.setPaddingZero, classes.rhrheroImageForZoom]
              : []
          ],
          isSwatchPanelDialogOpen
            ? classes.rhHeroImageZoomIn
            : classes.rhHeroImageZoomOut,
          imageLayout === "horizontal" &&
            isSwatchPanelDialogOpen &&
            (!isZoomViewerDialogOpen
              ? classes.rhHeroImageHorizontalZoomed
              : classes.rhHeroImageHorizontal),
          ["square", "vertical"].includes(imageLayout || "") &&
            (!isZoomViewerDialogOpen ? null : classes.rhHeroImageHorizontal)
        ])}
      >
        <div
          style={{
            position: "relative",
            marginTop: "16px",
            margin: isRHRImageStyling ? "auto" : "",
            width: "100%"
          }}
          className={classes.pdpImageWrapper}
          id={`${baseId}-pdpImageWrapper`}
          key={`${baseId}-pdpImageWrapper`}
          data-testid={`${baseId}-pdpImageWrapper`}
        >
          {!processEnvServer ? (
            <Slider
              {...settings}
              className={classes.magicSlider}
              initialSlide={activeSlideIndex}
              ref={magicSliderRef}
            >
              {[...imageList.slice(0, 1), ...imageList.slice(2)].map(
                (image, index) => {
                  if (image?.video && image?.autoplay) {
                    return (
                      <VideoAutoPlayer
                        parentBaseId={baseId}
                        key={`${baseId}-videoPlayer`}
                        index={index}
                        image={
                          image?.video?.includes(".com")
                            ? image?.video
                            : `https://www.youtube.com/embed/${
                                image?.video?.split("&")[0]
                              }?modestbranding=1&rel=0&showinfo=0&autoplay=1&controls=0`
                        }
                        activeIndex={activeIndex}
                      />
                    );
                  }

                  return (
                    <RHImage
                      isNewPdpLayout={isNewPdpLayout}
                      parentBaseId={baseId}
                      key={`productImage-${index}`}
                      data-testid={"mobile-pdp-image"}
                      loadingBehavior={index === 0 ? "eager" : "lazy"}
                      objectFit={objectFit}
                      src={
                        index === 0 && (productImageUrl as string)
                          ? (productImageUrl as string)
                          : image.imageUrl
                      }
                      alt={image?.caption || image?.imageUrl}
                      heroContainerStyles={classNames([
                        classes.heroContainer,
                        !isSwatchPanelDialogOpen ? classes.heroContainer : null,
                        isSwatchPanelDialogOpen ? classes.setHeight : null
                      ])}
                      preset={
                        isColorized
                          ? (`PDPv2-hero-${mediaString}` as ProductImagePresetKeys)
                          : // `pdp-hero-${mediaString}` as ProductImagePresetKeys
                            (`PDPv2-hero-${mediaString}` as ProductImagePresetKeys)
                      }
                      heroImageStyles={
                        imageLayout == "vertical"
                          ? classes.verticalImage
                          : imageLayout == "horizontal"
                          ? classes.horizontalImage
                          : imageLayout == "square"
                          ? classes.squareImage
                          : classes.verticalImage
                      }
                      onClick={() => {
                        setIsZoomViewerDialogOpen(true);
                        if (image.video && isZoomViewerDialogOpen) {
                          setVideo(
                            image.video.includes(".com")
                              ? image.video // scene 7 url
                              : image.video.split("&")[0] // youtube id
                          );
                        }
                      }}
                      aspectRatio={IMAGE_ASPECT_RATIO["heroImage"]}
                      isSwatchPanelDialogOpen={isSwatchPanelDialogOpen}
                      isZoomViewerDialogOpen={isZoomViewerDialogOpen}
                      imageLayout={imageLayout}
                      setIsHeroImageUrlFailed={setIsHeroImageUrlFailed}
                      failedImageUrl={
                        isHeroImageUrlFailed ? (images || [])[0]?.imageUrl : ""
                      }
                      imageStyles={{
                        height: "inherit",
                        cursor: !isZoomViewerDialogOpen
                          ? `url('/img/zoom-in.svg'), auto`
                          : "pointer"
                      }}
                    >
                      {image.video !== "" ? (
                        <div
                          className={classNames({
                            [classes.playIconStyle]: true,
                            [classes.playIconStylePDPV3]: true
                          })}
                          onClick={() => {
                            if (image.video) {
                              setVideo(
                                image.video.includes(".com")
                                  ? image.video // scene 7 url
                                  : image.video.split("&")[0] // youtube id
                              );
                            }
                          }}
                        >
                          <span className={classes.playText}>
                            {pageContent.WATCH_THE_FILM}
                          </span>
                          <PlayIconV2 />
                        </div>
                      ) : null}
                    </RHImage>
                  );
                }
              )}
            </Slider>
          ) : null}
          <Hidden
            smDown={!isSwatchPanelDialogOpen ? smDown : undefined}
            xsUp={true}
          >
            <div
              className={classes.onHeroImageHover}
              onMouseEnter={() => setHeroImageHovered(true)}
              onMouseLeave={() => setHeroImageHovered(false)}
            >
              <>
                {isSwatchPanelDialogOpen ? (
                  <RHImage
                    parentBaseId={baseId}
                    key={`productImage-${activeIndex}`}
                    data-testid={"desktop-pdp-image"}
                    objectFit={objectFit}
                    loadingBehavior="eager"
                    src={
                      (activeIndex === 0 || activeIndex === undefined) &&
                      swatchImageUrl &&
                      isColorizable
                        ? swatchImageUrl
                        : activeImage.imageUrl
                    }
                    alt={activeImage.caption || activeImage.imageUrl}
                    preset={
                      isColorized
                        ? (`PDPv2-hero-${mediaString}` as ProductImagePresetKeys)
                        : // `pdp-hero-${mediaString}` as ProductImagePresetKeys
                          (`PDPv2-hero-${mediaString}` as ProductImagePresetKeys)
                    }
                    heroImageStyles={
                      imageLayout == "vertical"
                        ? classes.verticalImage
                        : imageLayout == "horizontal"
                        ? classes.horizontalImage
                        : imageLayout == "square"
                        ? classes.squareImage
                        : classes.verticalImage
                    }
                    onClick={() => {
                      if (activeImage.video) {
                        setVideo(
                          activeImage.video.includes(".com")
                            ? activeImage.video // scene 7 url
                            : activeImage.video.split("&")[0] // youtube id
                        );
                      }
                    }}
                    aspectRatio={IMAGE_ASPECT_RATIO["heroImage"]}
                    imageSkeletonBackgroundColor="#f9f7f4"
                    imageWrapperStyles={{ backgroundColor: "#f9f7f4" }}
                    heroContainerStyles={classNames([
                      !isSwatchPanelDialogOpen ? classes.heroContainer : null,
                      isSwatchPanelDialogOpen ? classes.setHeight : null
                    ])}
                    isSwatchPanelDialogOpen={isSwatchPanelDialogOpen}
                    isZoomViewerDialogOpen={isZoomViewerDialogOpen}
                    imageLayout={imageLayout}
                    setIsHeroImageUrlFailed={setIsHeroImageUrlFailed}
                    failedImageUrl={
                      isHeroImageUrlFailed ? (images || [])[0]?.imageUrl : ""
                    }
                  >
                    {
                      <>
                        {" "}
                        {activeImage.video !== "" && (
                          <PlayIconV2 className={classes.playArrow} />
                        )}
                      </>
                    }
                  </RHImage>
                ) : (
                  <>
                    <Slider
                      {...sliderSettings}
                      ref={sliderRef}
                      afterChange={index => setActiveIndex(index)}
                    >
                      {selectedSwatchImageUrl && isColorizable && (
                        <RHImage
                          parentBaseId={baseId}
                          key={`productImage-selectedSwatch`}
                          data-testid={"desktop-pdp-image"}
                          objectFit={objectFit}
                          loadingBehavior="eager"
                          src={productImageUrl}
                          alt=""
                          preset={
                            isColorized
                              ? (`PDPv2-hero-${mediaString}` as ProductImagePresetKeys)
                              : // `pdp-hero-${mediaString}` as ProductImagePresetKeys
                                (`PDPv2-hero-${mediaString}` as ProductImagePresetKeys)
                          }
                          heroImageStyles={
                            imageLayout == "vertical"
                              ? classes.verticalImage
                              : imageLayout == "horizontal"
                              ? classes.horizontalImage
                              : imageLayout == "square"
                              ? classes.squareImage
                              : classes.verticalImage
                          }
                          aspectRatio={IMAGE_ASPECT_RATIO["heroImage"]}
                          imageSkeletonBackgroundColor="#f9f7f4"
                          imageWrapperStyles={{
                            backgroundColor: "#f9f7f4"
                          }}
                          setIsHeroImageUrlFailed={setIsHeroImageUrlFailed}
                          failedImageUrl={
                            isHeroImageUrlFailed
                              ? (images || [])[0]?.imageUrl
                              : ""
                          }
                          heroContainerStyles={classNames([
                            classes.heroContainer,
                            isSwatchPanelDialogOpen ? classes.setHeight : null
                          ])}
                          onLoad={
                            isHeroImageUrlFailed ? null : handleSwatchImageLoad
                          }
                        />
                      )}
                      {(images || [])
                        .slice(selectedSwatchImageUrl && isColorizable ? 1 : 0)
                        .map((image, index, self) => {
                          const imageSrc = image?.imageUrl;
                          if (image?.video && image?.autoplay) {
                            return (
                              <div className={classes.videoContainerStyles}>
                                <VideoAutoPlayer
                                  parentBaseId={baseId}
                                  key={`${baseId}-videoPlayer`}
                                  index={index}
                                  image={
                                    image?.video?.includes(".com")
                                      ? image?.video
                                      : `https://www.youtube.com/embed/${
                                          image?.video?.split("&")[0]
                                        }?modestbranding=1&rel=0&showinfo=0&autoplay=1&controls=0`
                                  }
                                  activeIndex={activeIndex}
                                />
                              </div>
                            );
                          }
                          return (
                            <RHImage
                              parentBaseId={baseId}
                              key={`productImage-${index}`}
                              data-testid={"desktop-pdp-image"}
                              objectFit={objectFit}
                              loadingBehavior="eager"
                              src={imageSrc}
                              alt={image?.caption || image?.imageUrl}
                              preset={
                                isColorized
                                  ? (`PDPv2-hero-${mediaString}` as ProductImagePresetKeys)
                                  : // `pdp-hero-${mediaString}` as ProductImagePresetKeys
                                    (`PDPv2-hero-${mediaString}` as ProductImagePresetKeys)
                              }
                              heroImageStyles={
                                imageLayout == "vertical"
                                  ? classes.verticalImage
                                  : imageLayout == "horizontal"
                                  ? classes.horizontalImage
                                  : imageLayout == "square"
                                  ? classes.squareImage
                                  : classes.verticalImage
                              }
                              aspectRatio={IMAGE_ASPECT_RATIO["heroImage"]}
                              imageSkeletonBackgroundColor="#f9f7f4"
                              imageWrapperStyles={{
                                backgroundColor: "#f9f7f4"
                              }}
                              heroContainerStyles={classNames([
                                classes.heroContainer,
                                isSwatchPanelDialogOpen
                                  ? classes.setHeight
                                  : null
                              ])}
                              setIsHeroImageUrlFailed={setIsHeroImageUrlFailed}
                              failedImageUrl={
                                isHeroImageUrlFailed
                                  ? (images || [])[0]?.imageUrl
                                  : ""
                              }
                            >
                              {image?.video !== "" && (
                                <div
                                  className={classes.playIconStyle}
                                  onClick={() => {
                                    if (image?.video) {
                                      setVideo(
                                        image?.video.includes(".com")
                                          ? image?.video // scene 7 url
                                          : image?.video?.split("&")[0] // youtube id
                                      );
                                    }
                                  }}
                                >
                                  <span className={classes.playText}>
                                    {pageContent?.WATCH_THE_FILM}
                                  </span>
                                  <PlayIconV2 />
                                </div>
                              )}
                            </RHImage>
                          );
                        })}
                    </Slider>
                  </>
                )}
              </>
            </div>
          </Hidden>
          {!isColorizable ? (
            <div className={classes.colorPreviewUnavailiable}>
              <Typography
                className={typographyStyles.rhBaseBody1}
                style={{
                  color: theme.palette.common.white,
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {pageContent?.COLOR_PREVIEW_AVAILABLE_SOON}
              </Typography>
            </div>
          ) : null}
        </div>

        {(!!video || !!filmUrl) && !processEnvServer && (
          <IframeDialog
            parentBaseId={baseId}
            key={`${baseId}-iframe-dialog`}
            src={
              genratedFilmUrl.includes(".com")
                ? undefined
                : `https://www.youtube.com/embed/${genratedFilmUrl}?modestbranding=1&rel=0&showinfo=0&autoplay=1&controls=0`
            }
            srcDoc={
              genratedFilmUrl.includes(".com")
                ? `<div style="
                width: 100%;
                height: calc(100vh - 56px);
                display: flex;
                align-items: center;
                justify-content: center;
            "><video autoplay name="media" loop style="
            object-fit: scale-down;
            width: inherit;
        "><source src="${video}" type="video/mp4"></video></div>`
                : undefined
            }
            // src={genratedFilmUrl}
            open={!!video || !!filmUrl}
            onClose={() => {
              setVideo("");
              if (setFilmUrl) {
                setFilmUrl(null);
              }
            }}
          />
        )}
        {!isSwatchPanelDialogOpen && (
          <ZoomViewerDialog
            parentBaseId={baseId}
            key={`${baseId}-zoomViewerDialog`}
            images={images}
            index={activeIndex}
            selectedSwatchImageUrl={selectedSwatchImageUrl}
            open={isZoomViewerDialogOpen}
            onClose={() => setIsZoomViewerDialogOpen(false)}
            objectFit={objectFit}
            imageCaptionText={imageCaptionText}
          />
        )}
      </Grid>
    </Grid>
  );
};

HeroImageV2.defaultProps = {};

export default memoize(HeroImageV2);
