import React, { FC } from "react";
import { Grid, Button } from "utils/material-ui-core";
import { AddAllItemToCart } from "page-product";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import { usePageContent } from "customProviders/LocationProvider";
import { MultiSkuComponentWithRestrictions } from "components/RelatedProductCard/types";
import { addOnDataType } from "@RHCommerceDev/component-product-addon/types";
const env = useEnv();
const RHRFlag = yn(env.FEATURE_RHR);
export interface AddAllProductActionsProps {
  addAllItemToCart?: Array<AddAllItemToCart>;
  isAlignCenter?: boolean;
  loading?: boolean;
  isDisabled?: boolean;
  isExpiredPage?: boolean;
  handleAddAllItemsToCart?: () => void;
  multiSkuComponentsData?: Array<MultiSkuComponentWithRestrictions>;
  productAddonItems?: Array<addOnDataType>;
  productAddons?: Array<addOnDataType>;
  allSelectedAddOnsConfigured?: boolean;
}
export const AddAllProductActions: FC<AddAllProductActionsProps> = ({
  addAllItemToCart,
  isAlignCenter,
  loading,
  handleAddAllItemsToCart,
  isExpiredPage,
  multiSkuComponentsData
}) => {
  const { pageContent } = usePageContent();

  return (
    <>
      <Grid
        id={"component-add-all-product-actions"}
        container
        alignItems="stretch"
        spacing={2}
        className={`p-3 xs:p-10`}
      >
        <Grid item xs={12} className="flex justify-end">
          <Grid
            item
            xs={12}
            sm={isAlignCenter ? 12 : 6}
            md={isAlignCenter ? 12 : 3}
            className="text-center"
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className={"{{ RHRFlag ? 'font-[400]' : 'font-[500]' }} w-64"}
              disabled={loading || !addAllItemToCart?.length}
              onClick={handleAddAllItemsToCart}
            >
              {isExpiredPage
                ? pageContent?.ADD_ALL_ITEM_TO_CART
                : pageContent?.ADD_ALL_TO_CART}
            </Button>
          </Grid>
        </Grid>
        {!isExpiredPage && (
          <Grid item xs={12} className="flex justify-end">
            <Grid
              item
              xs={12}
              sm={isAlignCenter ? 12 : 6}
              md={isAlignCenter ? 12 : 3}
              className="text-center"
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={"{{ RHRFlag ? 'font-[400]' : 'font-[500]' }} w-64"}
              >
                {pageContent?.ADD_ALL_TO_PROJECT}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
AddAllProductActions.defaultProps = {};
export default AddAllProductActions;
