import { CustomProductMapper } from "./index";

/** This is all about fixing PE-1721 */
export function customConfigHelper(
  customConfig: CustomProductMapper | SkuCustomizationType
): CustomProductMapper | SkuCustomizationType {
  if (
    ((customConfig as SkuCustomizationType)?.controlType || "").includes(
      "Continuous Loop"
    )
  ) {
    const { controlLength, ...nextCustomConfig } = customConfig;
    return nextCustomConfig;
  } else {
    return customConfig;
  }
}
