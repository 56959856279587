import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Theme,
  useMediaQuery
} from "@material-ui/core";
import { ToggleButtonGroup } from "@mui/joy";
import memoize from "utils/memoize";
import { processEnvServer } from "hooks/useSsrHooks";
import OptionSelector from "./OptionSelector";
import {
  BREAKPOINT_XS,
  BREAKPOINT_SM,
  BREAKPOINT_MD,
  BREAKPOINT_LG,
  BREAKPOINT_XL
} from "utils/constants";

const useStyles = makeStyles((theme: Theme) => ({
  selectedButton: {
    borderRadius: "0px !important",
    border: "0.5px solid !important",
    borderColor: "#000 !important",
    background: "var(--ReimagineWarm-RH-White, #FFF)"
  },
  unselectedButton: {
    borderRadius: "0px !important",
    borderColor: "#F1F0ED !important",
    background: "#F1F0ED !important"
  },
  optionWrapper: {
    display: "flex",

    [theme.breakpoints.up(BREAKPOINT_XS)]: {
      minWidth: "347px !important",
      maxWidth: "347px !important"
    },
    [theme.breakpoints.up(BREAKPOINT_SM)]: {
      minWidth: "704px !important",
      maxWidth: "704px !important"
    },
    [theme.breakpoints.up(BREAKPOINT_MD)]: {
      minWidth: "319px !important",
      maxWidth: "319px !important"
    },
    [theme.breakpoints.up(BREAKPOINT_LG)]: {
      minWidth: "347px !important",
      maxWidth: "347px !important"
    },
    [theme.breakpoints.up(BREAKPOINT_XL)]: {
      minWidth: "371px !important",
      maxWidth: "371px !important"
    },

    justifyContent: "space-between",
    "& [id*='configurator_option_value']": {
      color: "var(--ReimagineWarm-RH-Black, #000)",
      textTransform: "none",
      textAlign: "center",
      fontKerning: "none",
      fontFeatureSettings: "'liga' off",
      fontFamily: "RHC-Thin",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "100%",
      letterSpacing: "0.26px",
      minWidth: "0",
      flexGrow: "1"
    },
    "& > #button-group": {
      display: "flex",
      gap: "4px",
      maxWidth: "77.19%",
      width: "100%",
      flexWrap: "wrap"
    }
  },
  optionWrapperAddOn: {
    display: "flex",
    minWidth: "unset",
    maxWidth: "unset",
    justifyContent: "space-between",
    "& [id*='configurator_option_value']": {
      color: "var(--ReimagineWarm-RH-Black, #000)",
      textTransform: "none",
      textAlign: "center",
      fontKerning: "none",
      fontFeatureSettings: "'liga' off",
      fontFamily: "RHC-Thin",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "100%",
      letterSpacing: "0.26px",
      minWidth: "0",
      flexGrow: "1"
    },
    "& > #button-group": {
      display: "flex",
      gap: "4px",
      maxWidth: "77.19%",
      width: "100%",
      flexWrap: "wrap"
    }
  },
  optionKey: {
    color: "var(--Reimagine-Black, #000)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "RHC-Thin",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "110%",
    letterSpacing: "0.52px",
    height: "100%"
    // maxWidth: "22.81%",
    // minWidth: "22.81%"
  },
  unavailableButton: {
    textDecoration: "line-through !important",
    color: "#898886 !important"
  },
  customTooltip: {
    padding: "8px 10px",
    color: "var(--reimagine-warm-rh-white, #FFF)",
    fontSize: "11px",
    fontStyle: "normal",
    lineHeight: "110%",
    letterSpacing: "0.44px",
    zIndex: 1,
    background: "black"
  },
  arrow: {
    color: "black"
  }
}));

const OptionsItems = ({
  Option,
  checked,
  handleSelection,
  isAddOnOption = false,
  isAddOnPanelOption = false
}) => {
  const classes = useStyles();
  const mdOnly = useMediaQuery<Theme>(theme => theme.breakpoints.only("md"));
  const smOnly = useMediaQuery<Theme>(theme => theme.breakpoints.only("sm"));
  const xsOnly = useMediaQuery<Theme>(theme => theme.breakpoints.only("xs"));
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = option => {
    handleSelection(option);
    setSelectedOption(option?.id);
  };

  useEffect(() => {
    setSelectedOption(null);
    try {
      Option?.options.forEach((opt, index) => {
        if (opt?.status === "selected") {
          setSelectedOption(opt.id);
          throw new Error("Break the loop");
        }
      });
    } catch (error) {
      if (error.message !== "Break the loop") throw error;
    }
  }, [Option]);

  return (
    <>
      <Grid
        container
        item
        alignItems="flex-start"
        alignContent="flex-start"
        justify="space-between"
        className={
          isAddOnOption && isAddOnPanelOption && smOnly
            ? classes.optionWrapperAddOn
            : classes.optionWrapper
        }
        style={{
          justifyContent: isAddOnOption ? "flex-start" : "space-between",
          marginTop: isAddOnOption ? "16px" : "0px"
        }}
        id="options-wrapper"
        data-testid="options-wrapper"
      >
        <Grid
          container
          item
          alignItems="center"
          className={classes.optionKey}
          style={{ width: isAddOnOption ? "70px" : "97px", marginRight: "8px" }}
          id="option-key"
          data-testid="option-key"
        >
          <Grid
            item
            id="option-name-container"
            data-testid="option-name-container"
          >
            <Typography
              style={{
                alignSelf: "center",
                textTransform: "uppercase",
                fontFamily: "RHSans-Light",
                lineHeight: "12.1px",
                letterSpacing: "0.44px"
              }}
              id="options-name"
              data-testid="options-name"
            >
              {Option?.name || Option?.type}
            </Typography>
          </Grid>
        </Grid>
        {!processEnvServer && (
          <ToggleButtonGroup
            spacing={2}
            variant="outlined"
            id="button-group"
            style={{
              width: isAddOnOption
                ? mdOnly
                  ? "calc(100% - 78px)"
                  : "calc(100% - 70px)"
                : "calc(100% - 105px)"
            }}
          >
            {Option?.options.map(opt => (
              <OptionSelector
                selectedOption={selectedOption}
                opt={opt}
                handleOptionSelect={handleOptionSelect}
              />
            ))}
          </ToggleButtonGroup>
        )}
      </Grid>
    </>
  );
};

export default memoize(OptionsItems);
