import template from "lodash/template";

export const getMemberSavingsText = (
  pageContent?: any,
  percentage?: number,
  min?: number,
  max?: number
) => {
  const saveUpToSelectSale =
    pageContent?.SAVE_UP_TO_SELECT_SALE ||
    "SAVE <%= percent %>% ON SELECT SALE ITEMS";
  const saveRangeSelectSale =
    pageContent?.SAVE_RANGE_SELECT_SALE ||
    "SAVE <%= min %>% - <%= max %>% ON SELECT SALE ITEMS";
  const saveUpToSelect =
    pageContent?.SAVE_UP_TO_SELECT || "SAVE <%= percent %>% ON SELECT ITEMS";
  const saveRangeSelect =
    pageContent?.SAVE_RANGE_SELECT ||
    "SAVE <%= min %>% - <%= max %>% ON SELECT ITEMS";
  const saveUpToAll =
    pageContent?.SAVE_UP_TO_ALL || "SAVE <%= percent %>% ON ALL ITEMS";
  const saveRangeAll =
    pageContent?.SAVE_RANGE_ALL ||
    "SAVE <%= min %>% - <%= max %>% ON ALL ITEMS";

  if (!percentage || percentage === 0 || isNaN(percentage) || !min || !max)
    return template(pageContent?.MEMBER_SAVINGS_REGULAR)({ percent: 25 });

  if (min === max || (min && !max) || (!min && max)) {
    if (percentage < 20) {
      return template(saveUpToSelectSale)({ percent: min || max });
    }
    if (percentage < 100) {
      return template(saveUpToSelect)({ percent: min || max });
    }
    return template(saveUpToAll)({ percent: min || max });
  }

  if (percentage < 20) {
    return template(saveRangeSelectSale)({ min, max });
  }
  if (percentage < 100) {
    return template(saveRangeSelect)({ min, max });
  }
  return template(saveRangeAll)({ min, max });
};
