import classNames from "classnames";
import { usePageContent } from "customProviders/LocationProvider";
import useButtonStyles from "hooks/useButtonStyles";
import React, { FC } from "react";
import {
  CLOSE_HAMBURGER_MENU,
  OPEN_HAMBURGER_MENU
} from "resources/hamburger-nav.json";

export interface AnimatedHamburgerIconProps {
  isOpen?: boolean;
  color?: string;
  onClick?: (event) => void;
  style?: { [key: string]: string | number };
}

export const AnimatedHamburgerIcon: FC<AnimatedHamburgerIconProps> = ({
  isOpen,
  color,
  onClick,
  style
}) => {
  const { pageContent } = usePageContent();
  const buttonStyles = useButtonStyles({
    keys: ["unstyledBtn"]
  });
  const CLOSE_HAMBURGER_LABEL =
    pageContent?.hamburgerNav?.CLOSE_HAMBURGER_MENU ?? CLOSE_HAMBURGER_MENU;
  const OPEN_HAMBURGER_LABEL =
    pageContent?.hamburgerNav?.OPEN_HAMBURGER_MENU ?? OPEN_HAMBURGER_MENU;

  return (
    <div style={style}>
      <button
        id="hamburgerIcon"
        className={classNames(
          buttonStyles.unstyledBtn,
          isOpen ? "open" : "menuhamburger"
        )}
        onClick={onClick}
        role="button"
        aria-label={isOpen ? CLOSE_HAMBURGER_LABEL : OPEN_HAMBURGER_LABEL}
        aria-expanded={isOpen}
        tabIndex={0}
      >
        <span style={{ background: color, height: "1px" }} />
        <span style={{ background: color, height: "1px" }} />
        <span style={{ background: color, height: "1px" }} />
        {/* <span style={{ background: color, height: "0.75px", top: "20px" }} /> */}
      </button>
    </div>
  );
};

AnimatedHamburgerIcon.defaultProps = {
  style: { padding: "17px 16px 17px 10px" }
};

export default AnimatedHamburgerIcon;
