import React, { useState, useRef, useEffect, FC } from "react";
import { CardMedia } from "utils/material-ui-core";
import memoize from "utils/memoize";

interface VideoAutoPlayerProps {
  index: number;
  image: string;
  activeIndex: number | undefined;
  parentBaseId?: string;
}

const VideoAutoPlayer: FC<VideoAutoPlayerProps> = ({
  index,
  image,
  activeIndex,
  parentBaseId
}) => {
  const baseId = `${parentBaseId}-videoAutoPlayer`;

  const [currentTime, setCurrentTime] = useState<number>(0);
  const filmRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!filmRef?.current) return;
    if (index === activeIndex) {
      if (filmRef?.current) {
        filmRef.current.currentTime = currentTime;
        filmRef?.current?.play();
      }
    } else {
      if (filmRef.current) {
        setCurrentTime(prevTime => filmRef?.current?.currentTime || prevTime);
        filmRef?.current?.pause();
      }
    }
    const handleTimeUpdate = () => {
      if (!filmRef?.current) return;
      setCurrentTime(prevTime => filmRef?.current?.currentTime || prevTime);
    };
    filmRef?.current?.addEventListener("timeupdate", handleTimeUpdate);
    return () => {
      if (!filmRef.current) return;
      filmRef?.current?.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [activeIndex, filmRef]);

  return (
    <CardMedia
      data-testid="rh-video-test"
      ref={filmRef}
      key={`productImage-${index || 0}`}
      component="video"
      image={image || ""}
      autoPlay
      muted
      id={`${baseId} hero-container-video`}
      loop
    />
  );
};

export default memoize(VideoAutoPlayer);
