import React, {
  FC,
  useState,
  useEffect,
  RefObject,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction
} from "react";
import {
  Card,
  useTheme,
  useMediaQuery,
  makeStyles,
  createStyles,
  Theme
} from "utils/material-ui-core";
import RHLink from "component-rh-link";
import { useCountry } from "hooks/useCountry";
import useAppData from "hooks/useAppData";
import { useEnv } from "hooks/useEnv";
import { SaleContextFilter } from "graphql-client/queries/app";
import buildPath from "utils/buildPath";
import Landscape from "./Landscape";
import LandscapeFull from "./LandscapeFull";
import memoize from "utils/memoize";
import { useHistory, useLocation } from "react-router";
import { useCookies } from "hooks/useCookies";
import useParams, { useNewURLStructureParams } from "hooks/useParams";
import { isSSRToggledCheck } from "utils/ssrHelpers";
import { useLocalization } from "hooks/useLocalization";
import useLocale from "hooks/useLocale/useLocale";
import { useLazyQuery } from "@apollo/client";
import { queryCategoryImage } from "graphql-client/queries/category-image";
import yn from "yn";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkFocus: {
      "&.Mui-focusVisible": {
        border: "1px solid rgb(0, 95, 204)"
      }
    }
  })
);

export interface CollectionCardProps {
  data: CollectionCategory;
  readonly?: boolean;
  linkUrl?: string;
  saleContextFilter?: SaleContextFilter;
  col?: 4 | 6 | 12;
  hideDetails?: boolean;
  saveSelectedCategory?: (cgId: string) => void;
  selectedProductRef?: RefObject<HTMLDivElement> | null;
  handleRemoveCookie?: (remove: string) => void;
  handleOnSwatchClick?: (e: any, index: number, swatch: Swatch) => void;
  categorySwatchImage?: string | null;
  swatchImageId?: string | null;
  categoryImageLoading?: boolean;
  selectedProductId?: string;
  colorizable?: boolean;
  isSwatchSelected?: string[];
  setIsSwatchSelected?: Dispatch<SetStateAction<string[]>>;
}

export const CollectionCard: FC<CollectionCardProps> = memoize(
  ({
    data,
    readonly,
    saleContextFilter,
    col,
    hideDetails,
    saveSelectedCategory,
    selectedProductRef,
    handleRemoveCookie,
    categorySwatchImage: categorySwatchImageProp,
    categoryImageLoading: categoryImageLoadingProp,
    isSwatchSelected,
    swatchImageId,
    setIsSwatchSelected
  }) => {
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const xs = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
    const theme = useTheme();
    const env = useEnv();
    const classes = useStyles();
    const location = useLocation();
    const locale = useLocale();
    const prefix = useLocalization();
    const history = useHistory();
    const newURLStructureParams = useNewURLStructureParams();
    const userCountry = useCountry();
    const [, , removeCookie] = useCookies(["cgId", "pgId"]);
    const [isScroll, setIsScroll] = useState<Boolean>(false);
    const isSSRToggled = isSSRToggledCheck();
    const isCGColorizationEnabled = yn(env.FEATURE_CG_COLORIZATION);
    const isColorizable = data?.colorizable;

    const [updatedCategorySwatchImageProp, setUpdatedCategorySwatchImageProp] =
      useState();

    useEffect(() => {
      setUpdatedCategorySwatchImageProp(categorySwatchImageProp);
    }, [categorySwatchImageProp]);
    // useEffect(() => {
    //   if (selectedProductRef && !isSSRToggled) {
    //     setTimeout(() => {
    //       selectedProductRef?.current?.scrollIntoView({
    //         behavior: "auto",
    //         block: "center"
    //       });
    //       setIsScroll(true);
    //     }, 70);
    //   }
    // });

    const [
      fetchCategoryImage,
      {
        data: { categoryImage: categorySwatchImage } = {} as Query,
        loading: categoryImageLoading
      }
    ] = useLazyQuery<Query>(queryCategoryImage);

    const onSwatchClickHandler = useCallback(
      (index, swatch) => {
        setUpdatedCategorySwatchImageProp(null);
        setIsSwatchSelected(prevArray => [...prevArray, data?.id]);
        if (isCGColorizationEnabled && isColorizable) {
          fetchCategoryImage({
            variables: {
              categoryId: data?.id,
              countryCode: userCountry,
              swatchIds: swatch?.id,
              locale
            }
          });
        }
      },
      [
        fetchCategoryImage,
        isColorizable,
        isCGColorizationEnabled,
        data?.id,
        userCountry,
        locale
      ]
    );

    useEffect(() => {
      if (selectedProductRef) {
        setTimeout(() => {
          selectedProductRef?.current?.scrollIntoView({
            behavior: "auto",
            block: "center"
          });
          setIsScroll(true);
        }, 70);
      }
    }, [selectedProductRef]);

    const toHref = useMemo(() => {
      if (readonly) {
        return "";
      }

      const buildPathOption = {
        ...(!!saleContextFilter && { sale: (!!saleContextFilter).toString() }),
        // hasShellProducts: (data?.hasShellProducts || false)?.toString(),
        fromCG: "true"
      };
      const pageTitle = data?.displayName?.toLowerCase().replace(/\s+/g, "-");
      if (yn(env.FEATURE_URL_CHANGE)) {
        return buildPath(
          `/${newURLStructureParams?.category}/pg/${pageTitle}`,
          {
            ...buildPathOption
          }
        );
      }

      // call buildPath with pgterm if feature flag is true
      if (env.FEATURE_AUTOMATE_SEARCH_PGS) {
        return buildPath(
          `/catalog/category/products.jsp/${data.id}/category:${data.id}`,
          {
            ...buildPathOption
          }
        );
      } else {
        return buildPath(`/catalog/category/products.jsp/${data.id}`, {
          ...buildPathOption
        });
      }
    }, [
      data?.hasShellProducts,
      data.id,
      data.parentCatId,
      env.FEATURE_AUTOMATE_SEARCH_PGS,
      readonly,
      saleContextFilter
    ]);

    useEffect(() => {
      if (isScroll && history.action === "POP") {
        handleRemoveCookie(isScroll);
      }
      // if (isScroll && history.action === "POP" && !isSSRToggled) {
      //   /* this works fine when page donot reload on switching to other page */
      //   handleRemoveCookie(isScroll);
      // }
      // if (isScroll && history.action === "POP" && isSSRToggled) {
      //   /* below event listener is add for SSR= true, as when clicked on item in CG and jump to PG
      //     the page tends to reload as mentioned in ticket SHOP-2535
      //     till the route happens the above function deletes the cookie so on back button there is no cookie to scroll to the item.
      //     so unless an until the scroll occurs and page is loaded then delete the cookie.  */
      //   window.addEventListener("load", event => {
      //     setTimeout(() => {
      //       removeCookie("cgId", {
      //         path: "/"
      //       });
      //       removeCookie("pgId", {
      //         path: "/"
      //       });
      //     }, 50);
      //   });
      // }
    }, [isScroll]);

    const saveCategory = useCallback(() => {
      saveSelectedCategory?.(data?.id);
    }, []);

    const linkUrl =
      data?.useBannerLink && data?.bannerLink
        ? `${prefix}${data?.bannerLink}`
        : toHref;

    const imageAlternativeText = ` ${data?.bannerTitle} ${data?.bannerSubTitle} ${data?.bannerSubtitle3}`;

    return (
      <Card
        id="component-collection-card"
        ref={selectedProductRef}
        className={classNames([
          env.FEATURE_RHR ? "relative md:py-0 lg:py-0 xl:py-0" : "",
          `${
            !env.FEATURE_RHR ? "max-w-[1150px]" : "max-w-none"
          } !w-full m-auto relative select-none`
        ])}
        style={{
          backgroundColor: env.FEATURE_RHR
            ? env.FEATURE_BG_GREY
              ? theme?.palette?.background?.default
              : "white"
            : theme.palette.secondary.light
        }}
        elevation={0}
        square
        onClick={saveCategory}
      >
        <RHLink
          id={`component-collectionCard_link-${data.id}`}
          data-testid={`collectionCardLink-${data.id}`}
          underline="none"
          to={linkUrl}
          className={classes.linkFocus}
          tabIndex={0}
          aria-label={imageAlternativeText}
        >
          {mdUp &&
          !env.FEATURE_RHR &&
          data.cgBannerTemplate?.includes("banner-image-landscape-full.jsp") ? (
            <LandscapeFull
              data={data}
              linkUrl={linkUrl}
              handleOnSwatchClick={onSwatchClickHandler}
              categorySwatchImage={
                categorySwatchImageProp || categorySwatchImage?.imageurl
              }
              categoryImageLoading={
                categoryImageLoadingProp || categoryImageLoading
              }
            />
          ) : (
            <Landscape
              hideDetails={hideDetails}
              linkUrl={linkUrl}
              data={data}
              col={col}
              handleOnSwatchClick={onSwatchClickHandler}
              categorySwatchImage={
                updatedCategorySwatchImageProp || categorySwatchImage?.imageurl
              }
              swatchImageId={swatchImageId || categorySwatchImage?.category}
              categoryImageLoading={
                categoryImageLoadingProp || categoryImageLoading
              }
              isSwatchSelected={isSwatchSelected}
            />
          )}
        </RHLink>
      </Card>
    );
  }
);

CollectionCard.defaultProps = {
  readonly: false
};

export default (props: Omit<CollectionCardProps, "saleContextFilter">) => {
  const { app } = useAppData();
  const {
    data,
    readonly,
    col,
    hideDetails,
    saveSelectedCategory,
    selectedProductRef,
    selectedProductId,
    handleRemoveCookie,
    isSwatchSelected,
    setIsSwatchSelected
  } = props;
  const params = useParams({ sale: "false" });
  const saleContextParam = useMemo(
    () => (params.sale === "true" ? "sale" : null) as SaleContextFilter,
    [params.sale]
  );
  return (
    <CollectionCard
      data={data}
      readonly={readonly}
      col={col}
      hideDetails={hideDetails}
      saveSelectedCategory={saveSelectedCategory}
      selectedProductRef={selectedProductRef}
      selectedProductId={selectedProductId}
      handleRemoveCookie={handleRemoveCookie}
      saleContextFilter={saleContextParam}
      isSwatchSelected={isSwatchSelected}
      setIsSwatchSelected={setIsSwatchSelected}
    />
  );
};
