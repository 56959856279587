import React, { useEffect } from "react";
import {
  Typography,
  Theme,
  useMediaQuery,
  makeStyles,
  createStyles
} from "utils/material-ui-core";
import RHImage from "component-rh-image";

import memoize from "utils/memoize";
import yn from "yn";
import { useEnv } from "hooks/useEnv";
import { processEnvServer } from "hooks/useSsrHooks";

const useStyle = ({
  centered,
  col,
  textBelowSwatches,
  isFeatureCgColorization,
  xsDown,
  smDown
}) =>
  makeStyles((theme: Theme) => {
    return createStyles({
      dynamicSwatchImage: {
        minWidth: "100%",
        minHeight: "100%",
        objectFit: "cover",
        overflow: "hidden"
      },
      dynamicSwatchImageWrapper: {
        height: "85px"
      },
      dynamicSwatchMoreBox: {
        display: "flex",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "#24242488",
        justifyContent: "center",
        alignItems: "center"
      },
      dynamicSwatchMoreText: {
        color: "#FFF",
        fontFamily: "RHSans-Light",
        fontSize: "11px",
        lineHeight: "150%",
        letterSpacing: "0.165px"
      },
      dynamicSwatchImageText: {
        width: "100%",
        alignSelf: textBelowSwatches ? "flex-start" : "flex-end",
        textAlign: centered ? "center" : "left",
        fontFamily: "RHSans-Roman",
        lineHeight: "110% !important",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        color: "#201f1f",
        [theme.breakpoints.up("xs")]: {
          fontSize: "10px"
        },
        [theme.breakpoints.up("sm")]: {
          fontSize: "8px"
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "10px"
        }
      }
    });
  });
const DynamicSwatches = memoize(
  ({
    pageContent,
    dynamicSwatches,
    textBelowSwatches = false,
    handleOnSwatchClick,
    justify = "flex-start",
    col,
    linkUrl,
    centered,
    setSelectedSwatch,
    selectedSwatch,
    saleMessageEnabled
  }) => {
    const env = useEnv();
    const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
    const isFeatureCgColorization = yn(env.FEATURE_CG_COLORIZATION);

    if (!dynamicSwatches) return null;
    const classes = useStyle({
      centered,
      textBelowSwatches,
      isFeatureCgColorization,
      col,
      xsDown,
      smDown
    })();
    // const { totalNumSwatchIds, top5SwatchIds } = dynamicSwatches;
    // const showMore = totalNumSwatchIds > 6;
    const { top5SwatchIds } = dynamicSwatches;
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const lgUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("lg"));

    useEffect(() => {
      const { displaySwatch, top5SwatchIds } = dynamicSwatches;
      const swatchToDisplayIndex =
        top5SwatchIds.length > 0
          ? top5SwatchIds.findIndex(swatch => swatch?.id === displaySwatch?.id)
          : -1;

      if (swatchToDisplayIndex !== -1)
        setSelectedSwatch(top5SwatchIds[swatchToDisplayIndex]?.id);
    }, [dynamicSwatches, top5SwatchIds, setSelectedSwatch]);

    const handleClick = (e, idx, swatch, ...args) => {
      if (isFeatureCgColorization) {
        if (handleOnSwatchClick) {
          handleOnSwatchClick(idx, swatch, ...args);
        }
        setSelectedSwatch(swatch?.id);
        if (e) {
          e.preventDefault();
          e.stopPropagation();
        }
      }
    };

    return (
      <div
        className={`flex flex-1 gap-[4px] pt-[3px] w-full ${
          !processEnvServer && "items-baseline"
        } 
        ${textBelowSwatches ? "" : "md:pt-[6px]"}  ${
          col === 12 ? "md:mb-[0px]" : ""
        } ${!textBelowSwatches && "md:self-end md:mt-auto"}
         place-self-end`}
        style={{ marginLeft: processEnvServer ? "-4px" : "" }}
        data-testid={"collectionCardDetails-DynamicSwatches"}
      >
        {top5SwatchIds?.map((swatch, idx) => {
          if (!swatch.imageUrl) return null;
          const swatchText = (
            <div
              className={`!relative !flex !bottom-0 ${
                textBelowSwatches ? "!justify-start" : "!justify-end"
              } !pb-[3px] !gap-[4px] !pl-[1px] ${
                !textBelowSwatches ? "flex-col-reverse" : "flex-col"
              } `}
            >
              {dynamicSwatches.displaySaleCopy === "true" &&
                saleMessageEnabled &&
                (dynamicSwatches.allSwatchIdsOnSale || swatch.sale === "Y") && (
                  <Typography
                    className={`!w-full ${
                      smDown || xsDown
                        ? "!relative"
                        : col === 12
                        ? "!absolute"
                        : "!relative"
                    } !bottom-0 !left-0 !text-[#CA2022] ${
                      centered ? "!text-center" : "!text-left"
                    }
                  ${
                    textBelowSwatches ? "!self-start" : "!self-end"
                  } !font-primary-rhroman !leading-[120%] !overflow-hidden !text-ellipsis !line-clamp-1 !uppercase xs:!text-[10px] sm:!text-[8px] md:!text-[10px]
                  `}
                  >
                    {pageContent?.SALE_TEXT?.toLowerCase()}
                  </Typography>
                )}
            </div>
          );
          return (
            <div
              data-testid={"collectionCardDetails-DynamicSwatches-ImageGrid"}
              className={` !flex-1 ${
                !processEnvServer ? "!h-[95px]" : ""
              } !flex !box-border ${
                textBelowSwatches ? "!justify-start" : "!justify-end"
              } !flex-col`}
            >
              {!textBelowSwatches && swatchText}
              <div
                className={`relative h-[85px] ${
                  isFeatureCgColorization && textBelowSwatches
                    ? "mb-[2px]"
                    : textBelowSwatches
                    ? "mb-1"
                    : "mb-0"
                }`}
              >
                <a
                  href={isFeatureCgColorization ? "#" : linkUrl}
                  onClick={e => handleClick(e, idx, swatch)}
                  aria-label={swatch?.displayName}
                  data-testid={`swatch-image-anchor-${swatch?.id}`}
                >
                  <RHImage
                    src={swatch?.imageUrl?.replace("$63sq$", "$sw_hvr$")}
                    alt={swatch?.displayName}
                    height="85px"
                    imageWrapperClassName={classes.dynamicSwatchImageWrapper}
                    imageClassName={classes.dynamicSwatchImage}
                  />
                  {/* {showMore && idx === 5 && (
                    <div className={classes.dynamicSwatchMoreBox}>
                      <Typography className={classes.dynamicSwatchMoreText}>
                        {pageContent?.MORE_SWATCHES || "+more"}
                      </Typography>
                    </div>
                  )} */}
                </a>
              </div>
              {isFeatureCgColorization && selectedSwatch === swatch?.id && (
                <div
                  className={`h-[1px] border-[0.5px] border-solid border-[#201f1f] ${
                    mdUp && col === 12 ? "!mt-[2px]" : "!mt-[0px]"
                  } ${
                    lgUp && col === 12 ? "!mb-[0px]" : "!mb-[3px]"
                  } !py-0 !px-[1px] !self-stretch`}
                  data-testid={`colorization-selected-swatch-${swatch?.id}`}
                  style={{
                    backgroundColor: "var(--reimagine-warm-rh-darker)"
                  }}
                />
              )}
              {textBelowSwatches && swatchText}
            </div>
          );
        })}
      </div>
    );
  }
);

export default DynamicSwatches;
