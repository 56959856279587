import { Grid, Theme, makeStyles, useMediaQuery } from "@material-ui/core";
import FinishOption from "@RHCommerceDev/component-configurator-options/FinishOption";
import React, { FC } from "react";
import memoize from "utils/memoize";
import OptionsItems from "./OptionsItems";

const useStyles = makeStyles(theme => ({
  optionsContainer: {
    display: "flex",
    gap: "24px",
    [theme.breakpoints.up("sm")]: {
      display: "grid"
    }
  }
}));

interface ConfiguratorOptionsProps {
  data: Product;
  checked: boolean;
  handleSelection: (option) => void;
  handleFinishSwatchSelection: (swatch) => void;
  availableOptions: ProductAvailableOptionSet[];
  availableSwatch: any;
}
const getFilteredSwatches = swatches =>
  swatches?.filter(swatch => swatch?.status !== "unavailable");

export const ConfiguratorOptions: FC<ConfiguratorOptionsProps> = memoize(
  ({
    data,
    checked,
    handleSelection,
    availableOptions,
    handleFinishSwatchSelection,
    availableSwatch
  }) => {
    const classes = useStyles();
    const mdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
    // const finishOptionData: ProductAvailableOptionSet = data?.productLineItem?.availableOptions.find(
    //     opt => opt?.type === "Finish"
    //   );

    return (
      <>
        <Grid
          container
          className={classes.optionsContainer}
          id="options-items-container"
          data-testid="options-items-container"
        >
          {availableOptions?.map(
            (opt: ProductAvailableOptionSet, index: number) => {
              if (
                ["Fabric", "Color", "Finish", "Leather"].some(el =>
                  opt.type.includes(el)
                )
              )
                return null;
              return (
                <OptionsItems
                  Option={opt}
                  checked={checked}
                  handleSelection={handleSelection}
                />
              );
            }
          )}
        </Grid>
        {availableSwatch?.finishSwatchGroups?.length ? (
          <Grid
            container
            style={{
              gap: "24px",
              justifyContent: mdDown && smUp ? "center" : "flex-start"
            }}
            id="finish-option-container"
            data-testid="finish-option-container"
          >
            <FinishOption
              finishSwatches={availableSwatch?.finishSwatchGroups}
              handleFinishSwatchSelection={handleFinishSwatchSelection}
            />
          </Grid>
        ) : null}
      </>
    );
  }
);
