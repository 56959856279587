import React, { FC, useMemo, useState, useEffect } from "react";
import {
  Typography,
  makeStyles,
  createStyles,
  Theme,
  useMediaQuery,
  styled
} from "utils/material-ui-core";
import useTypographyStyles from "hooks/useTypographyStyles";
import template from "lodash/template";
import classNames from "classnames";
import { useLocalization } from "hooks/useLocalization";
import useRHStyles from "hooks/useRHStyles";
import { useEnv } from "hooks/useEnv";
import { Hidden, processEnvServer } from "hooks/useSsrHooks";
import memoize from "utils/memoize";
import useResponsiveGridSpacing from "hooks/useResponsiveGridSpacing";

import RHLink from "component-rh-link";
import { SaleContextFilter } from "graphql-client/queries/app";
import buildPath from "utils/buildPath";
import { useFetchModel } from "hooks/useFetchModel";
import yn from "yn";
import MemberSavingsText from "./MemberSavingsText";
import DynamicSwatches from "./DynamicSwatches";
import Title from "./Title";
import PriceInfo from "./PriceInfo";
import { useAppId } from "hooks-use-app-id";

const useStyles = ({ col }) =>
  makeStyles((theme: Theme) => {
    return createStyles({
      container: {
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(3),
          marginTop: 0,
          marginBottom: 0
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: theme.spacing(3),
          marginRight: theme.spacing(3),
          flexWrap: "nowrap"
        }
      },
      bannerTitle: {
        wordBreak: "normal",
        display: "inline"
      },

      bannerTitleCG: {
        color: "#000",
        fontFamily: "RHSans-Hairline",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: 75,
        lineHeight: "100%",
        letterSpacing: "-0.64px",
        textTransform: "uppercase"
      },
      CGSubtitle: {
        color: "#000",
        fontFamily: "RHSans-UltraThin",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 75,
        lineHeight: "90%",
        letterSpacing: "0.36px",
        textTransform: "uppercase"
      },
      landscapeFull: {
        color: "white !important",
        textShadow: "1px 1px 30px #666666"
      },
      headerTextColor: {
        color: theme.brandColors.tempHeaderColor
      },
      bodyTextColor: {
        color: theme.brandColors.tempBodyColor
      },
      bannerSubSubTitleCG: {
        color: "#000",
        fontFamily: "RHSans-Thin",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 200,
        lineHeight: "120%",
        letterSpacing: "0.15px",
        textTransform: "uppercase"
      },
      bannerSubSubReduceSpace: {
        marginTop: 0
      },
      reduceFontDetail: {
        [theme.breakpoints.only("md")]: {
          fontSize: theme.fontSizes.h4
        }
      },
      price: {},
      mdDetailsFontSize: {
        [theme.breakpoints.up("md")]: {
          fontSize: `${theme.fontSizes.h3}px !important`
        }
      },
      rhrCgGrid: {
        ".cg-slide &": {
          [theme.breakpoints.up("md")]: {
            marginTop: "0px !important",
            marginBottom: "0px "
          },
          [theme.breakpoints.down("sm")]: {
            marginTop: "0px ",
            marginBottom: "0px",
            paddingTop: "0px"
          }
        },
        ".cg-slide-6 &": {
          [theme.breakpoints.up("lg")]: {
            marginBottom: "0 "
          }
        }
      },
      saleAndMembershipCG: {
        [theme.breakpoints.up("xs")]: {
          marginTop: "12px"
        },
        [theme.breakpoints.up("xl")]: {
          ".cg-slide-12 &": {
            marginTop: "12px"
          }
        }
      },
      defaultMembershipMessage: {
        color: "#000",
        fontFamily: "RHSans-Thin",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: 100,
        lineHeight: "13.2px",
        letterSpacing: "0.165px",
        textTransform: "uppercase"
      },
      saveSelectedItems: {
        color: "var(--reimagine-warm-rh-red, #CA2022)",
        fontSize: "11px",
        [theme.breakpoints.up("xs")]: {
          textTransform: "uppercase"
        },
        [theme.breakpoints.up("sm")]: {
          textTransform: "initial"
        },
        [theme.breakpoints.up("md")]: {
          textTransform: "uppercase"
        },
        fontFamily: "RHSans-Roman",
        lineHeight: "120%" /* 15.6px */,
        letterSpacing: "0.195px"
      },
      withMembershipMessage: {
        color: "var(--reimagine-warm-rh-red, #CA2022)",
        fontSize: "11px",
        [theme.breakpoints.up("xs")]: {
          textTransform: "uppercase"
        },
        [theme.breakpoints.up("sm")]: {
          textTransform: "initial"
        },
        [theme.breakpoints.up("md")]: {
          textTransform: "uppercase"
        },
        fontFamily: "RHSans-Thin",
        lineHeight: "120%" /* 15.6px */,
        letterSpacing: "0.195px",
        paddingBottom: "16px"
      },
      saveOffRegular: {
        color: "var(--reimagine-warm-rh-red, #CA2022)",
        [theme.breakpoints.up("xs")]: {
          fontSize: "11px",
          textTransform: "uppercase"
        },
        [theme.breakpoints.up("sm")]: {
          textTransform: "initial"
        },
        [theme.breakpoints.up("md")]: {
          textTransform: "uppercase"
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: col === 12 ? "13px" : "11px"
        },
        fontFamily: "RHSans-Roman",
        lineHeight: "120%" /* 15.6px */,
        letterSpacing: "0.195px",
        marginBottom: "16px"
      },
      saleText: {
        color: "var(--reimagine-warm-rh-red, #CA2022)",
        [theme.breakpoints.up("xs")]: {
          fontSize: "11px",
          textTransform: "uppercase"
        },
        [theme.breakpoints.up("sm")]: {
          textTransform: "initial"
        },
        [theme.breakpoints.up("md")]: {
          textTransform: "uppercase"
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: col === 12 ? "13px" : "11px"
        },
        fontFamily: "RHSans-Thin",
        lineHeight: "120%" /* 15.6px */,
        letterSpacing: "0.195px"
      },
      gridItem2Centered: {
        [theme.breakpoints.up("md")]: {
          textAlign: col === 4 ? "" : "center"
        },
        "& #bannerTitleCG": {
          fontSize: "40px",
          letterSpacing: "-0.8px"
        },
        "& #bannerSubSubTitleCG": {
          fontSize: "13px",
          letterSpacing: "0.195px"
        }
      }
    });
  });

export interface CollectionCardDetailsProps {
  data: CollectionCategory;
  linkUrl?: string;
  hideDetails?: boolean;
  col?: number;
  previewChild?: React.ReactNode;
  saveSelectedCategory?: (cgId: string) => void;
  smallView?: boolean;
  saleContextFilter?: SaleContextFilter;
  handleOnSwatchClick?: (event: any, index: number, swatch: any) => void;
}

export const CollectionCardDetails: FC<CollectionCardDetailsProps> = ({
  data,
  linkUrl,
  hideDetails = false,
  col,
  saveSelectedCategory,
  smallView,
  handleOnSwatchClick,
  saleContextFilter
}) => {
  const env = useEnv();
  const { pageContent } = useFetchModel("/admin/collections", true, false);
  let [swatchImage, setSwatchImage] = useState("");
  const isFeatureCgColorization = yn(env.FEATURE_CG_COLORIZATION);
  const isCgColorizable = data?.colorizable;
  const isLandscapeFull = data.cgBannerTemplate?.includes(
    "banner-image-landscape-full.jsp"
  );
  const [selectedSwatch, setSelectedSwatch] = useState<string | null>(null);
  const typographyStyles = useTypographyStyles({
    keys: [
      "skuListSale",
      "collectionAccordion",
      "cardPriceRangeBold",
      "memberPriceWhite",
      "rhBaseH1",
      "rhBaseH2",
      "rhBaseH3",
      "rhBaseBody0",
      "rhBaseBody1",
      "rhBaseBody2",
      "rhBaseBody4",
      "rhBaseH5"
    ]
  });
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
  const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));

  const xlOnly = useMediaQuery<Theme>(theme => theme.breakpoints.only("xl"));
  const lgOnly = useMediaQuery<Theme>(theme => theme.breakpoints.only("lg"));
  const mdOnly = useMediaQuery<Theme>(theme => theme.breakpoints.only("md"));
  const smOnly = useMediaQuery<Theme>(theme => theme.breakpoints.only("sm"));
  const xsOnly = useMediaQuery<Theme>(theme => theme.breakpoints.only("xs"));

  const xlUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("xl"));
  const prefix = useLocalization();
  const rhStyles = useRHStyles({
    keys: ["collectionGalleryHeader"]
  });

  const isFeatureCGCenetered = yn(env.FEATURE_CG_CENTERED);
  const rhrCols = col === 12 ? 4 : 12;

  const showSwatchTextBelow = !(col === 12 && mdUp);
  const styleConfig = {
    col,
    centered: isFeatureCGCenetered,
    textBelowSwatches: showSwatchTextBelow,
    isFeatureCgColorization
  };

  const classes = useStyles(styleConfig)();

  useEffect(() => {
    const imgUrl = data?.bannerSwatchImage?.split("");
    const findStringIndex = imgUrl?.indexOf("?");
    const views =
      (col === 12 && smOnly && "-tablet") ||
      (col === 12 && smUp && "-desktop") ||
      (col === 12 && smDown && "-mobile") ||
      (col === 6 && "-tablet") ||
      (col === 4 && xlUp && "-desktop") ||
      (col === 4 && smUp && "-mobile") ||
      (col === 4 && "-tablet");

    findStringIndex === -1
      ? imgUrl?.push(views || "")
      : imgUrl?.splice(findStringIndex!, 0, views || "");
    const result = imgUrl?.join("");

    const finalResult =
      findStringIndex === -1
        ? `${result}?`
        : result?.substring(0, result?.indexOf("wid"));
    if (finalResult) {
      const resultWithDynamicWidth =
        (col === 4 && `${finalResult}wid=${600}`) ||
        (xlUp && `${finalResult}wid=${900}`) ||
        (xlOnly && `${finalResult}wid=${900}`) ||
        (lgOnly && `${finalResult}wid=${900}`) ||
        (mdOnly && `${finalResult}wid=${900}`) ||
        (smOnly && `${finalResult}wid=${col === 12 ? 900 : 600}`) ||
        (xsOnly && `${finalResult}wid=${600}`);

      setSwatchImage(`${resultWithDynamicWidth}&fmt=png-alpha`);
    }
  }, [
    smUp,
    xlUp,
    lgOnly,
    mdOnly,
    smOnly,
    xsOnly,
    mdUp,
    smDown,
    data?.bannerSwatchImage,
    col
  ]);
  if (processEnvServer) {
    const imgUrl = data?.bannerSwatchImage?.split("");
    const findStringIndex = imgUrl?.indexOf("?");
    const views =
      (col === 12 && smOnly && "-tablet") ||
      (col === 12 && smUp && "-desktop") ||
      (col === 12 && smDown && "-tablet") ||
      (col === 6 && "-tablet") ||
      (col === 4 && "-tablet");

    findStringIndex === -1
      ? imgUrl?.push(views || "")
      : imgUrl?.splice(findStringIndex!, 0, views || "");
    const result = imgUrl?.join("");

    const finalResult =
      findStringIndex === -1
        ? `${result}?`
        : result?.substring(0, result?.indexOf("wid"));
    if (finalResult) {
      const resultWithDynamicWidth =
        (col === 4 && `${finalResult}wid=${600}`) ||
        (xlUp && `${finalResult}wid=${900}`) ||
        (xlOnly && `${finalResult}wid=${900}`) ||
        (lgOnly && `${finalResult}wid=${900}`) ||
        (mdOnly && `${finalResult}wid=${900}`) ||
        (smOnly && `${finalResult}wid=${col === 12 ? 900 : 600}`) ||
        (xsOnly && `${finalResult}wid=${600}`);

      swatchImage = `${resultWithDynamicWidth}&fmt=png-alpha`;
    }
  }

  const isModern = data?.cgBannerTemplate?.includes("bannerTheme=mo");
  const mdColumns = isModern || isLandscapeFull ? 12 : 4;
  const spacing = useResponsiveGridSpacing();

  const allSwatchIdsOnSale = data?.dynamicSwatches?.allSwatchIdsOnSale === true;

  const showViewSelectItemOnSale =
    data?.onCategoryFinishSale !== -1 ? data?.onCategoryFinishSale === 1 : true;

  const displaySaleCopy = data?.dynamicSwatches?.displaySaleCopy;
  const priceInfo = data?.priceInfo;
  const listPrice = priceInfo?.listPrice;
  const memberPrice = priceInfo?.memberPrice;
  const isSaleSku20Plus =
    Number(data?.saleMemberInfo?.pgSaleStats?.percentSaleSkus) >= 20;
  const isSaleMessageEnabled = data?.saleMessageEnabled;
  const { isConcierge } = useAppId();
  const dynamicSwatchsUI = (
    <DynamicSwatches
      pageContent={pageContent}
      dynamicSwatches={data?.dynamicSwatches}
      classes={classes}
      textBelowSwatches={showSwatchTextBelow}
      handleOnSwatchClick={handleOnSwatchClick}
      col={col}
      linkUrl={linkUrl || data?.bannerLink}
      centered={isFeatureCGCenetered}
      selectedSwatch={selectedSwatch}
      setSelectedSwatch={setSelectedSwatch}
      saleMessageEnabled={
        data?.onCategoryFinishSale === 0 ? false : data?.saleMessageEnabled
      }
    />
  );

  const memberPricingUi = useMemo(() => {
    const showPriceInfo = priceInfo?.lowestPriceSku && listPrice && memberPrice;
    return (
      <div
        className={classNames(classes?.saleAndMembershipCG)}
        style={{ paddingTop: isConcierge ? 12 : 0 }}
      >
        {showPriceInfo ? (
          <PriceInfo
            priceInfo={priceInfo}
            pageContent={pageContent}
            saleStats={data?.saleMemberInfo?.pgSaleStats}
            saleMessageEnabled={data?.saleMessageEnabled}
            saleSwatchMatch={data?.dynamicSwatches?.saleSwatchMatch}
          />
        ) : null}
        {/* migh be used in future */}
        {/* {showViewSelectItemOnSale &&
            data?.dynamicSwatches?.top5SwatchIds?.length ? (
              <SaleText
                id={data?.id}
                parentCatId={data?.parentCatId}
                forceSaleFlag={data?.forceSaleFlag}
                saleStats={data?.saleMemberInfo?.pgSaleStats}
                typographyStyles={classes}
                hasShellProducts={data?.hasShellProducts || false}
                saleMessageEnabled={data?.saleMessageEnabled}
                forceViewSelectItemOnSale={data?.onCategoryFinishSale}
                allSwatchIdsOnSale={allSwatchIdsOnSale}
              />
            ) : null} */}
        <MemberSavingsText
          showingPriceInfo={showPriceInfo}
          memberSavings={{
            ...data?.saleMemberInfo?.pgMemberSavings,
            percentSaleSkus: data?.saleMemberInfo?.pgSaleStats?.percentSaleSkus
          }}
          salePercentage={Number(
            data?.saleMemberInfo?.pgSaleStats?.percentSaleSkus
          )}
          linkUrl={linkUrl || data?.bannerLink}
          linkToSalePG={true}
          saleMessageEnabled={data?.saleMessageEnabled}
          displaySaleCopy={displaySaleCopy}
        />
      </div>
    );
  }, [
    priceInfo,
    listPrice,
    memberPrice,
    col,
    classes?.saleAndMembershipCG,
    isConcierge,
    pageContent,
    data,
    linkUrl
  ]);

  if (hideDetails) return null;

  const StyledHidden = styled(Hidden)({
    width: "100%"
  });

  const colorText = useMemo(() => {
    return template(pageContent?.Shown_in || "Shown in <%= color %>")({
      color: (selectedSwatch && isCgColorizable
        ? data?.dynamicSwatches?.top5SwatchIds?.find(
            swatch => swatch?.id === selectedSwatch
          )
        : data?.dynamicSwatches?.top5SwatchIds?.[0]
      )?.displayName
    });
  }, [data?.dynamicSwatches, selectedSwatch]);

  return (
    <div
      id="component-collection-card-details"
      data-testid={`collectionCardDetails-${data.id}`}
      data-cg={data.id}
      className={classNames([
        `${
          col === 4
            ? `!pt-0 !pb-5 ${smDown && col !== 4 ? "!pr-5 !pl-5" : ""}`
            : ""
        }`,
        classes.rhrCgGrid,
        rhStyles.collectionGalleryHeader,
        `${col === 4 || col === 6 ? "md:w-full" : "md:w-[50%]"} grid ${
          col === 4 ? "lg:gap-2" : "lg:gap-8"
        }`
      ])}
    >
      {!showSwatchTextBelow && <div></div>}
      {/* Swatches for grid 2 and 3 but only from medium to large */}
      {(col === 6 || col === 4) && (
        <StyledHidden smDown>{dynamicSwatchsUI}</StyledHidden>
      )}
      <div
        className={`!pt-0 flex flex-col justify-center ${
          col !== 12 ? "" : "!pb-0"
        }`}
      >
        <StyledHidden mdUp>
          <div></div>
          {dynamicSwatchsUI}
        </StyledHidden>
        <div
          className={classNames([
            classes.gridItem2Centered,
            `${col === 12 ? "sm:order-1" : "sm:order-none"}`,
            `
                ${
                  col !== 12
                    ? "pt-2"
                    : smDown
                    ? "pt-6"
                    : smDown && processEnvServer
                    ? "pt-[30px]"
                    : "pt-0"
                }
                `
          ])}
        >
          {(data.introText || allSwatchIdsOnSale || isSaleSku20Plus) && (
            <Typography
              className={`!font-[RHSans-ExtraLight] !text-black !leading-[90%] !tracking-[0.165px] !uppercase !pb-2 xs:!text-[10px] md:!text-[11px] ${
                col === 12 ? "lg:!text-[11px]" : "lg:!text-[10px]"
              }`}
            >
              {data.introText && pageContent?.INTRODUCING}
              {isSaleSku20Plus && displaySaleCopy === "true" && (
                <>
                  {data.introText && " / "}
                  <span className="!text-[#CA2022] !text-[20px] !font-primary-rhthin !leading-[120%] !tracking-[0.3px]">
                    {pageContent?.SALE_TEXT}
                  </span>
                </>
              )}
            </Typography>
          )}
          {Boolean(smallView) ? (
            <RHLink
              data-testid={`collectionCardLink-${data.id}`}
              underline="none"
              style={{ width: "100%" }}
              onClick={e => {
                saveSelectedCategory?.(data?.id);
              }}
              to={
                data?.useBannerLink && data?.bannerLink
                  ? `${prefix}${data?.bannerLink}`
                  : buildPath("/catalog/category/products.jsp", {
                      categoryId: data.id,
                      sale: (!!saleContextFilter).toString(),
                      pgterm: `category:${data.id}`,
                      hasShellProducts: (
                        data?.hasShellProducts || false
                      )?.toString()
                    })
              }
            >
              <Title
                data={data}
                env={env}
                classes={classes}
                mdUp={mdUp}
                isLandscapeFull={isLandscapeFull}
                smUp={smUp}
                mdOnly={mdOnly}
                isModern={isModern}
                typographyStyles={typographyStyles}
              />
            </RHLink>
          ) : (
            <Title
              data={data}
              env={env}
              classes={classes}
              mdUp={mdUp}
              isLandscapeFull={isLandscapeFull}
              smUp={smUp}
              mdOnly={mdOnly}
              isModern={isModern}
              typographyStyles={typographyStyles}
            />
          )}
          {data?.bannerSubSubTitle &&
            (!isFeatureCgColorization ||
              !data?.dynamicSwatches?.top5SwatchIds) && (
              <Typography
                id="bannerSubSubTitleCG"
                className="!text-black !font-primary-rhthin !text-[10px] !font-[200] !leading-[120%] !tracking-[0.15px] !uppercase"
                dangerouslySetInnerHTML={{
                  __html: data.bannerSubSubTitle || ""
                }}
              />
            )}

          <div className={"my-3 mx-0 flex flex-col gap-1"}>
            {/* bannersubtitle is new so it will render only in case of feature-cg is true */}
            {isFeatureCgColorization &&
              data?.dynamicSwatches?.top5SwatchIds?.length > 0 && (
                <Typography
                  id="bannerSubtitle3-dynamic"
                  Welcom
                  className="!text-black !font-primary-rhthin !text-[11px] !not-italic !font-[100] !leading-[13.2px] !tracking-[0.165px]"
                  dangerouslySetInnerHTML={{
                    __html: colorText
                  }}
                />
              )}
            {(!isFeatureCgColorization ||
              !data?.dynamicSwatches?.top5SwatchIds?.length) &&
              data?.bannerSubtitle3 && (
                <Typography
                  id="bannerSubtitle3-dynamic"
                  className="!text-black !font-primary-rhthin !text-[11px] !not-italic !font-[100] !leading-[13.2px] !tracking-[0.165px]"
                  dangerouslySetInnerHTML={{
                    __html: data?.bannerSubtitle3
                  }}
                />
              )}
            {data?.additionalBannerCopy && !isFeatureCgColorization && (
              <Typography
                id="finishItemsSMUpCG"
                className="!text-black !font-primary-rhthin !text-[11px] !not-italic !font-[100] !leading-[13.2px] !tracking-[0.165px]"
                dangerouslySetInnerHTML={{
                  __html: data?.additionalBannerCopy || ""
                }}
              />
            )}
          </div>

          {/* below code is from old cg styles when FEATURE_CG is false */}

          {data?.stockedDeliveryMessage ? (
            <Typography
              id="stockedDeliveryMessage"
              className="!text-black !font-primary-rhthin !text-[11px] !not-italic !font-thin !leading-[13.2px] !tracking-[0.165px] !uppercase"
            >
              {data.stockedDeliveryMessage || ""}
            </Typography>
          ) : null}

          {data?.specialOrderDeliveryMessage ? (
            <Typography
              id="specialOrderDeliveryMessage"
              className="!text-black !font-primary-rhthin !text-[11px] !not-italic !font-thin !leading-[16.5px] !tracking-[0.165px] !uppercase !pt-1"
            >
              {data.specialOrderDeliveryMessage || ""}
            </Typography>
          ) : null}

          {(data?.saleMessageEnabled ||
            data?.stockInfo?.inStock ||
            data?.saleMemberInfo?.stockInfo?.inStock) &&
          !data?.stockedDeliveryMessage &&
          !data?.specialOrderDeliveryMessage ? (
            <Typography
              id="inStockItems"
              className={classNames(
                typographyStyles?.rhBaseBody0,
                "!text-black !font-primary-rhthin !text-[11px] !font-thin !leading-[13.2px] !tracking-[0.165px] !uppercase "
              )}
            >
              {pageContent?.IN_STOCK_ITEMS}
            </Typography>
          ) : null}

          {memberPricingUi}
        </div>
      </div>

      {col === 12 && <Hidden only={["sm", "xs"]}>{dynamicSwatchsUI}</Hidden>}
    </div>
  );
};

export default memoize(CollectionCardDetails);
