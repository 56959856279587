import React, { useState, useMemo, useCallback } from "react";
import {
  Grid,
  useTheme,
  Theme,
  Typography,
  IconButton,
  GridList,
  GridListTile,
  useMediaQuery
} from "@material-ui/core";
import ProductOptionDropdown from "component-product-option-dropdown";
import RHDivider from "component-rh-divider";
import he from "he";
import SwatchGroups from "component-swatch-groups";
import RHTextField from "component-rh-text-field";
import {
  getLengthOptions,
  getWidthOptions,
  getLengthGapOptions,
  getWidthGapOptions,
  getFormattedMeasurement,
  PANELS,
  SUB_PANEL_OPTIONS,
  fractionCalculator
} from "utils/customProductHelper";
import {
  ROMAN_MOTORIZED_MIN_WIDTH,
  ROMAN_MOTORIZED_MAX_WIDTH,
  ROMAN_MOTORIZED_MIN_LENGTH,
  ROMAN_MOTORIZED_MAX_LENGTH,
  ROMAN_CONTLOOP_MIN_WIDTH,
  ROMAN_CORDLOCK_MAX_WIDTH,
  ROMAN_CORDLOCK_MAX_LENTH,
  ROLLER_MOTORIZED_MIN_WIDTH,
  ROLLER_MOTORIZED_MAX_WIDTH,
  ROLLER_MOTORIZED_MIN_LENGTH,
  ROLLER_MOTORIZED_MAX_LENGTH,
  BLIND_MOTORIZED_MIN_WIDTH,
  BLIND_MOTORIZED_MAX_WIDTH,
  BLIND_MOTORIZED_MIN_LENGTH,
  BLIND_MOTORIZED_MAX_LENGTH,
  DEFAULT_TILT_POSITION_MOTORIZED,
  CONTROL_MOTORIZED,
  CONTROL_CONTINUOUS_LOOP,
  CONTROL_STANDARD
} from "utils/constants";
import { CustomProductConfiguration } from "component-custom-product";
import RHInfoIcon from "icon-info";
import memoize from "utils/memoize";
import RHLink from "component-rh-link";
import CustomProductInfoDialog from "dialog-custom-product-info";
import useTypographyStyles from "hooks/useTypographyStyles";
import RHImage from "component-rh-image";
import { usePageContent } from "customProviders/LocationProvider";
import classNames from "classnames";

interface ICustomProductCardProps {
  loading?: boolean;
  data?: Product;
  pli?: ProductLineItem;
  productSwatchImage: Maybe<ProductImage>;
  swatchGroups?: ProductSwatchGroup[];
  selectedSwatches?: {
    [groupMaterial: string]: ProductSwatch;
  };
  configuration?: CustomProductConfiguration;
  shadeType?: string;
  customProductType?: string;
  onChange?: (
    value: string,
    options: ProductAvailableOption[] | undefined
  ) => void;
  handleRequestSwatch?: (e) => void;
  onConfigChange: (
    key: string,
    value: string | number | ProductAvailableOption | CustomOption | undefined,
    customOptions?: CustomProductConfiguration
  ) => void;
  handleOnSwatchClick?: {
    (data: {
      swatchGroup: ProductSwatchGroup;
      selectedSwatch: ProductSwatch;
    }): void;
  };
  swatchErrorMessage?: boolean;
}

const CustomProductCard: React.FC<ICustomProductCardProps> = ({
  loading,
  data,
  pli,
  productSwatchImage,
  selectedSwatches,
  swatchGroups,
  configuration,
  shadeType,
  customProductType,
  handleOnSwatchClick,
  onConfigChange,
  onChange,
  handleRequestSwatch,
  swatchErrorMessage
}) => {
  const {
    maxLength,
    minLength,
    maxWidth,
    minWidth,
    maxControlLength,
    minControlLength,
    widthFractionGap,
    lengthFractionGap,
    panels,
    linings,
    mountTypes,
    bracketColors,
    controlTypes,
    controlsAndTilts,
    controlPositions,
    rollTypes,
    tiltTypes,
    finish
  } = pli?.customProductOptions?.customProductInformation || {};

  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
  const { pageContent } = usePageContent();

  const getDraperLining = useCallback(
    (customProductType: string = "") => {
      if (customProductType === "drape") {
        return (
          pli?.availableOptions?.find(
            option => option.type === "Drapery Lining"
          )?.options || ([] as ProductAvailableOption[])
        );
      } else {
        return linings || ([] as CustomOption[]);
      }
    },
    [linings, pli?.availableOptions]
  );

  const getRodSize = useCallback((): ProductAvailableOptionSet | undefined => {
    return pli?.availableOptions?.find(option => option.type === "Size");
  }, [pli?.availableOptions]);

  const rodSize = useMemo(() => getRodSize(), [getRodSize]);

  const getRingType = useCallback((): ProductAvailableOptionSet | undefined => {
    return pli?.availableOptions?.find(option => option.type === "Ring Type");
  }, [pli?.availableOptions]);
  const ringType = useMemo(() => getRingType(), [getRingType]);

  const getRodType = useCallback((): ProductAvailableOptionSet | undefined => {
    return pli?.availableOptions?.find(option => option.type === "Type");
  }, [pli?.availableOptions]);
  const rodType = useMemo(() => getRodType(), [getRodType]);

  const getBracketShape = useCallback(():
    | ProductAvailableOptionSet
    | undefined => {
    return pli?.availableOptions?.find(
      option => option.type === "Bracket Shape"
    );
  }, [pli?.availableOptions]);
  const bracketShape = useMemo(() => getBracketShape(), [getBracketShape]);

  const getBackPlateShape = useCallback(():
    | ProductAvailableOptionSet
    | undefined => {
    return pli?.availableOptions?.find(
      option => option.type === "Backplate Shape"
    );
  }, [pli?.availableOptions]);
  const backplateShape = useMemo(
    () => getBackPlateShape(),
    [getBackPlateShape]
  );

  const memoizedLiningOptions: (ProductAvailableOption | CustomOption)[] =
    useMemo(
      () => getDraperLining(customProductType),
      [customProductType, getDraperLining]
    );

  const typographyClasses = useTypographyStyles({
    keys: ["rhBaseLink2", "rhBaseBody2", "rhBaseCaption", "uppercaseText"]
  });
  const theme = useTheme();
  const [width, setWidth] = useState(0);
  const [length, setLength] = useState(0);
  const [widthFraction, setWidthFraction] = useState(0);
  const [lengthFraction, setLengthFraction] = useState(0);
  const [selectedMountType, setSelectedMountType] = useState<CustomOption>();
  const [selectedLining, setSelectedLining] = useState<CustomOption>();
  const [selectedControlType, setSelectedControlType] = useState<
    CustomOption | undefined
  >({});
  const [controlLength, setControlLength] = useState(0);
  const [selectedControlPosition, setSelectedControlPosition] = useState<
    CustomOption | undefined
  >({});
  const [selectedBracketColor, setSelectedBracketColor] = useState<
    CustomOption | undefined
  >({});
  const [selectedRollType, setSelectedRollType] = useState<CustomOption>();
  const [selectedTiltType, setSelectedTiltType] = useState<CustomOption>();
  const [selectedControlAndTilt, setSelectedControlAndTilt] = useState<
    CustomOption | undefined
  >({});
  const [roomLabel, setSelectedRoomLabel] = useState("");
  const [roomLabelDescription, setRoomLabelDescription] = useState("");
  const [selectedPanel, setSelectedPanel] = useState("");
  const [subPanelValue, setSubPanelValue] = useState("");
  const [controlTypeWarning, setControlTypeWarning] = useState("");
  const [tiltTypeWarning, setTiltTypeWarning] = useState("");
  const [ringTypeWarning, setRingTypeWarning] = useState("");
  const [controlTiltPositionWarning, setControlTiltPositionWarning] =
    useState("");
  const [disabledControlType, setDisabledControlType] = useState<string[]>([]);
  const [disabledRingType, setDisabledRingType] = useState<string[]>([]);
  const [disabledControlAndTiltPositions, setDisabledControlAndTiltPosition] =
    useState<Maybe<string>[]>([]);

  const [selectedRodType, setSelectedRodType] = useState<CustomOption>();
  const [selectedRingType, setSelectedRingType] = useState<CustomOption>();
  const [selectedBracketShape, setSelectedBracketShape] = useState<
    CustomOption | undefined
  >();
  const [selectedBackplateShape, setSelectedBackplateShape] = useState<
    CustomOption | undefined
  >();
  const [selectedRodSize, setSelectedRodSize] = useState<CustomOption>();
  const [showSubPanel, setShowSubPanel] = useState(false);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [dialogType, setDialogType] = useState<string>("");
  const [selectedGrommetFinish, setSelectedGrommetFinish] = useState<
    CustomOption | undefined
  >();

  // on measurement changed
  const onMeasurementChange = useCallback(
    (type: string, value: number): void => {
      let widthValue = width + widthFraction;
      let lengthValue = length + lengthFraction;
      let lengthFractionGap = lengthFraction;
      let widthFractionGap = widthFraction;
      let displayWidth = configuration?.displayWidth;
      let displayLength = configuration?.displayLength;

      switch (type) {
        case "width":
          displayWidth = getFormattedMeasurement(value, widthFraction);
          setWidth(value);
          if (
            maxWidth !== undefined &&
            maxWidth !== null &&
            widthFraction &&
            value >= maxWidth
          ) {
            setWidthFraction(0);
            widthValue = value;
          } else {
            widthValue = value + widthFraction;
          }
          break;

        case "widthFractionGap":
          displayWidth = getFormattedMeasurement(width, value);
          widthValue = width + value;
          widthFractionGap = value;
          setWidthFraction(value);
          break;

        case "length":
          displayLength = getFormattedMeasurement(value, lengthFraction);
          setLength(value);
          if (
            maxLength !== undefined &&
            maxLength !== null &&
            lengthFraction &&
            value >= maxLength
          ) {
            setLengthFraction(0);
            lengthValue = value;
          } else {
            lengthValue = value + lengthFraction;
          }
          // Compliance: controlLength should be 40% of length by default. But customer is allowed to change it later.
          const nextControlLength = Math.round(value * 0.4);
          onControlLengthChange(
            nextControlLength > (minControlLength || 0)
              ? nextControlLength
              : minControlLength || 0
          );
          break;

        case "lengthFractionGap":
          displayLength = getFormattedMeasurement(length, value);
          lengthValue = length + value;
          lengthFractionGap = value;
          setLengthFraction(value);
          break;
      }

      selectedControlType?.id && resetControlType();

      selectedControlPosition?.id && resetControlPosition();

      selectedBracketColor?.id && resetHardwareFinish();

      selectedRollType?.id && resetRollType();

      selectedLining?.id && resetLinings();

      roomLabel && resetRoomLabel();

      selectedTiltType?.id && resetTiltType();

      selectedControlAndTilt?.id && resetControlAndTiltPosition();

      pdpStateChanged(widthValue, lengthValue);

      const changedValue = type?.includes("width") ? widthValue : lengthValue;

      const customConfig = {
        isCustom: true,
        isMotorized: false,
        isContiniousLoop: false,
        controlTypes: undefined,
        controlPositions: undefined,
        bracketColors: undefined,
        rollTypes: undefined,
        roomLabel: undefined,
        tiltTypes: undefined,
        width: widthValue,
        length: lengthValue,
        widthFractionGap,
        lengthFractionGap,
        controlsAndTilts: undefined,
        displayWidth,
        displayLength
      };
      onConfigChange(type, changedValue, { ...customConfig });
    },
    [minControlLength, width, length, selectedLining, roomLabel]
  );

  const onMeasurementWidthChange = useCallback(
    (value: string) => {
      onMeasurementChange("width", Number(value));
    },
    [onMeasurementChange]
  );

  const onMeasurementLengthChange = useCallback(
    (value: string) => {
      onMeasurementChange("length", Number(value));
    },
    [onMeasurementChange]
  );

  const onMeasurementWidthFractionChange = useCallback(
    (value: string) => {
      onMeasurementChange("widthFractionGap", Number(value));
    },
    [onMeasurementChange]
  );

  const onMeasurementLengthFractionChange = useCallback(
    (value: string) => {
      onMeasurementChange("lengthFractionGap", Number(value));
    },
    [onMeasurementChange]
  );

  const pdpStateChanged = (widthValue: number, lengthValue: number): void => {
    const dimensionsSelected = widthValue > 0 && lengthValue > 0;
    switch (shadeType) {
      case "roman":
        pdpRomanStateChanged(widthValue, lengthValue, dimensionsSelected);
        break;

      case "roller":
        pdpRollerStateChanged(widthValue, lengthValue, dimensionsSelected);
        break;

      case "wood":
        pdpWoodStateChanged(widthValue, lengthValue, dimensionsSelected);
        break;
    }
  };

  // reset methods
  const resetControlType = (): void => {
    setSelectedControlType({
      id: "",
      value: ""
    });
    // Compliance: control length should start out at 40% of length.
    setControlLength(Math.round((length || minControlLength || 0) * 0.4)); // This might not be needed. We have another one of these above
  };

  const resetHardwareFinish = (): void => {
    setSelectedBracketColor({
      id: "",
      value: ""
    });
  };

  const resetRollType = (): void => {
    setSelectedRollType({
      id: "",
      value: ""
    });
  };

  const resetTiltType = (): void => {
    setSelectedTiltType({
      id: "",
      value: ""
    });
  };

  const resetLinings = (): void => {
    setSelectedLining({
      id: "",
      value: ""
    });
  };

  const resetControlAndTiltPosition = (): void => {
    setSelectedControlAndTilt({
      id: "",
      value: ""
    });
  };

  const resetRoomLabel = (): void => {
    setSelectedRoomLabel("");
  };

  const resetControlPosition = (): void => {
    setSelectedControlPosition({
      id: "",
      value: ""
    });
  };

  // custom options
  const onMountTypeChange = useCallback(
    (value: string) => {
      const mountType = mountTypes?.find(option => option.id === value);
      let data;
      data =
        value && mountType
          ? { ...mountType, ...{ type: "Mount Type" } }
          : undefined;
      setSelectedMountType(mountType);
      onConfigChange("mountTypes", data, {
        isCustom: true
      });
    },
    [mountTypes, onConfigChange]
  );

  const onLiningChange = useCallback(
    (value: string) => {
      let selLining;
      let data = "";
      if (customProductType === "drape" && value) {
        selLining = memoizedLiningOptions?.find(option => option.id === value);
        data = { ...selLining, ...{ type: "Drapery Lining" } };
        onConfigChange("linings", data);
        onChange?.(value, memoizedLiningOptions as ProductAvailableOption[]);
      } else if (customProductType === "drape" && !value) {
        onChange?.(value, memoizedLiningOptions as ProductAvailableOption[]);
      } else if (customProductType === "shade" && value) {
        selLining = linings?.find(option => option.id === value);
        data = { ...selLining, ...{ type: "Lining" } };
        onConfigChange("linings", data, {
          isCustom: true
        });
      } else {
        onConfigChange("linings", data, {
          isCustom: true
        });
      }
      setSelectedLining(selLining);
    },
    [
      customProductType,
      linings,
      memoizedLiningOptions,
      onChange,
      onConfigChange
    ]
  );

  const onControlTypeChange = useCallback(
    (value: string) => {
      const controlType = controlTypes?.find(option => option.id === value);
      const isMotorized = controlType?.motorized || false;
      const isContiniousLoop = controlType?.continuousLoop || false;
      const data: CustomOption | undefined =
        value && controlType
          ? { ...controlType, ...{ type: "Control Type" } }
          : undefined;
      setSelectedControlType(controlType);
      onConfigChange("controlTypes", data, {
        isCustom: true,
        isMotorized,
        isContiniousLoop
      });
    },
    [controlTypes, onConfigChange]
  );

  const onControlPositionChange = useCallback(
    (value: string) => {
      const controlPosition = controlPositions?.find(
        option => option.id === value
      );
      const data: CustomOption | undefined =
        value && controlPosition
          ? { ...controlPosition, ...{ type: "Control Position" } }
          : undefined;
      setSelectedControlPosition(controlPosition);
      onConfigChange("controlPositions", data, { isCustom: true });
    },
    [controlPositions, onConfigChange]
  );

  const onControlLengthChange = useCallback(
    (value: string | number) => {
      const parsedValue = Number(value);
      const data: CustomOption = {
        id: "",
        type: "Control Length",
        value: `${parsedValue}&#34;`,
        message: ""
      };
      setControlLength(parsedValue);
      onConfigChange("controlLength", parsedValue, {
        isCustom: true,
        displayControlLength: data
      });
    },
    [onConfigChange]
  );

  const onHardwareFinishChange = useCallback(
    (value: string) => {
      const bracketColor = bracketColors?.find(option => option.id === value);
      const data: CustomOption | undefined =
        value && bracketColor
          ? { ...bracketColor, ...{ type: "Bracket Color" } }
          : undefined;
      setSelectedBracketColor(bracketColor);
      onConfigChange("bracketColors", data, { isCustom: true });
    },
    [bracketColors, onConfigChange]
  );

  const onRollTypeChange = useCallback(
    (value: string) => {
      const rollType = rollTypes?.find(option => option.id === value);
      const data: CustomOption | undefined =
        value && rollType
          ? { ...rollType, ...{ type: "Roll Type" } }
          : undefined;
      setSelectedRollType(rollType);
      onConfigChange("rollTypes", data, { isCustom: true });
    },
    [onConfigChange, rollTypes]
  );

  const onTiltTypeChange = useCallback(
    (value: string) => {
      let disabledControlAndTilts: Maybe<string>[] = [];
      let controlTiltPositionMessage = "";
      const tiltType = tiltTypes?.find(option => option.id === value);
      let data: CustomOption | undefined = {
        ...tiltType,
        ...{ type: "Tilt Type" }
      };
      let customConfig: CustomProductConfiguration = {
        isCustom: true,
        isMotorized: tiltType?.motorized ? true : false,
        isContiniousLoop: tiltType?.continuousLoop ? true : false,
        controlsAndTilts: configuration?.controlsAndTilts
      };
      if (tiltType?.value === CONTROL_MOTORIZED) {
        const defaultControlAndTilt =
          controlsAndTilts?.find(
            option => option.id === DEFAULT_TILT_POSITION_MOTORIZED
          ) || {};
        controlTiltPositionMessage =
          pageContent?.warningMessages?.tiltTypeWarning;
        disabledControlAndTilts = (controlsAndTilts || [])
          .filter(option => option?.id !== DEFAULT_TILT_POSITION_MOTORIZED)
          .map(option => option?.value || "");
        setSelectedControlAndTilt(defaultControlAndTilt);
        customConfig = {
          ...customConfig,
          controlsAndTilts: { ...defaultControlAndTilt, type: "Control & Tilt" }
        };
      }
      data = value ? data : undefined;
      setSelectedTiltType(tiltType);
      setControlTiltPositionWarning(controlTiltPositionMessage);
      setDisabledControlAndTiltPosition(disabledControlAndTilts);
      onConfigChange("tiltTypes", data, { ...customConfig });
    },
    [
      tiltTypes,
      configuration?.controlsAndTilts,
      onConfigChange,
      controlsAndTilts,
      pageContent
    ]
  );

  const onControlsAndTiltChange = useCallback(
    (value: string) => {
      const controlAndTilt = controlsAndTilts?.find(
        option => option.id === value
      );
      const data: CustomOption | undefined =
        value && controlAndTilt
          ? {
              ...controlAndTilt,
              ...{ type: "Control & Tilt" }
            }
          : undefined;
      setSelectedControlAndTilt(controlAndTilt);
      onConfigChange("controlsAndTilts", data, { isCustom: true });
    },
    [controlsAndTilts, onConfigChange]
  );

  const onBracketShapeChange = useCallback(
    (value: string) => {
      const selBracketShape = bracketShape?.options?.find(
        option => option.id === value
      );
      setSelectedBracketShape(selBracketShape);
      if (onChange) onChange(value, bracketShape?.options);
      onConfigChange("bracketShape", value);
    },
    [bracketShape?.options, onChange, onConfigChange]
  );

  const onBackplateShapeChange = useCallback(
    (value: string) => {
      const selBackplateShape = backplateShape?.options?.find(
        option => option.id === value
      );
      setSelectedBackplateShape(selBackplateShape);
      if (onChange) onChange(value, backplateShape?.options);
      onConfigChange("backplateShape", value);
    },
    [backplateShape?.options, onChange, onConfigChange]
  );
  const onRodSizeChange = useCallback(
    (value: string) => {
      const selSize = rodSize?.options?.find(option => option.id === value);
      setSelectedRodSize(selSize);
      onChange?.(value, rodSize?.options);
      onConfigChange("rodSize", value);
    },
    [onChange, onConfigChange, rodSize?.options]
  );

  const onRodTypeChange = useCallback(
    (value: string) => {
      const selRodType = rodType?.options?.find(option => option.id === value);
      let disabledRings: string[] = [];
      let ringTypeWarningMessage = "";
      setSelectedRodType(selRodType);

      if (selRodType?.value === "Double Rod") {
        ringTypeWarningMessage = pageContent?.warningMessages?.ringTypeWarning;
        disabledRings.push("With C-Loop Rings");
      }
      setDisabledRingType(disabledRings);
      setRingTypeWarning(ringTypeWarningMessage);
      onChange?.(value, rodType?.options);
      onConfigChange("rodType", value);
    },
    [onChange, onConfigChange, rodType?.options, pageContent]
  );

  const onRingTypeChange = useCallback(
    (value: string) => {
      const selRingType = ringType?.options?.find(
        option => option.id === value
      );
      setSelectedRingType(selRingType);
      onChange?.(value, ringType?.options);
      onConfigChange("ringType", value);
    },
    [onChange, onConfigChange, ringType?.options]
  );

  const onPanelChange = useCallback(
    (value: string) => {
      let panelData: string | CustomOption = "";
      if (value === "Single") {
        setSelectedPanel(value);
        setShowSubPanel(true);
        setSubPanelValue("");
      } else if (value === "Pair") {
        const selPanel = panels?.find(option => option.value === value);
        panelData = {
          ...selPanel,
          ...{ type: "Panel" }
        };
        setSelectedPanel(value);
        setShowSubPanel(false);
      } else {
        setShowSubPanel(false);
      }
      onConfigChange("panels", panelData, { isCustom: true });
    },
    [onConfigChange, panels]
  );

  const onSubPanelChange = useCallback(
    (value: string) => {
      let panelData;
      if (value) {
        const selPanel = panels?.find(option => option?.value?.includes(value));
        panelData = {
          ...selPanel,
          ...{ type: "Panel" }
        };
      }
      setSubPanelValue(value);
      onConfigChange("panels", panelData, { isCustom: true });
    },
    [onConfigChange, panels]
  );

  const onLengthChange = useCallback(
    (value: string) => {
      const parsedValue = Number(value);
      setLength(parsedValue);
      onConfigChange("length", parsedValue, {
        isCustom: true,
        displayLength: `${parsedValue}&#34;`
      });
    },
    [onConfigChange]
  );

  const onRoomLabelChange = useCallback(
    (value: string) => {
      setSelectedRoomLabel(value);
      onConfigChange("roomLabel", value, { isCustom: true });
    },
    [onConfigChange]
  );

  const onRoomLabelDescriptionBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      onConfigChange("roomLabelDescription", e?.target?.value, {
        isCustom: true
      });
    },
    [onConfigChange]
  );

  const onRoomLabelDescriptionChange = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setRoomLabelDescription(e?.target?.value);
    },
    []
  );

  const onGrommetFinishChange = option => {
    setSelectedGrommetFinish({ ...option, type: "Finish" });
    onConfigChange(
      "finish",
      { ...option, type: "Finish" },
      {
        isCustom: true
      }
    );
  };

  const pdpRomanStateChanged = (
    widthValue: number,
    lengthValue: number,
    dimensionsSelected: boolean = false
  ): void => {
    let controlTypeMessage = "";
    const disabledControlTypes: string[] = [];
    if (dimensionsSelected) {
      if (
        widthValue < ROMAN_MOTORIZED_MIN_WIDTH ||
        widthValue > ROMAN_MOTORIZED_MAX_WIDTH ||
        lengthValue < ROMAN_MOTORIZED_MIN_LENGTH ||
        lengthValue > ROMAN_MOTORIZED_MAX_LENGTH
      ) {
        controlTypeMessage =
          controlTypeMessage +
          pageContent?.warningMessages?.romanMotorizedSizeWarning;
        disabledControlTypes.push(CONTROL_MOTORIZED);
      }
      if (widthValue < ROMAN_CONTLOOP_MIN_WIDTH) {
        controlTypeMessage =
          controlTypeMessage +
          pageContent?.warningMessages?.romanContinuousLoopSizeWarning;
        disabledControlTypes.push(CONTROL_CONTINUOUS_LOOP);
      }
      if (
        widthValue > ROMAN_CORDLOCK_MAX_WIDTH ||
        lengthValue > ROMAN_CORDLOCK_MAX_LENTH
      ) {
        controlTypeMessage =
          pageContent?.warningMessages?.romanStandardMaxSizeWarning;
        disabledControlTypes.push(CONTROL_STANDARD);
      }
    }
    setDisabledControlType(disabledControlTypes);
    setControlTypeWarning(controlTypeMessage);
  };
  const pdpRollerStateChanged = (
    widthValue: number,
    lengthValue: number,
    dimensionsSelected: boolean = false
  ): void => {
    let controlTypeMessage = "";
    let disabledControlTypes: string[] = [];
    if (dimensionsSelected) {
      if (
        widthValue < ROLLER_MOTORIZED_MIN_WIDTH ||
        widthValue > ROLLER_MOTORIZED_MAX_WIDTH ||
        lengthValue < ROLLER_MOTORIZED_MIN_LENGTH ||
        lengthValue > ROLLER_MOTORIZED_MAX_LENGTH
      ) {
        controlTypeMessage =
          controlTypeMessage +
          pageContent?.warningMessages?.rollerMotorizedSizeWarning;
        disabledControlTypes.push(CONTROL_MOTORIZED);
      }
    }
    setDisabledControlType(disabledControlTypes);
    setControlTypeWarning(controlTypeMessage);
  };
  const pdpWoodStateChanged = (
    widthValue: number,
    lengthValue: number,
    dimensionsSelected: boolean = false
  ): void => {
    let tiltTypeMessage = "";
    let disabledControlTypes: string[] = [];
    if (dimensionsSelected) {
      if (
        widthValue < BLIND_MOTORIZED_MIN_WIDTH ||
        widthValue > BLIND_MOTORIZED_MAX_WIDTH ||
        lengthValue < BLIND_MOTORIZED_MIN_LENGTH ||
        lengthValue > BLIND_MOTORIZED_MAX_LENGTH
      ) {
        tiltTypeMessage =
          pageContent?.warningMessages?.tiltTypeMotorizedSizeWarning;
        disabledControlTypes.push(CONTROL_MOTORIZED);
      }
    }
    setDisabledControlType(disabledControlTypes);
    setTiltTypeWarning(tiltTypeMessage);
  };

  const handleInfoDialogClose = useCallback(() => {
    setShowInfoDialog(false);
  }, []);

  const ControlLength = useMemo(
    () => (
      <ProductOptionDropdown
        id={`control-length`}
        label="Control Length"
        value={controlLength?.toString()}
        options={getLengthOptions(
          maxControlLength || 0,
          minControlLength || 0
        )?.map((option, index) => ({
          key: `${"control-length"}-${index}`,
          value: option?.toString(),
          display: option
        }))}
        onChange={onControlLengthChange}
        fullWidth
      />
    ),
    [onControlLengthChange, controlLength, maxControlLength, minControlLength]
  );

  let count = 0;
  let fabricTitle = "";
  if (selectedSwatches?.Fabric) {
    fabricTitle = selectedSwatches?.Fabric?.title;
  }
  if (selectedSwatches?.Color) {
    fabricTitle = selectedSwatches?.Color?.title;
  }

  const handleInfoDialog = useCallback(
    (e: any) => {
      let { name } = e?.currentTarget;
      if (name === "measurements") {
        if (customProductType === "shade") {
          const cwCode =
            shadeType === "wood" ? "WD" : shadeType === "roller" ? "RO" : "RS";
          name = `${name}${cwCode}`;
        }
      }
      setDialogType(name);
      setShowInfoDialog(true);
    },
    [shadeType, customProductType]
  );

  return (
    <>
      {!loading && (
        <>
          <Grid className="!grow !pt-[45px]" container spacing={1}>
            {pli?.availableOptions?.map((availableOption, index) => {
              return (
                <>
                  {["Color", "Finish"].includes(availableOption.type) ? (
                    <Grid
                      key={`availableOptions-${index}`}
                      item
                      xs={12}
                      md={12}
                    >
                      <>
                        <Grid container className="!mb-[5px] !items-center">
                          <Grid item md={6} sm={6} xs={10}>
                            <Typography
                              className={classNames([
                                typographyClasses.rhBaseBody2,
                                "!uppercase !mb-2.5"
                              ])}
                            >
                              {(count += 1)}.{` SELECT ${availableOption.type}`}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            sm={6}
                            xs={2}
                            className="flex justify-end"
                          >
                            {availableOption.type !== "Color" &&
                              customProductType !== "customHardware" && (
                                <IconButton
                                  name="finish"
                                  onClick={handleInfoDialog}
                                >
                                  <RHInfoIcon className="cursor-pointer" />
                                </IconButton>
                              )}
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item md={12} sm={12} xs={12}>
                            <SwatchGroups
                              swatchGroups={swatchGroups || []}
                              swatches={["customSwatches", "stockedSwatches"]}
                              selectedSwatches={selectedSwatches || {}}
                              onClick={handleOnSwatchClick}
                              productSwatchImage={productSwatchImage}
                              detail={true}
                              isCustomProduct={true}
                              data={data}
                            />
                          </Grid>
                        </Grid>
                        <Grid>
                          <Grid className="mb-[30px]">
                            {customProductType === "shade" ||
                            customProductType === "drape" ? (
                              <Grid item className="!pt-5 !pb-2.5">
                                <RHLink
                                  onClick={handleRequestSwatch}
                                  className={typographyClasses.rhBaseLink2}
                                  underline="always"
                                >
                                  {he.decode(
                                    `Request a swatch of ${
                                      data?.displayName.split("<")[0]
                                    } in ${fabricTitle}`
                                  )}{" "}
                                </RHLink>
                              </Grid>
                            ) : null}
                            <p className="text-xs mt-[3px] text-left font-primary-rhsans font-normal leading-[1.66] text-red-300">
                              {swatchErrorMessage &&
                                "Requested Swatch Unavailable to add."}
                            </p>
                          </Grid>
                          <RHDivider className="!opacity-100 !text-gray-200" />
                        </Grid>
                      </>
                    </Grid>
                  ) : null}
                </>
              );
            })}

            {finish && customProductType === "drape" && (
              <Grid item xs={12} md={12} className="!mt-2">
                <Grid container className="!mb-1 items-center">
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography
                      className={typographyClasses.rhBaseBody2}
                      display="inline"
                    >
                      {(count += 1)}. SELECT GROMMET FINISH:{" "}
                    </Typography>
                    <Typography
                      className={classNames(
                        typographyClasses.rhBaseCaption,
                        "!font-medium !text-[13px]"
                      )}
                      display="inline"
                    >
                      {selectedGrommetFinish?.value}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="!mt-3">
                  <Grid item md={12} sm={12} xs={12}>
                    <GridList
                      cols={smDown ? 4 : 6}
                      cellHeight="auto"
                      spacing={smDown ? 17 : 20}
                    >
                      {finish?.map((option, index) => {
                        return (
                          <GridListTile
                            key={`grommet-finish-${index}`}
                            onClick={() => onGrommetFinishChange(option)}
                          >
                            <div
                              style={{
                                borderStyle: "solid",
                                borderWidth: 0,
                                ...(option?.id === selectedGrommetFinish?.id
                                  ? {
                                      borderWidth: 1,
                                      padding: 2
                                    }
                                  : {})
                              }}
                            >
                              <RHImage
                                src={`https://media.restorationhardware.com/is/image/rhis/${option.id}?wid=50&hei=50`}
                                alt={option.value || "custom-product-image"}
                              />
                            </div>
                          </GridListTile>
                        );
                      })}
                    </GridList>
                  </Grid>
                </Grid>
                <Grid className="!mt-[37px]">
                  <RHDivider className="opacity-100 text-gray-200" />
                </Grid>
              </Grid>
            )}

            {mountTypes && customProductType !== "trackHardware" && (
              <Grid item xs={12} md={12}>
                <Grid container className="!items-center !mb-[5px]">
                  <Grid item md={6} sm={6} xs={10}>
                    <Typography className={typographyClasses.rhBaseBody2}>
                      {" "}
                      {(count += 1)}. SELECT MOUNT TYPE
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={2} className="flex justify-end">
                    <IconButton name="mountType" onClick={handleInfoDialog}>
                      <RHInfoIcon className="cursor-pointer" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <ProductOptionDropdown
                      id={`mount-type`}
                      label="Mount Type"
                      value={selectedMountType?.id || ""}
                      options={mountTypes?.map((option, index) => ({
                        key: `mount-type-${option.id}${index}`,
                        value: option.id || "",
                        display: he.decode(option.value || "")
                      }))}
                      onChange={onMountTypeChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid className="!mt-[37px]">
                  <RHDivider className="!opacity-100 !text-gray-200" />
                </Grid>
              </Grid>
            )}

            {rodSize && (
              <Grid item xs={12} md={12}>
                <Grid container className="!items-center !mb-[5px]">
                  <Grid item md={6} sm={6} xs={10}>
                    <Typography className={typographyClasses.rhBaseBody2}>
                      {(count += 1)}. SELECT SIZE
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={2} className="flex justify-end">
                    <IconButton name="size" onClick={handleInfoDialog}>
                      <RHInfoIcon className="cursor-pointer" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <ProductOptionDropdown
                      id={`rod-size`}
                      label="Size"
                      value={selectedRodSize?.id || ""}
                      options={rodSize?.options?.map((option, index) => ({
                        key: `size-${option.id}${index}`,
                        value: option.id,
                        display: he.decode(option.value || "")
                      }))}
                      onChange={onRodSizeChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid className="!mt-[37px]">
                  <RHDivider className="!opacity-100 !text-gray-200" />
                </Grid>
              </Grid>
            )}

            {((minWidth || 0) > 0 || (minLength || 0) > 0) &&
              customProductType !== "customHardware" &&
              customProductType !== "trackHardware" && (
                <Grid item xs={12} md={12}>
                  <Grid container className="items-center !mb-[5px]">
                    <Grid item md={6} sm={6} xs={10}>
                      <Typography className={typographyClasses.rhBaseBody2}>
                        {(count += 1)}. SELECT MEASUREMENTS
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={2}
                      className="flex justify-end"
                    >
                      <IconButton
                        name="measurements"
                        onClick={handleInfoDialog}
                      >
                        <RHInfoIcon className="cursor-pointer" />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item container spacing={2}>
                      <Grid item md={6} sm={6} xs={6}>
                        <ProductOptionDropdown
                          id={`width-inches`}
                          label="Width (Inches)"
                          value={width?.toString()}
                          options={getWidthOptions(
                            maxWidth || 0,
                            minWidth || 0
                          )?.map((option, index) => ({
                            key: `width-inches-${index}`,
                            value: option?.toString(),
                            display: option
                          }))}
                          onChange={onMeasurementWidthChange}
                          fullWidth
                        />
                      </Grid>
                      {(widthFractionGap || 0) > 0 &&
                        (widthFractionGap || 0) < 1 && (
                          <Grid item md={6} sm={6} xs={6}>
                            <ProductOptionDropdown
                              id={`width-fraction-gap`}
                              label={`Width (${fractionCalculator(
                                widthFractionGap || 0,
                                0,
                                0
                              )} Inch)`}
                              value={widthFraction?.toString()}
                              options={
                                width >= (maxWidth || 0)
                                  ? []
                                  : getWidthGapOptions(
                                      widthFractionGap || 0
                                    )?.map((option, index) => ({
                                      key: `width-fraction-${index}`,
                                      value: option?.value?.toString() || "",
                                      display: option?.display
                                    }))
                              }
                              onChange={onMeasurementWidthFractionChange}
                              fullWidth
                            />
                          </Grid>
                        )}
                    </Grid>
                    <Grid item container spacing={2} className="!mt-[17px]">
                      <Grid item md={6} sm={6} xs={6}>
                        <ProductOptionDropdown
                          id={`length-inches`}
                          label="Length (Inches)"
                          value={length?.toString()}
                          options={getLengthOptions(
                            maxLength || 0,
                            minLength || 0
                          )?.map((option, index) => ({
                            key: `length-inches-${index}`,
                            value: option?.toString(),
                            display: option
                          }))}
                          onChange={onMeasurementLengthChange}
                          fullWidth
                        />
                      </Grid>
                      {(lengthFractionGap || 0) > 0 &&
                        (lengthFractionGap || 0) < 1 && (
                          <Grid item md={6} sm={6} xs={6}>
                            <ProductOptionDropdown
                              id={`length-fraction-gap`}
                              label={`Length (${fractionCalculator(
                                lengthFractionGap || 0,
                                0,
                                0
                              )} Inch)`}
                              value={lengthFraction?.toString()}
                              options={
                                length >= (maxLength || 0)
                                  ? []
                                  : getLengthGapOptions(
                                      lengthFractionGap || 0
                                    )?.map((option, index) => ({
                                      key: `length-fraction-${index}`,
                                      value: option?.value?.toString() || "",
                                      display: option?.display
                                    }))
                              }
                              onChange={onMeasurementLengthFractionChange}
                              fullWidth
                            />
                          </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} className="!mt-[37px]">
                      <RHDivider className="!opacity-100 !text-gray-200" />
                    </Grid>
                  </Grid>
                </Grid>
              )}

            {(customProductType === "customHardware" ||
              customProductType === "trackHardware") &&
              (minLength || 0) > 0 && (
                <Grid item xs={12} md={12}>
                  <Grid container className="!items-center !mt-[5px]">
                    <Grid item md={6} sm={6} xs={10}>
                      <Typography className={typographyClasses.rhBaseBody2}>
                        {(count += 1)}. SELECT{" "}
                        {customProductType === "trackHardware"
                          ? "WIDTH"
                          : "LENGTH"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={2}
                      className="flex justify-end"
                    >
                      {customProductType !== "customHardware" && (
                        <IconButton name="length" onClick={handleInfoDialog}>
                          <RHInfoIcon className="cursor-pointer" />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container className="!mt-2.5">
                    <Grid item md={6} sm={6} xs={6}>
                      <ProductOptionDropdown
                        id={`length-inches`}
                        label={
                          customProductType === "trackHardware"
                            ? "Width (inch)"
                            : "Length (inch)"
                        }
                        value={length?.toString()}
                        options={getLengthOptions(
                          maxLength || 0,
                          minLength || 0
                        )?.map((option, index) => ({
                          key: `length-type-${index}`,
                          value: option?.toString(),
                          display: option
                        }))}
                        onChange={onLengthChange}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="!mt-[37px]">
                    <RHDivider className="!opacity-100 !text-gray-200" />
                  </Grid>
                </Grid>
              )}

            {panels && (
              <Grid item xs={12} md={12}>
                <Grid container className="!items-center !mb-[5px]">
                  <Grid item md={6} sm={6} xs={10}>
                    <Typography className={typographyClasses.rhBaseBody2}>
                      {(count += 1)}. SELECT PANELS
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={2} className="flex justify-end">
                    <IconButton name="panels" onClick={handleInfoDialog}>
                      <RHInfoIcon className="cursor-pointer" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <ProductOptionDropdown
                      id={`panel-type`}
                      label="Panels"
                      value={selectedPanel}
                      options={PANELS?.map((option, index) => ({
                        key: `panel-type-${index}`,
                        value: option,
                        display: option
                      }))}
                      onChange={onPanelChange}
                      fullWidth
                    />
                  </Grid>
                  {showSubPanel ? (
                    <Grid item md={6} sm={6} xs={6}>
                      <ProductOptionDropdown
                        id={`panel-orientation`}
                        label="Orientation"
                        value={subPanelValue}
                        options={SUB_PANEL_OPTIONS?.map((option, index) => ({
                          key: `panel-orientation-${index}`,
                          value: option,
                          display: option
                        }))}
                        fullWidth
                        onChange={onSubPanelChange}
                      />
                    </Grid>
                  ) : null}
                </Grid>
                <Grid className="!mt-[37px]">
                  <RHDivider className="!opacity-100 !text-gray-200" />
                </Grid>
              </Grid>
            )}

            {rodType && (
              <Grid item xs={12} md={12}>
                <Grid container className="!items-center !mb-[5px]">
                  <Grid item md={6} sm={6} xs={10}>
                    <Typography className={typographyClasses.rhBaseBody2}>
                      {(count += 1)}. SELECT ROD TYPE
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={2} className="flex justify-end">
                    <IconButton name="rodType" onClick={handleInfoDialog}>
                      <RHInfoIcon className="cursor-pointer" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <ProductOptionDropdown
                      id={`rod-type`}
                      label="Rod Type"
                      value={selectedRodType?.id || ""}
                      options={rodType?.options?.map((option, index) => ({
                        key: `rod-type-${option.id}${index}`,
                        value: option.id,
                        display: he.decode(option.value || "")
                      }))}
                      onChange={onRodTypeChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid className="!mt-[37px]">
                  <RHDivider className="!opacity-100 !text-gray-200" />
                </Grid>
              </Grid>
            )}

            {controlTypes && (
              <>
                <Grid item xs={12} md={12}>
                  <Grid container className="items-center !mb-[5px]">
                    <Grid item md={6} sm={6} xs={10}>
                      <Typography className={typographyClasses.rhBaseBody2}>
                        {(count += 1)}. SELECT CONTROL TYPE
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={2}
                      className="flex justify-end"
                    >
                      <IconButton name="controlType" onClick={handleInfoDialog}>
                        <RHInfoIcon className="cursor-pointer" />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {controlTypeWarning && (
                    <Grid container className="!mb-5">
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          component="span"
                          className="font-primary-baron font-medium text-gray-600 tracking-[0.2px] leading-[18px] text-[13px]"
                        >
                          {he.decode(controlTypeWarning)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={6} xs={6}>
                      <ProductOptionDropdown
                        id={`control-type`}
                        label="Control Type"
                        value={selectedControlType?.id || ""}
                        options={controlTypes
                          ?.filter(
                            option =>
                              !disabledControlType?.includes(
                                option?.value || ""
                              )
                          )
                          ?.map((option, index) => ({
                            key: `control-type-${option.id}${index}`,
                            value: option.id || "",
                            display: he.decode(option.value || "")
                          }))}
                        onChange={onControlTypeChange}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  {selectedControlType?.value === "Standard Cord Lock" && (
                    <Grid container spacing={2} className="!mt-2.5">
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography className={typographyClasses.rhBaseBody2}>
                          CONTROL LENGTH
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={6} xs={6}>
                        {ControlLength}
                      </Grid>
                    </Grid>
                  )}
                  <Grid className="!mt-[37px]">
                    <RHDivider className="!opacity-100 !text-gray-200" />
                  </Grid>
                </Grid>
              </>
            )}

            {!controlTypes && (minControlLength || 0) > 0 && (
              <>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={12}
                      className="!mb-[14px] !mt-3"
                    >
                      <Typography className={typographyClasses.rhBaseBody2}>
                        {(count += 1)}. SELECT CONTROL LENGTH
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={6} xs={12}>
                      {ControlLength}
                    </Grid>
                  </Grid>
                  <Grid className="!mt-[37px]">
                    <RHDivider className="!opacity-100 !text-gray-200" />
                  </Grid>
                </Grid>
              </>
            )}

            {bracketShape && (
              <Grid item xs={12} md={12}>
                <Grid container className="!items-center !mb-[5px]">
                  <Grid item md={6} sm={6} xs={10}>
                    <Typography className={typographyClasses.rhBaseBody2}>
                      {(count += 1)}. SELECT BRACKET SHAPE
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={2} className="flex justify-end">
                    <IconButton name="bracketShape" onClick={handleInfoDialog}>
                      <RHInfoIcon className="cursor-pointer" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <ProductOptionDropdown
                      id={`bracket-shape`}
                      label="Bracket Shape"
                      value={selectedBracketShape?.id || ""}
                      options={bracketShape?.options?.map((option, index) => ({
                        key: `bracket-shape-${option.id}${index}`,
                        value: option.id,
                        display: he.decode(option.value || "")
                      }))}
                      onChange={onBracketShapeChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid className="!mb-[37px]">
                  <RHDivider className="!opacity-100 !text-gray-200" />
                </Grid>
              </Grid>
            )}

            {backplateShape && (
              <Grid item xs={12} md={12}>
                <Grid container className="!items-center !mb-[5px]">
                  <Grid item md={6} sm={6} xs={10}>
                    <Typography className={typographyClasses.rhBaseBody2}>
                      {(count += 1)}. SELECT BACKPLATE SHAPE
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={2} className="flex justify-end">
                    <IconButton
                      name="backplateShape"
                      onClick={handleInfoDialog}
                    >
                      <RHInfoIcon className="cursor-pointer" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <ProductOptionDropdown
                      id={`backplate-shape`}
                      label="Backplate Shape"
                      value={selectedBackplateShape?.id || ""}
                      options={backplateShape?.options?.map(
                        (option, index) => ({
                          key: `backplate-shape-${option.id}${index}`,
                          value: option.id,
                          display: he.decode(option.value || "")
                        })
                      )}
                      onChange={onBackplateShapeChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid className="!mt-[37px]">
                  <RHDivider className="!opacity-100 !text-gray-200" />
                </Grid>
              </Grid>
            )}
            {ringType && (
              <Grid item xs={12} md={12}>
                <Grid container className="!items-center !mb-[5px]">
                  <Grid item md={6} sm={6} xs={10}>
                    <Typography className={typographyClasses.rhBaseBody2}>
                      {(count += 1)}. INCLUDE RINGS
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={2} className="flex justify-end">
                    <IconButton name="includeRings" onClick={handleInfoDialog}>
                      <RHInfoIcon className="cursor-pointer" />
                    </IconButton>
                  </Grid>
                </Grid>
                {ringTypeWarning && (
                  <Grid container className="!mb-5">
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        component="span"
                        className="font-primary-baron font-medium text-gray-600 tracking-[0.2px] !leading-[18px] !text-[13px]"
                      >
                        {he.decode(ringTypeWarning)}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <ProductOptionDropdown
                      id={`ring-type`}
                      label="Ring Type"
                      value={selectedRingType?.id || ""}
                      options={ringType?.options
                        ?.filter(ring => !disabledRingType.includes(ring.value))
                        ?.map((option, index) => ({
                          key: `ring-type-${option.id}${index}`,
                          value: option.id,
                          display: he.decode(option.value || "")
                        }))}
                      onChange={onRingTypeChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid className="!mb-[37px]">
                  <RHDivider className="!opacity-100 !text-gray-200" />
                </Grid>
              </Grid>
            )}

            {controlPositions && (
              <Grid item xs={12} md={12}>
                <Grid container className="!items-center !mb-[5px]">
                  <Grid item md={6} sm={6} xs={10}>
                    <Typography className={typographyClasses.rhBaseBody2}>
                      {(count += 1)}. SELECT CONTROL POSITION
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={2} className="flex justify-end">
                    <IconButton
                      name="controlPosition"
                      onClick={handleInfoDialog}
                    >
                      <RHInfoIcon className="cursor-pointer" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <ProductOptionDropdown
                      id={`control-positions`}
                      label="Control Positions"
                      value={selectedControlPosition?.id || ""}
                      options={controlPositions?.map((option, index) => ({
                        key: `control-positions-${option.id}${index}`,
                        value: option.id || "",
                        display: he.decode(option.value || "")
                      }))}
                      onChange={onControlPositionChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid className="!mb-[37px]">
                  <RHDivider className="!opacity-100 !text-gray-200" />
                </Grid>
              </Grid>
            )}

            {memoizedLiningOptions?.length ? (
              <Grid item xs={12} md={12}>
                <Grid container className="!items-center !mb-[5px]">
                  <Grid item md={6} sm={6} xs={10}>
                    <Typography className={typographyClasses.rhBaseBody2}>
                      {(count += 1)}. SELECT LINING
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={2} className="flex justify-end">
                    <IconButton name="linings" onClick={handleInfoDialog}>
                      <RHInfoIcon className="cursor-pointer" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <ProductOptionDropdown
                      id={`lining`}
                      label="Lining"
                      value={selectedLining?.id || ""}
                      options={memoizedLiningOptions?.map((option, index) => ({
                        key: `linings-${option.id}${index}`,
                        value: option.id || "",
                        display: he.decode(option.value || "")
                      }))}
                      onChange={onLiningChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid className="!mb-[37px]">
                  <RHDivider className="!opacity-100 !text-gray-200" />
                </Grid>
              </Grid>
            ) : null}

            {bracketColors && (
              <Grid item xs={12} md={12}>
                <Grid container className="!items-center !mb-[5px]">
                  <Grid item md={6} sm={6} xs={10}>
                    <Typography className={typographyClasses.rhBaseBody2}>
                      {(count += 1)}. SELECT HARDWARE FINISH
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={2} className="flex justify-end">
                    <IconButton
                      name="hardwareFinish"
                      onClick={handleInfoDialog}
                    >
                      <RHInfoIcon className="cursor-pointer" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <ProductOptionDropdown
                      id={`hardware-finish`}
                      label="Hardware Finish"
                      value={selectedBracketColor?.id || ""}
                      options={bracketColors?.map((option, index) => ({
                        key: `hardware-finish-${option.id}${index}`,
                        value: option.id || "",
                        display: he.decode(option.value || "")
                      }))}
                      onChange={onHardwareFinishChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid className="!mt-[37px]">
                  <RHDivider className="!opacity-100 !text-gray-200" />
                </Grid>
              </Grid>
            )}

            {rollTypes && (
              <Grid item xs={12} md={12}>
                <Grid container className="!items-center !mb-[5px]">
                  <Grid item md={6} sm={6} xs={10}>
                    <Typography className={typographyClasses.rhBaseBody2}>
                      {(count += 1)}. SELECT ROLL TYPE
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={2} className="flex justify-end">
                    <IconButton name="rollType" onClick={handleInfoDialog}>
                      <RHInfoIcon className="cursor-pointer" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <ProductOptionDropdown
                      id={`roll-type`}
                      label="Roll Type"
                      value={selectedRollType?.id || ""}
                      options={rollTypes?.map((option, index) => ({
                        key: `roll-type-${option.id}${index}`,
                        value: option.id || "",
                        display: he.decode(option.value || "")
                      }))}
                      onChange={onRollTypeChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid className="!mb-[37px]">
                  <RHDivider className="!opacity-100 !text-gray-200" />
                </Grid>
              </Grid>
            )}

            {tiltTypes && (
              <Grid item xs={12} md={12}>
                <Grid container className="!items-center !mb-[5px]">
                  <Grid item md={6} sm={6} xs={10}>
                    <Typography className={typographyClasses.rhBaseBody2}>
                      {(count += 1)}. SELECT TILT TYPE
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={2} className="flex justify-end">
                    <IconButton name="tiltType" onClick={handleInfoDialog}>
                      <RHInfoIcon className="cursor-pointer" />
                    </IconButton>
                  </Grid>
                  {tiltTypeWarning && (
                    <Grid
                      container
                      style={{ marginBottom: 20 }}
                      className="!mb-5"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          component="span"
                          className="font-primary-baron font-medium text-gray-600 tracking-[0.2px] !leading-[18px] !text-[13px]"
                        >
                          {he.decode(tiltTypeWarning)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <ProductOptionDropdown
                      id={`tilt-type`}
                      label="Tilt Type"
                      value={selectedTiltType?.id || ""}
                      options={tiltTypes
                        ?.filter(
                          option =>
                            !disabledControlType?.includes(option.value || "")
                        )
                        ?.map((option, index) => ({
                          key: `tilt-type-${option.id}${index}`,
                          value: option.id || "",
                          display: he.decode(option.value || "")
                        }))}
                      onChange={onTiltTypeChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid className="!mb-[37px]">
                  <RHDivider className="!opacity-100 !text-gray-200" />
                </Grid>
              </Grid>
            )}

            {controlsAndTilts && (
              <Grid item xs={12} md={12}>
                <Grid container className="!items-center !mb-[5px]">
                  <Grid item md={6} sm={6} xs={10}>
                    <Typography className={typographyClasses.rhBaseBody2}>
                      {(count += 1)}. SELECT CONTROL AND TILT POSITION
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={2} className="flex justify-end">
                    <IconButton name="tiltPosition" onClick={handleInfoDialog}>
                      <RHInfoIcon className="cursor-pointer" />
                    </IconButton>
                  </Grid>
                  {controlTiltPositionWarning && (
                    <Grid container className="!mb-5">
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          component="span"
                          className="font-primary-baron font-medium text-gray-600 tracking-[0.2px] !leading-[18px] !text-[13px]"
                        >
                          {he.decode(controlTiltPositionWarning)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <ProductOptionDropdown
                      id={`controls-and-tilts`}
                      label="Control And Tilt Position"
                      value={selectedControlAndTilt?.id || ""}
                      options={controlsAndTilts
                        ?.filter(
                          option =>
                            !disabledControlAndTiltPositions?.includes(
                              option.value
                            )
                        )
                        ?.map((option, index) => ({
                          key: `controls-and-tilts-${option.id}${index}`,
                          value: option.id || "",
                          display: he.decode(option.value || "")
                        }))}
                      onChange={onControlsAndTiltChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid className="!mt-[37px]">
                  <RHDivider className="!opacity-100 !text-gray-200" />
                </Grid>
              </Grid>
            )}

            {mountTypes && customProductType === "trackHardware" && (
              <Grid item xs={12} md={12}>
                <Grid container className="!items-center !mb-[5px]">
                  <Grid item md={6} sm={6} xs={10}>
                    <Typography className={typographyClasses.rhBaseBody2}>
                      {" "}
                      {(count += 1)}. SELECT MOUNTING TYPE
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={2} className="flex justify-end">
                    <IconButton name="mountType" onClick={handleInfoDialog}>
                      <RHInfoIcon className="cursor-pointer" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <ProductOptionDropdown
                      id={`mount-type`}
                      label="Mount Type"
                      value={selectedMountType?.id || ""}
                      options={mountTypes?.map((option, index) => ({
                        key: `mount-type-${option.id}${index}`,
                        value: option.id || "",
                        display: he.decode(option.value || "")
                      }))}
                      onChange={onMountTypeChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid className="!mt-[37px]">
                  <RHDivider className="!opacity-100 !text-gray-200" />
                </Grid>
              </Grid>
            )}

            {pli?.sku && (
              <Grid item xs={12} md={12}>
                <Grid container className="!items-center !mb-[5px]">
                  <Grid item md={6} sm={6} xs={10}>
                    <Typography className={typographyClasses.rhBaseBody2}>
                      {" "}
                      {(count += 1)}. LABEL THIS WINDOW
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={2} className="flex justify-end">
                    <IconButton name="label" onClick={handleInfoDialog}>
                      <RHInfoIcon className="cursor-pointer" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <ProductOptionDropdown
                      id={`room-label`}
                      label="Room"
                      value={roomLabel}
                      options={
                        pageContent?.roomsList?.map((option, index) => ({
                          key: `room-type-${option}${index}`,
                          value: option,
                          display: option
                        })) || []
                      }
                      onChange={onRoomLabelChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                    <RHTextField
                      id="window-description"
                      label="Window Description"
                      margin="none"
                      fullWidth
                      value={roomLabelDescription}
                      onBlur={onRoomLabelDescriptionBlur}
                      onChange={onRoomLabelDescriptionChange}
                      inputProps={{
                        style: {
                          background: theme?.palette?.background?.default,
                          height: theme.typography.pxToRem(45),
                          boxSizing: "border-box"
                        },
                        maxLength: 21
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid className="!mb-[37px]">
                  <RHDivider className="!opacity-100 !text-gray-200" />
                </Grid>
              </Grid>
            )}
          </Grid>
          {showInfoDialog && (
            <CustomProductInfoDialog
              open={showInfoDialog}
              dialogType={dialogType}
              customProductType={customProductType || ""}
              onClose={handleInfoDialogClose}
            />
          )}
        </>
      )}
    </>
  );
};

export default memoize(CustomProductCard);
