import React from "react";
import { Typography } from "utils/material-ui-core";

import classNames from "classnames";
import stripLineBreaks from "utils/stripLineBreaks";
import memoize from "utils/memoize";

const Title = memoize(({ data, smUp, classes, mdUp, isLandscapeFull }) => {
  if (data) {
    return (
      <>
        <div className={classNames([classes?.bannerTitleWrapper])}>
          <Typography
            id="bannerTitleCG"
            data-testid={"collectionCardDetails-Title"}
            className={classNames([
              classes?.bannerTitleCG,
              mdUp && isLandscapeFull ? "" : classes.headerTextColor
            ])}
            dangerouslySetInnerHTML={{
              __html:
                (smUp ? data.bannerTitle : stripLineBreaks(data.bannerTitle)) ||
                ""
            }}
          />
        </div>
        {data.bannerSubTitle && (
          <div>
            <Typography
              id="CGSubtitle"
              className={classNames([classes?.CGSubtitle])}
              dangerouslySetInnerHTML={{
                __html: data.bannerSubTitle || ""
              }}
            />
          </div>
        )}
      </>
    );
  }
  return <></>;
});

export default Title;
