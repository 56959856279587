import React, { useMemo } from "react";
import {
  Typography,
  makeStyles,
  createStyles,
  Theme
} from "utils/material-ui-core";

import classNames from "classnames";
import memoize from "utils/memoize";
import { formatCurrencyPrice } from "component-rh-price-range-display/helpers";
import { useRhUserAtomValue } from "hooks/atoms";

import he from "he";
import { SALE_PERCENT_100 } from "utils/constants";

const useStyle = makeStyles((theme: Theme) => {
  return createStyles({
    priceInfoLine: {
      "& > .priceInfoMember": {
        marginLeft: "4px"
      },
      "& > .priceInfoRegular": {
        fontWeight: 100,
        fontFamily: "RHSans-Thin"
      },
      ".cg-slide-4 &": {
        paddingBottom: "12px"
      },
      paddingBottom: "24px",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "12px"
      }
    },
    priceInfoSale: {
      color: "#CA2022"
    }
  });
});

const PriceInfo = memoize(
  ({
    priceInfo,
    pageContent,
    saleMessageEnabled,
    saleStats,
    saleSwatchMatch
  }) => {
    const { userType } = useRhUserAtomValue();
    const styledClass = useStyle();
    const {
      listPrice,
      memberPrice,
      memberOriginalPrice,
      onClearance,
      priceCopy,
      tradePrice,
      contractPrice
    } = priceInfo;

    let priceMessage = "";
    if (priceCopy) {
      // DO NOT REMOVE SPACE BEFORE lowestPrice, SR-1826
      priceMessage = priceCopy.replace(" lowestPrice", "");
    }

    let displayedMemberPrice = memberPrice;

    if (
      saleMessageEnabled &&
      saleStats?.percentSaleSkus > 0 &&
      saleStats?.percentSaleSkus < 100 &&
      onClearance
    ) {
      displayedMemberPrice = memberOriginalPrice;
    }

    if (saleSwatchMatch) {
      displayedMemberPrice = memberPrice;
    }

    const getUserTypePrice = useMemo(() => {
      const type = userType?.toLowerCase();
      switch (type) {
        case "trade":
          return formatCurrencyPrice(tradePrice);
        case "contract":
          return formatCurrencyPrice(contractPrice);
        default:
          return formatCurrencyPrice(displayedMemberPrice);
      }
    }, [contractPrice, displayedMemberPrice, tradePrice, userType]);

    const getUserTypeLabel = useMemo(() => {
      switch (userType?.toLowerCase()) {
        case "trade":
          return "TRADE";

        case "contract":
          return "CONTRACT";

        default:
          return "MEMBER";
      }
    }, [userType]);

    return (
      <div
        data-testid={"collectionCardDetails-PriceInfo"}
        className="mt-[12px]"
      >
        <Typography
          className={classNames([
            "!font-primary-light !text-[12px] !leading-[120%] !tracking-[0.12px] !uppercase !font-[300]",
            saleStats?.percentSaleSkus === SALE_PERCENT_100
              ? " !text-[#CA2022]"
              : "!text-black",
            styledClass.priceInfoLine,
            {
              [styledClass.priceInfoSale]:
                saleSwatchMatch || saleStats?.percentSaleSkus >= 100
            }
          ])}
        >
          {priceMessage ? (
            <span
              dangerouslySetInnerHTML={{
                __html: he.decode(priceMessage ?? "")
              }}
            />
          ) : (
            pageContent?.STARTING_AT
          )}
          <span className="priceInfoMember">
            {getUserTypePrice}
            {` ${pageContent?.[getUserTypeLabel] || ""}`}
          </span>
          <span className="priceInfoRegular">
            {" / "}
            {formatCurrencyPrice(listPrice)} {pageContent?.REGULAR || ""}
          </span>
        </Typography>
      </div>
    );
  }
);

export default PriceInfo;
