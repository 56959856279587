import React, { FC, useState, useEffect, useCallback } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  FormControlLabel,
  Checkbox,
  useMediaQuery
} from "utils/material-ui-core";
import { Hidden, processEnvServer } from "hooks/useSsrHooks";
import RHLink from "component-rh-link";
import {
  BREAKPOINT_SM,
  BREAKPOINT_MD,
  BREAKPOINT_LG,
  IMAGE_ASPECT_RATIO
} from "utils/constants";
import memoize from "utils/memoize";
import RHImage from "component-rh-image";
import ProductOptionDropdown from "component-product-option-dropdown";
import { sortBy } from "lodash";
import he from "he";
import useTypographyStyles from "hooks/useTypographyStyles";
import classNames from "classnames";
import { useEnv } from "hooks/useEnv";
import RHPriceRangeDisplay from "component-rh-price-range-display";
import { usePageContent } from "customProviders/LocationProvider";
import analyticsLoader from "analytics/loader";
import yn from "yn";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { countryNameMapper } from "resources/countries-config.json";
import { useUserSessionAtomValue } from "hooks/atoms";
import RHRPriceDisplay from "@RHCommerceDev/component-rh-price-range-display/RHRPriceDisplay";
import { getPriceUserType } from "component-product-grid";
import template from "lodash/template";
import useGetPDPRedirectPath from "hooks/useGetPDPRedirectPath";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        marginTop: "32px",
        marginBottom: "24px"
      }
    },
    label: {
      color: "#000000",
      textDecoration: "underline"
    },
    priceLabel: {
      textDecoration: "none"
    },
    imageWrapper: {
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        maxWidth: "100px"
      }
    },
    optionGrid: {
      paddingLeft: "30px",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        paddingLeft: "18px"
      },
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        maxWidth: "100%"
      }
    },
    qtyContainer: {
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        paddingLeft: "10px",
        paddingRight: "10px"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        paddingLeft: "5px",
        paddingRight: "unset"
      }
    },
    productAddonCheckbox: {
      height: theme.typography.pxToRem(29),
      width: theme.typography.pxToRem(28),
      color: "#666666",
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        paddingLeft: "30px"
      }
    },
    optionContainer: {
      paddingTop: "24px"
    },
    longDesc: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical"
    },
    addOnMessaging: {
      gap: "4px",
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        padding: "0px 12px 0px"
      },
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        padding: "0px 0px 8px 0px"
      }
    }
  })
);

export interface AddOnConfigModes {
  mainItemOptionsChanged?: boolean;
  quantityChanged?: boolean;
  panelProdComputation?: boolean;
}

export interface AddOnDataType {
  productSelected?: boolean;
  productId?: Maybe<string>;
  selectedOptions?: ProductAvailableOption[];
  optionsLength?: number;
  qty?: number;
  fullSkuId?: string;
  spo?: Maybe<boolean>;
  preBillMessage?: Maybe<string>;
  pricing?: Maybe<ProductSkuPriceInfo>;
  itemOptions?: ProductAvailableOptionSet[];
  maxQuantity?: Maybe<number>;
  mainItemProductId?: string;
  // addonAgreed?: boolean;
  colorizedImgURL?: Maybe<string>;
}

export interface AddOnCheckBoxProps {
  selectedIds?: ProductAvailableOption[];
  data?: ProductAddonsInfo;
  opts?: Maybe<ProductAvailableOptionSet[]>;
  imgURL?: Maybe<string>;
  loading?: boolean;
  mainProductQty?: number;
  handleAddonData?: (
    addOnData: AddOnDataType,
    addOnConfigModes: AddOnConfigModes
  ) => void;
  addOnData?: AddOnDataType;
  productLineItemOptions?: ProductAvailableOptionSet[];
}

export const AddOnCheckBox: FC<AddOnCheckBoxProps> = memoize(
  ({
    data,
    opts,
    imgURL,
    loading,
    mainProductQty,
    handleAddonData,
    handleAddToCartAddonData,
    addOnData,
    productLineItemOptions,
    productAddonsPricing
  }) => {
    const env = useEnv();
    const country = getCountryFromUrl();
    const currencyCode = countryNameMapper[country];
    const isFeatureAddOnMessaging = yn(env.FEATURE_ADDON_MESSAGING);
    const { pageContent } = usePageContent();
    const PDP_DEFAULT_QTY = Number(env.FEATURE_PDP_DEFAULT_QUANTITY) || 0;
    const classes = useStyles();
    const { rhUser } = useUserSessionAtomValue();
    const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const lgUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("lg"));
    const mdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("md"));
    const lgDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("lg"));
    const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));

    const typographyStyles = useTypographyStyles({
      keys: [
        "rhBaseBody2",
        "rhBaseBody3",
        "rhBaseBody1",
        "rhBaseCaption",
        "rhBaseH3",
        "rhBaseH2",
        "uppercaseText"
      ]
    });
    const [isChecked, setIsChecked] = useState(false);
    const getQtyValue = env.FEATURE_PDP_DEFAULT_QUANTITY ? PDP_DEFAULT_QTY : 1;
    const [qty, setQty] = useState(PDP_DEFAULT_QTY);
    const [shortDesc, setShortDesc] = useState<string>("");
    const [itemOptions, setItemOptions] = useState<
      Array<ProductAvailableOptionSet>
    >([]);

    const [selectedOptions, setSelectedOptions] = useState<
      ProductAvailableOption[]
    >([]);

    const [preselectedOptions, setPreselectedOptions] = useState<
      ProductAvailableOption[]
    >([]);

    //workaround to restrict checkbox drodowns updating when panel dropdowns are updating
    useEffect(() => {
      setItemOptions(opts);
    }, []);

    useEffect(() => {
      if (isChecked) {
        analyticsLoader(a =>
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.ADD_ON_CHECKBOX.INT_TYPE,
            {
              productId: data?.id,
              productAddonTitle: data?.displayName,
              fullskuid: addOnData?.fullskuid
            }
          )
        );
      }
    }, [isChecked]);

    useEffect(() => {
      if (isChecked && Number(mainProductQty) === 0) {
        setIsChecked(false);
      }
    }, [isChecked, mainProductQty]);

    useEffect(() => {
      const preSelectedOpts: Array<ProductAvailableOption> = [
        ...getPreSelectedOption(opts)
          ?.flat()
          ?.filter(opt => opt)
      ];
      setPreselectedOptions(prevPreselection =>
        JSON.stringify(preSelectedOpts.sort()) !==
        JSON.stringify(prevPreselection.sort())
          ? preSelectedOpts
          : prevPreselection
      );
    }, [productLineItemOptions]);

    useEffect(() => {
      handleAddonData(
        {
          productId: data?.id,
          productSelected: isChecked,
          selectedOptions: selectedOptions,
          optionsLength: opts?.length,
          qty: qty ? qty : 1
        },
        {
          panelProdComputation: false,
          mainItemOptionsChanged: true
        }
      );
    }, [preselectedOptions]);

    const setOptionsVisibility = useCallback(
      optionsSet =>
        productLineItemOptions?.map(optionSet => {
          const matchingOptionSet = optionsSet?.find(
            secondOptionSet =>
              secondOptionSet.optionTypeId === optionSet.optionTypeId
          );

          const matchingOptionIds = optionSet?.options
            .filter(option => option.status === "selected")
            .map(option => option.id);

          const isOptionSetVisible =
            (matchingOptionSet &&
              matchingOptionSet.options.some(option =>
                matchingOptionIds.includes(option.id)
              )) ||
            false;

          return {
            optionTypeId: optionSet.optionTypeId,
            optionType: optionSet.type,
            isOptionSetVisible,
            matchedOptionIds: matchingOptionIds
          };
        }),
      [productLineItemOptions]
    );

    const getPreSelectedOption = useCallback(
      optionsSet =>
        productLineItemOptions
          .map(optionSet => {
            const matchingOptionSet = optionsSet?.find(
              secondOptionSet =>
                secondOptionSet.optionTypeId === optionSet.optionTypeId
            );
            const matchingOptionIds = optionSet?.options
              ?.filter(option => option.status === "selected")
              .map(option => option.id);
            return matchingOptionSet?.options.filter(option =>
              matchingOptionIds.includes(option.id)
            );
          })
          .filter(opt => opt !== undefined),
      [productLineItemOptions]
    );

    const getSelectedIds = selectedOptionsSet => {
      const uniqueOptionIds = new Set();
      selectedOptionsSet?.forEach(obj => {
        obj.matchedOptionIds.forEach(optionId => {
          uniqueOptionIds.add(optionId);
        });
      });

      const uniqueOptionIdsArray = Array.from(uniqueOptionIds);
      return uniqueOptionIdsArray;
    };

    const handleCheckbox = (event, productId) => {
      const selectedOptionsSet = setOptionsVisibility(opts)?.filter(
        opt => opt.isOptionSetVisible
      );
      const selectedOptionIds = getSelectedIds(selectedOptionsSet);

      const singularPreSelectedOptions = opts
        ?.map(opt => opt?.options?.find(opt => opt?.status === "selected"))
        .filter(opt => opt) as ProductAvailableOption[];
      handleAddonData(
        {
          productSelected: event.target.checked,
          productId: productId,
          optionsLength: opts?.length,
          qty: qty ? qty : 1,
          selectedOptions: selectedOptionIds?.length
            ? selectedOptionIds?.map(id => ({ id }))
            : singularPreSelectedOptions
        },
        {
          panelProdComputation: false,
          mainItemOptionsChanged: false
        }
      );

      setIsChecked(!isChecked);
      setQty(!isChecked ? 1 : 0);
    };

    useEffect(() => {
      const selectedOpts =
        opts
          ?.map(
            availableOption =>
              availableOption.options.find(
                option => option.status === "selected"
              ) as ProductAvailableOption
          )
          ?.filter(availableOption => !!availableOption) ?? [];

      let updateSelectedOpts = selectedOpts;
      if (selectedOptions.length) {
        updateSelectedOpts = [...selectedOpts, ...selectedOptions].reduce(
          (acc, currentItem) => {
            const existingItemIndex = acc.findIndex(
              item => item.type === currentItem.type
            );
            if (existingItemIndex === -1) {
              acc.push(currentItem);
            }
            return acc;
          },
          []
        );
      }
      setSelectedOptions(updateSelectedOpts);
    }, [opts]);

    //handler for select option
    const onAddOnConfigChange = useCallback(
      (
        value?: string,
        options?: ProductAvailableOption[],
        productId?: string
      ) => {
        const selectedOption = options?.find(option => option.id === value);
        const selectedOpts = [
          ...selectedOptions?.filter(
            selectedOption =>
              !options?.find(option => option.id === selectedOption.id)
          ),
          ...(selectedOption ? [selectedOption] : [])
        ];
        selectedOpts?.length > 0 ? setIsChecked(true) : setIsChecked(false);

        const finalSelectedOptions = selectedOpts?.length
          ? selectedOpts
          : getPreSelectedOption(opts).flat();

        setSelectedOptions(finalSelectedOptions);

        handleAddonData(
          {
            productId: productId,
            productSelected: true,
            selectedOptions: finalSelectedOptions,
            optionsLength: opts?.length,
            qty: qty ? qty : 1
          },
          {
            panelProdComputation: false
          }
        );

        if (finalSelectedOptions?.length === 0) {
          setQty(0);
          setIsChecked(false);
          return;
        }
      },

      [opts, selectedOptions, qty, handleAddonData, handleAddToCartAddonData]
    );

    const handleQuantitySelected = useCallback(
      (quantity: number) => {
        setQty(quantity);
        handleAddonData(
          {
            productId: data?.id,
            productSelected: quantity === 0 ? false : isChecked,
            selectedOptions: quantity === 0 ? [] : selectedOptions,
            optionsLength: opts?.length,
            qty: quantity
          },
          {
            panelProdComputation: false
          }
        );
        if (quantity === 0) setIsChecked(false);
      },
      [addOnData, selectedOptions, opts, handleAddonData]
    );

    const redirectPath = useGetPDPRedirectPath(data);

    useEffect(() => {
      if (
        addOnData &&
        addOnData.maxQuantity !== undefined &&
        qty > addOnData.maxQuantity
      ) {
        const newQuantity = addOnData?.maxQuantity;
        setQty(newQuantity);
        handleQuantitySelected(newQuantity);
      }
    }, [addOnData, qty]);

    useEffect(() => {
      const lengthAccordScreen = xsDown
        ? 55
        : smDown
        ? 142
        : mdDown
        ? 135
        : lgDown
        ? 170
        : 180;
      const shortDesc = (
        data?.longDescription?.length > lengthAccordScreen
          ? data?.longDescription?.substring(0, lengthAccordScreen) +
            "... " +
            he.decode(
              `<a target="_blank" href="${redirectPath}" title="Delivery Dimensions" style="color: #666; font-weight: 500;" >${pageContent?.MORE} </a>`
            )
          : this
      ) as string;
      return setShortDesc(shortDesc);
    }, [data, xsDown, smDown, mdDown, lgDown]);

    const shouldShowSaleText =
      (Number(data?.saleInfo?.percentSaleSkus) ?? 0) > 0;

    const selectItemsOnSale =
      Number(data?.saleInfo?.percentSaleSkus) > 0 &&
      Number(data?.saleInfo?.percentSaleSkus) < 100;

    const salePercentageRangeExists =
      data?.saleInfo?.memberSavings?.memberSavingsMax !==
      data?.saleInfo?.memberSavings?.memberSavingsMin;

    const SAVE_ON_SELECT_ITEMS = template(pageContent?.SAVE_ON_SELECT_ITEMS)({
      minPercent: data?.saleInfo?.memberSavings?.memberSavingsMin,
      maxPercent: data?.saleInfo?.memberSavings?.memberSavingsMax
    });

    const SAVE_UP_TO_SELECT_ITEMS = template(
      pageContent?.SAVE_MIN_PERCENT_ON_SELECT_ITEMS
    )({
      minPercent: data?.saleInfo?.memberSavings?.memberSavingsMin
    });

    const saleMessageForPriceComponent =
      shouldShowSaleText && selectItemsOnSale
        ? salePercentageRangeExists
          ? SAVE_ON_SELECT_ITEMS
          : SAVE_UP_TO_SELECT_ITEMS
        : "";

    return (
      <>
        <Grid
          container
          item
          xs={12}
          spacing={mdUp ? 3 : lgUp ? 4 : 0}
          className={classes?.container}
        >
          {isFeatureAddOnMessaging && (
            <Grid container className={classes?.addOnMessaging}>
              <Grid container>
                <Typography
                  style={{
                    fontFamily: "RHSans-Light",
                    fontWeight: 300,
                    letterSpacing: "0.52px"
                  }}
                  className={classNames([
                    typographyStyles?.rhBaseBody2,
                    typographyStyles?.uppercaseText
                  ])}
                  dangerouslySetInnerHTML={{
                    __html: `${data?.productAddonTitle} `
                  }}
                />
              </Grid>
              <Grid container>
                <Typography
                  style={{
                    fontFamily: "RHSans-Light",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: 300,
                    lineHeight: "150%",
                    letterSpacing: "0.195px"
                  }}
                  className={typographyStyles?.rhBaseBody2}
                  dangerouslySetInnerHTML={{
                    __html: `${data?.productAddonMessage} `
                  }}
                />
              </Grid>
            </Grid>
          )}
          <Grid
            item
            xs={3}
            spacing={2}
            md={2}
            className={classes?.imageWrapper}
          >
            <RHLink
              data-testid={`productImageLink-${data?.id}`}
              to={redirectPath}
              target="_blank"
            >
              <RHImage
                src={addOnData?.colorizedImgURL ?? imgURL}
                alt={data?.displayName}
                objectFit={"contain"}
                preset="mobileThumb"
                aspectRatio={IMAGE_ASPECT_RATIO["thumbnail"]}
                imageWrapperStyles={
                  mdUp
                    ? {
                        backgroundColor: "white",
                        width: "75%",
                        height: "auto"
                      }
                    : {
                        backgroundColor: "white"
                      }
                }
                imageSkeletonBackgroundColor="white"
              />
            </RHLink>
          </Grid>
          <Grid item spacing={2} xs={9} md={6} className={classes?.optionGrid}>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="productaddon-checkbox"
                  checked={Boolean(isChecked)}
                  onChange={e => handleCheckbox(e, data?.id)}
                  className={classes?.productAddonCheckbox}
                  style={{ cursor: !loading ? "pointer" : "default" }}
                />
              }
              label={
                <Grid container direction="column">
                  <Grid item className="flex">
                    <Typography
                      item
                      className={classNames([
                        typographyStyles?.rhBaseBody2,
                        "whitespace-nowrap"
                      ])}
                      style={{ paddingRight: 4 }}
                    >
                      {pageContent?.ADD}
                    </Typography>
                    <RHLink
                      data-testid={`productImageLink-${data?.id}`}
                      to={redirectPath}
                      target="_blank"
                    >
                      <Grid
                        item
                        className={classNames([classes?.label])}
                        style={{ paddingRight: 4 }}
                      >
                        <Typography
                          className={typographyStyles?.rhBaseBody2}
                          dangerouslySetInnerHTML={{
                            __html: `${data?.displayName} `
                          }}
                        />
                      </Grid>
                    </RHLink>
                  </Grid>
                  <Grid
                    item
                    className={classNames([
                      classes?.priceLabel,
                      typographyStyles?.rhBaseBody2
                    ])}
                  >
                    {!(addOnData?.itemOptions ?? itemOptions)?.length ? (
                      <>
                        <RHPriceRangeDisplay
                          data={data?.priceRangeDisplay}
                          topLabel={pageContent?.CONFIGURES_PRICE}
                          display={"inline"}
                          variant={"medium"}
                          memberPrice={
                            data?.priceRangeDisplay?.priceRange
                              ?.memberLowestListPrice
                          }
                          isMetaProduct={data?.metaProduct!}
                          nextGenDrivenOnSale={
                            data?.priceRangeDisplay?.nextGenDrivenOnSale!
                          }
                          skuOnSalePercentage={parseInt(
                            data?.saleInfo?.percentSaleSkus!
                          )}
                          currency={currencyCode}
                        />
                      </>
                    ) : selectedOptions.length === opts.length &&
                      addOnData?.pricing ? (
                      <>
                        <RHRPriceDisplay
                          listPrice={addOnData?.pricing?.listPrice!}
                          memberPrice={
                            getPriceUserType(
                              rhUser?.userType!,
                              addOnData?.pricing
                            )!
                          }
                          topLabel={pageContent?.CONFIGURES_PRICE}
                          isSaleFilterEnabled={false}
                          onSale={
                            true
                              ? !!addOnData?.pricing?.salePrice
                              : addOnData?.pricing?.onSale!
                          }
                          skulowestMemberPrice={
                            getPriceUserType(
                              rhUser?.userType!,
                              addOnData?.pricing
                            )!
                          }
                          showSaleMessage={false}
                          userType={rhUser?.userType! || ""}
                          pageContent={pageContent}
                          variant={"medium"}
                          centerAlignFlag={false}
                          hideCss={true}
                          showMembershipProductPrice={
                            data?.uxAttributes?.membershipProduct?.toLowerCase() ===
                            "true"
                          }
                          showGiftCardPrice={
                            data?.uxAttributes?.giftCert?.toLowerCase() ===
                            "true"
                          }
                        />
                      </>
                    ) : (
                      <>
                        <RHRPriceDisplay
                          listPrice={data?.priceRangeDisplay?.listPrices?.[0]!}
                          memberPrice={
                            data?.priceRangeDisplay?.memberPrices?.[0]!
                          }
                          topLabel={
                            data.priceRangeDisplay?.overridePriceLabel ||
                            pageContent?.["STARTING_AT"]
                          }
                          isSaleFilterEnabled={false}
                          onSale={data?.priceRangeDisplay?.nextGenDrivenOnSale!}
                          skulowestMemberPrice={
                            data?.priceRangeDisplay?.skulowestMemberPrice!
                          }
                          showSaleMessage={
                            Number(data?.saleInfo?.percentSaleSkus) === 0 ||
                            Number(data?.saleInfo?.percentSaleSkus) === 100
                              ? false
                              : true
                          }
                          userType={rhUser?.userType! || ""}
                          pageContent={pageContent}
                          computedSalePageContent={saleMessageForPriceComponent}
                          variant={"medium"}
                          centerAlignFlag={false}
                          showMembershipProductPrice={
                            data?.uxAttributes?.membershipProduct?.toLowerCase() ===
                            "true"
                          }
                          showGiftCardPrice={
                            data?.uxAttributes?.giftCert?.toLowerCase() ===
                            "true"
                          }
                          hideCss={true}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              }
              style={{ cursor: !loading ? "pointer" : "default" }}
            />
            <Grid xs={12} md={11} className={classes?.optionContainer}>
              {!!opts?.length ? (
                <Grid item xs={smDown ? 12 : 6} md={9}>
                  {(!!addOnData?.itemOptions?.length
                    ? addOnData?.itemOptions
                    : !processEnvServer
                    ? itemOptions
                    : opts
                  )?.map((availableOption, index) => {
                    let options = availableOption.options?.filter(
                      option => option.status !== "unavailable"
                    );

                    if (availableOption.type === "Color") {
                      options = sortBy(options, o => o.value);
                    }

                    const selected =
                      options.find(option => option.status === "selected") ||
                      ({} as ProductAvailableOption);

                    // SR-2241 -Product Addon unsuppress matching line item dropdowns
                    // const optionsVisibility = setOptionsVisibility(opts);
                    // const hideOptionsSet = optionsVisibility.some(
                    //   item =>
                    //     item.optionTypeId === availableOption.optionTypeId &&
                    //     item.isOptionSetVisible
                    // );
                    // if (hideOptionsSet) {
                    //   return null;
                    // }

                    return (
                      <>
                        <Grid
                          key={`availableOption-${index}`}
                          item
                          xs={12}
                          style={{ marginBottom: 16 }}
                        >
                          <ProductOptionDropdown
                            loading={loading}
                            id={`optionSelect-${data?.id}-${availableOption.type}`}
                            label={availableOption.type}
                            disabled={
                              options.length < 1 &&
                              selected?.status === "selected"
                            }
                            options={options.map(option => ({
                              key: `${availableOption.type}-${option.id}`,
                              value: option.id,
                              display: he.decode(option.value)
                            }))}
                            value={selected?.id || ""}
                            onChange={value => {
                              onAddOnConfigChange(value, options, data?.id);
                            }}
                            fullWidth
                            isChecked={isChecked}
                          />
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              ) : (
                <Hidden smDown>
                  <Grid item spacing={2} style={{ paddingBottom: 18 }}>
                    <Typography
                      noWrap={false}
                      paragraph={false}
                      variant="body2"
                      style={{ color: "#666666" }}
                      dangerouslySetInnerHTML={{
                        __html: he.decode(shortDesc ?? "")
                      }}
                    />
                  </Grid>
                </Hidden>
              )}
              {!processEnvServer && (
                <Hidden mdUp>
                  <Grid item xs={6}>
                    <Grid item xs={12} md={4} className={classes?.qtyContainer}>
                      <ProductOptionDropdown
                        loading={loading}
                        id={`${data?.id}-qty-input`}
                        label="QTY"
                        options={Array.from({
                          length:
                            (yn(env?.FEATURE_USE_QUANTITY)
                              ? (addOnData?.maxQuantity ??
                                  Number(getQtyValue)) > 40
                                ? 40
                                : Number(addOnData?.maxQuantity) <= 40
                                ? Number(addOnData?.maxQuantity)
                                : Number(getQtyValue)
                              : Number(getQtyValue)) + 1
                        }).map((item, index) => ({
                          key: `qty-${index}`,
                          value: `${index}`,
                          display: index
                        }))}
                        value={qty.toString()}
                        onChange={value => {
                          handleQuantitySelected(Number(value));
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Hidden>
              )}
            </Grid>
          </Grid>
          {!processEnvServer ? (
            <Hidden smDown>
              <Grid item xs={6} md={4}>
                <Grid item xs={12} md={4} className={classes?.qtyContainer}>
                  <ProductOptionDropdown
                    loading={loading || !isChecked}
                    id={`${data?.id}-qty-input`}
                    label="QTY"
                    options={Array.from({
                      length:
                        (yn(env?.FEATURE_USE_QUANTITY)
                          ? (addOnData?.maxQuantity ?? Number(getQtyValue)) > 40
                            ? 40
                            : Number(addOnData?.maxQuantity) <= 40
                            ? Number(addOnData?.maxQuantity)
                            : Number(getQtyValue)
                          : Number(getQtyValue)) + 1
                    }).map((item, index) => ({
                      key: `qty-${index}`,
                      value: `${index}`,
                      display: index
                    }))}
                    value={qty.toString()}
                    onChange={value => {
                      handleQuantitySelected(Number(value));
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Hidden>
          ) : (
            <Grid item xs={6} md={4}>
              <Grid item xs={12} md={4} className={classes?.qtyContainer}>
                <ProductOptionDropdown
                  loading={loading || !isChecked}
                  id={`${data?.id}-qty-input`}
                  label="QTY"
                  options={Array.from({
                    length:
                      (yn(env?.FEATURE_USE_QUANTITY)
                        ? (addOnData?.maxQuantity ?? Number(getQtyValue)) > 40
                          ? 40
                          : Number(addOnData?.maxQuantity) <= 40
                          ? Number(addOnData?.maxQuantity)
                          : Number(getQtyValue)
                        : Number(getQtyValue)) + 1
                  }).map((item, index) => ({
                    key: `qty-${index}`,
                    value: `${index}`,
                    display: index
                  }))}
                  value={qty.toString()}
                  onChange={value => {
                    handleQuantitySelected(Number(value));
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
);

export default AddOnCheckBox;
