import {
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  ClickAwayListener
} from "@material-ui/core";
import { SwatchesConfigurator } from "@RHCommerceDev/component-swatches-configurator";
import classNames from "classnames";
import React, { FC, useEffect, useRef, useState } from "react";
import memoize from "utils/memoize";
import { EXCLUDED_PRODUCT_CONFIGURATOR_OPTION_SWATCH_UNAVAILABLE_HIDE } from "@RHCommerceDev/utils/constants";
import { RHPriceRangeDisplay } from "component-rh-price-range-display";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    swatchGroupName: {
      color: "var(--ReimagineWarm-RH-Black, #000)",
      fontFamily: "RHSans-Light",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "120%",
      letterSpacing: "0.52px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "200px",
      cursor: "pointer"
    },
    swatchGroupNameV3: {
      color: "var(--ReimagineWarm-RH-Black, #000)",
      fontFamily: "RHSans-Light",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "120%",
      letterSpacing: "0.52px",
      // whiteSpace: "nowrap",
      overflow: "hidden"
    },
    swatchGroupPrice: {
      marginTop: "4px",
      color: "var(--ReimagineWarm-RH-Black, #000)",
      fontFamily: "RHSans-Light",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "120%",
      letterSpacing: "0.44px"
    },
    saleSwatchGroup: {
      color: "#CA2022 !important"
    },
    regularPrice: {
      color: "var(--ReimagineWarm-RH-Darker, #201F1F)",
      fontWeight: 100
    },
    FabricGrid: {
      [theme.breakpoints.down("xl")]: {
        paddingTop: "24px"
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "32px"
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: "24px"
      }
    },
    oddItem: {
      [theme.breakpoints.down("sm")]: {
        paddingRight: "16px"
      },
      [theme.breakpoints.down("xs")]: {
        paddingRight: "0px"
      }
    },
    evenItem: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "16px"
      },
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "0px"
      }
    }
    // salePriceGroupStyle:{
    //   fontFamily:"RHSans-Light",
    //   fontSize:"13px",
    //   lineHeight:"15.6px",
    //   letterSpacing:"0.52px",
    //   color: "var(--ReimagineWarm-RH-Darker, #ca2022)",
    // }
  });
});

interface FabricConfiguratorProps {
  data: Product;
  handleSelection: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selection?: any | null;
  checked?: boolean;
  availableSwatchGroups: any;
  swatchGroupMaterial: any;
}
interface FabricSelectorProps
  extends Omit<FabricConfiguratorProps, "availableSwatchGroups"> {
  swatchGroup: any;
  index: number;
  isSwatchClicked?: any;
  setIsSwatchClicked?: any;
  openStatus?: any;
  setOpenStatus?: any;
  activeIndex?: any;
  setActiveIndex?: React.Dispatch<React.SetStateAction<any>>;
}
const getFilteredSwatches = (swatches, swatchGroupMaterial) => {
  if (
    EXCLUDED_PRODUCT_CONFIGURATOR_OPTION_SWATCH_UNAVAILABLE_HIDE?.includes(
      swatchGroupMaterial?.toString()?.toLowerCase()
    )
  ) {
    return swatches?.filter(swatch => swatch?.status !== "unavailable");
  } else {
    return swatches;
  }
};

export const FabricConfigurator: FC<FabricConfiguratorProps> = memoize(
  ({
    data,
    handleSelection,
    selection,
    checked,
    availableSwatchGroups,
    swatchGroupMaterial
  }) => {
    const [isSwatchClicked, setIsSwatchClicked] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);

    const [openStatus, setOpenStatus] = useState({});
    const handleClosePopper = () => {
      setOpenStatus({});
    };
    return (
      <ClickAwayListener onClickAway={handleClosePopper}>
        <div id="fabric-selector-wrapper" data-testid="fabric-selector-wrapper">
          <Grid
            container
            id="fabric-selector-container"
            data-testid="fabric-selector-container"
          >
            {availableSwatchGroups?.map((swatchGroup, index) => {
              return (
                <FabricSelector
                  swatchGroup={swatchGroup}
                  index={index}
                  selection={selection}
                  data={data}
                  checked={checked}
                  handleSelection={handleSelection}
                  isSwatchClicked={isSwatchClicked}
                  setIsSwatchClicked={setIsSwatchClicked}
                  openStatus={openStatus}
                  setOpenStatus={setOpenStatus}
                  swatchGroupMaterial={swatchGroupMaterial}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                />
              );
            })}
          </Grid>
        </div>
      </ClickAwayListener>
    );
  }
);

const FabricSelector: FC<FabricSelectorProps> = ({
  swatchGroup,
  index,
  selection,
  data,
  checked,
  handleSelection,
  isSwatchClicked,
  setIsSwatchClicked,
  openStatus,
  setOpenStatus,
  swatchGroupMaterial,
  activeIndex,
  setActiveIndex
}) => {
  const isAllSwatchesOnSale =
    swatchGroup?.swatches &&
    swatchGroup?.swatches.every(swatch => swatch.onSale);
  const [isOverflowed, setIsOverflow] = useState<boolean>(false);
  const textElementRef = useRef<HTMLInputElement | null>(null);
  const classes = useStyles();
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
  const xsUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("xs"));

  useEffect(() => {
    if (textElementRef.current) {
      setIsOverflow(
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth
      );
    }
  }, [data, selection]);

  const filteredSwatches = getFilteredSwatches(
    swatchGroup?.swatches,
    swatchGroupMaterial
  );

  const getSwatchPriceRange = swatches => {
    const selectedSwatch = swatches?.find(
      swatch => swatch?.status === "selected"
    );

    if (selectedSwatch) {
      return selectedSwatch?.swatchFamilyPrices;
    }

    const firstSwatchFamilyPrices = swatches?.find(
      swatch => Object?.keys(swatch?.swatchFamilyPrices || {})?.length > 0
    );

    if (!firstSwatchFamilyPrices) {
      return;
    }

    return firstSwatchFamilyPrices?.swatchFamilyPrices;
    // const priceRange = {
    //   ...firstSwatchFamilyPrices?.swatchFamilyPrices,
    //   ...Object.keys(firstSwatchFamilyPrices?.swatchFamilyPrices || {})?.reduce(
    //     (prices, key) => {
    //       if (key?.includes("Prices")) {
    //         console.log('prices: ', {prices, key, swatches})
    //         return {
    //           ...prices,
    //           [key]: [
    //             minBy(
    //               getPriceFromSwatchFamilyPrice(swatches, key),
    //               (price: any) => {
    //                 return price;
    //               }
    //             ),
    //             maxBy(
    //               getPriceFromSwatchFamilyPrice(swatches, key),
    //               (price: any) => {
    //                 return price;
    //               }
    //             )
    //           ]
    //         };
    //       } else {
    //         return prices;
    //       }
    //     },
    //     {}
    //   )
    // };

    // return selectedSwatch ? selectedSwatch?.swatchFamilyPrices : priceRange;
  };

  return (
    <>
      {filteredSwatches?.length ? (
        <Grid
          container
          item
          data-testid="rh-fabric-configurator"
          xs={12}
          sm={6}
          md={12}
          alignItems="flex-start"
          alignContent="flex-start"
          className={`${classes.FabricGrid} ${
            xsUp && smDown
              ? index % 2 === 0
                ? classes.oddItem
                : classes.evenItem
              : ""
          }`}
        >
          <Grid item>
            {smDown ? (
              <Typography
                className={classNames(classes?.swatchGroupNameV3, {
                  [classes?.saleSwatchGroup]: checked && isAllSwatchesOnSale
                })}
                ref={textElementRef}
              >
                {swatchGroup?.swatchGroupName}
                {selection?.swatchGroupName === swatchGroup?.swatchGroupName ? (
                  <>- {selection?.title}</>
                ) : null}
              </Typography>
            ) : (
              <Typography
                className={classNames(classes?.swatchGroupNameV3, {
                  [classes?.saleSwatchGroup]: isAllSwatchesOnSale
                })}
                ref={textElementRef}
              >
                {swatchGroup?.swatchGroupName}
                {selection?.swatchGroupName === swatchGroup?.swatchGroupName ? (
                  <>- {selection?.title}</>
                ) : null}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <RHPriceRangeDisplay
              data={
                getSwatchPriceRange(swatchGroup?.swatches) ||
                swatchGroup?.swatches?.[0]?.swatchFamilyPrices
              }
              display={"inline"}
              topLabel={""}
              variant={"small"}
              isFabricPricing={true}
              isSkuLevel={true}
            />
          </Grid>
          {filteredSwatches?.length ? (
            <Grid item xs={12}>
              <SwatchesConfigurator
                groupMaterial={swatchGroup?.groupMaterial}
                swatchGroupName={swatchGroup?.swatchGroupName}
                swatches={filteredSwatches}
                handleSelection={handleSelection}
                selection={selection}
                checked={checked}
                data={data}
                isAllSwatchesOnSale={isAllSwatchesOnSale}
                isSwatchClicked={isSwatchClicked}
                setIsSwatchClicked={setIsSwatchClicked}
                openStatus={openStatus}
                setOpenStatus={setOpenStatus}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};
