import React, { FC, useCallback, useEffect, useState } from "react";
import {
  Grid,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery
} from "@material-ui/core";

import { FabricConfigurator } from "@RHCommerceDev/component-fabric-configurator";
import { SelectedFabricConfigurator } from "@RHCommerceDev/component-selected-fabric-configurator";
import memoize from "utils/memoize";
import { ConfiguratorOptions } from "@RHCommerceDev/component-configurator-options";
import { InstockOptionsFilter } from "@RHCommerceDev/component-instock-options-filter";
import {
  getFetchOptionStatusRequestPayload,
  getFormattedOptionIds,
  getInstockOptionIdsFromOptionStatus,
  getPreSelectedOptions,
  mapOptionStatusToAvailableOptions,
  updateSwatchDataStatus
} from "@RHCommerceDev/build-lineitem-available-options";
import { isEqual } from "lodash";
import {
  BREAKPOINT_XS,
  BREAKPOINT_SM,
  BREAKPOINT_MD,
  BREAKPOINT_LG,
  BREAKPOINT_XL,
  HIDE_SWATCH_DETAILS_MODAL_FOR_OPTIONS,
  MONOGRAM_ORDER_DEFAULT
} from "utils/constants";
import FinishOption from "@RHCommerceDev/component-configurator-options/FinishOption";
import MonogramDialog from "page-monogram-dialog";
import { MonogramChoice } from "component-related-product-card/MonogramChoice";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    FilterFabricConfiguratorGrid: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "stretch",

      paddingBottom: "24px",
      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        minWidth: "347px !important",
        maxWidth: "347px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        minWidth: "704px !important",
        maxWidth: "704px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        minWidth: "319px !important",
        maxWidth: "319px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        minWidth: "347px !important",
        maxWidth: "347px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        minWidth: "371px !important",
        maxWidth: "371px !important"
      }
    },
    SelectedFabricContainer: {
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        minWidth: "319px !important",
        maxWidth: "319px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        minWidth: "347px !important",
        maxWidth: "347px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        minWidth: "371px !important",
        maxWidth: "371px !important"
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "24px",
      [theme.breakpoints.up("sm")]: {
        paddingBottom: "0px"
      }
    },
    instockFilterGrid: {
      display: "flex",
      alignItems: "flex-start",
      paddingBottom: "24px",
      justifyContent: "center"
    },
    instockGrid: {
      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        minWidth: "347px !important",
        maxWidth: "347px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        minWidth: "704px !important",
        maxWidth: "704px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        minWidth: "319px !important",
        maxWidth: "319px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        minWidth: "347px !important",
        maxWidth: "347px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        minWidth: "371px !important",
        maxWidth: "371px !important"
      }
    }
  });
});

export const AvailableOptions: FC<any> = memoize(
  ({
    data,
    chosenLineItemOptions,
    setChosenLineItemOptionId,
    setChosenLineItemOptions,
    availableOptions,
    setAvailableOptions,
    setIsColorizable,
    fetchOptionStatus,
    currencyCode,
    locale,
    chosenLineItemUnavailableOptions,
    setChosenLineItemUnavailableOptions,
    availableSwatch,
    setAvailableSwatch,
    monogramOrder = MONOGRAM_ORDER_DEFAULT,
    setMonogramOrder
  }) => {
    const classes = useStyles();
    const mdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));

    const [instockOptionIds, setInstockOptionIds] = useState<string[]>([]);
    const [checked, setChecked] = useState<boolean>(false);
    const [isUserSelected, setIsUserSelected] = useState<boolean>(false);
    const [selection, setSelection] = useState<any>();
    const handleChecked = useCallback(() => {
      setChecked(prevChecked => !prevChecked);
      setIsUserSelected(true);
    }, []);

    const [monogramDialog, setMonogramDialog] = React.useState(false);

    const compareValues = (prev, current) =>
      Object.keys(prev).every(key => isEqual(prev?.[key], current?.[key]));

    const handleSelection = useCallback(
      (selectedSwatch, swatchGroupMaterial) => {
        let selectedUnavailableOptions: any = {};
        if (
          selectedSwatch?.status?.toString()?.toLowerCase() === "unavailable"
        ) {
          if (checked) {
            setChecked(instockOptionIds?.includes(selectedSwatch?.swatchId));
          }

          selectedUnavailableOptions = {
            [swatchGroupMaterial]: selectedSwatch?.swatchId
          };
          setChosenLineItemUnavailableOptions(prevState => ({
            ...prevState,
            [swatchGroupMaterial]: selectedSwatch?.swatchId
          }));
        } else {
          setChosenLineItemUnavailableOptions(prevState => ({
            ...prevState,
            [swatchGroupMaterial]: null
          }));
        }

        updateAvailableOptionStatus(
          {
            ...chosenLineItemOptions,
            [swatchGroupMaterial]: selectedSwatch?.swatchId
          },
          selectedUnavailableOptions
        );

        setChosenLineItemOptions(prevState => ({
          ...prevState,
          [swatchGroupMaterial]: selectedSwatch?.swatchId
        }));
        setSelection(prevState => ({
          ...prevState,
          [swatchGroupMaterial]: selectedSwatch
        }));

        setIsColorizable(data?.colorizeInfo?.colorizable);
        setIsUserSelected(true);
      },
      [checked, instockOptionIds, chosenLineItemOptions]
    );

    const handleSelectionConfigurator = useCallback(
      option => {
        let selectedUnavailableOptions: any = {};
        setIsColorizable(data?.colorizeInfo?.colorizable);
        if (option?.status?.toString()?.toLowerCase() === "unavailable") {
          if (checked) {
            setChecked(instockOptionIds?.includes(option?.id));
          }

          selectedUnavailableOptions = {
            [option.type ? option?.type : option?.name]: option?.id
          };

          setChosenLineItemUnavailableOptions(prevState => ({
            ...prevState,
            [option.type ? option?.type : option?.name]: option?.id
          }));
        } else {
          setChosenLineItemUnavailableOptions(prevState => ({
            ...prevState,
            [option.type ? option?.type : option?.name]: null
          }));
        }

        updateAvailableOptionStatus(
          {
            ...chosenLineItemOptions,
            [option.type ? option?.type : option?.name]: option?.id
          },
          selectedUnavailableOptions
        );

        setChosenLineItemOptions(prevState => ({
          ...prevState,
          [option.type ? option?.type : option?.name]: option?.id
        }));

        setIsUserSelected(true);
      },
      [checked, instockOptionIds, chosenLineItemOptions]
    );

    const handleFinishSwatchSelection = useCallback(
      swatch => {
        setIsColorizable(data?.colorizeInfo?.colorizable);
        if (!data?.colorizeInfo?.colorizable) return;
        let selectedUnavailableOptions: any = {};

        if (swatch?.status?.toString()?.toLowerCase() === "unavailable") {
          if (checked) {
            setChecked(instockOptionIds?.includes(swatch?.swatchId));
          }

          selectedUnavailableOptions = {
            finishSwatchGroups: swatch?.swatchId
          };
          setChosenLineItemUnavailableOptions(prevState => ({
            ...prevState,
            finishSwatchGroups: swatch?.swatchId
          }));
        } else {
          setChosenLineItemUnavailableOptions(prevState => ({
            ...prevState,
            finishSwatchGroups: null
          }));
        }

        updateAvailableOptionStatus(
          {
            ...chosenLineItemOptions,
            finishSwatchGroups: swatch?.swatchId
          },
          selectedUnavailableOptions
        );

        setChosenLineItemOptions(prevState => ({
          ...prevState,
          finishSwatchGroups: swatch?.swatchId
        }));

        setIsColorizable(data?.colorizeInfo?.colorizable);
        setIsUserSelected(true);
      },
      [checked, instockOptionIds, chosenLineItemOptions]
    );

    const updateAvailableOptionStatus = useCallback(
      async (selectedOptions = {}, selectedUnavailableOptions: any = {}) => {
        if (!Object.values(selectedOptions)?.length) {
          return;
        }

        const optionStatus = await fetchOptionStatus(
          getFetchOptionStatusRequestPayload({
            chosenLineItemOptions: selectedOptions,
            inStockOnly: checked,
            chosenLineItemUnavailableOptions: selectedUnavailableOptions
          })
        );

        if (checked) {
          setInstockOptionIds(
            getInstockOptionIdsFromOptionStatus(
              optionStatus?.availableOptionsStatus?.allOptions
            )
          );
        }

        if (optionStatus?.availableOptionsStatus?.allOptions?.length) {
          const { updatedAvailableOptions, selectedAvailableOptions } =
            mapOptionStatusToAvailableOptions({
              optionStatus: optionStatus?.availableOptionsStatus,
              availableOptions: availableOptions
            });

          const { updatedSwatchStatus, selectedSwatchOptions } =
            updateSwatchDataStatus({
              swatchData: data?.productConfiguration?.swatchData,
              optionStatus: optionStatus?.availableOptionsStatus
            });

          setChosenLineItemOptions(prev => ({
            ...prev,
            ...selectedAvailableOptions,
            ...selectedSwatchOptions
          }));
          getSelectedSwatch({
            ...selectedAvailableOptions,
            ...selectedSwatchOptions
          });

          const optionIds = getFormattedOptionIds({
            ...selectedAvailableOptions,
            ...selectedSwatchOptions
          });

          setChosenLineItemOptionId(optionIds);
          setAvailableOptions(updatedAvailableOptions);
          setAvailableSwatch(updatedSwatchStatus);
          setChosenLineItemUnavailableOptions({});
        }
      },
      [chosenLineItemUnavailableOptions, checked, data?.productConfiguration]
    );

    const getSelectedSwatch = preselectedOptions => {
      let swatchData = availableSwatch;

      if (!swatchData?.swatchGroups) {
        swatchData = data?.productConfiguration?.swatchData;
      }

      swatchData?.swatchGroups?.forEach((swatchGroup, index) => {
        swatchGroup?.swatchGroupList?.forEach(swatchGroupListElement => {
          const swatchIndex = swatchGroupListElement?.swatches?.findIndex(
            swatch =>
              swatch?.swatchId ===
              preselectedOptions?.[swatchGroup?.swatchGroupMaterialType]
          );

          if (swatchIndex >= 0) {
            setSelection(prev => ({
              ...prev,
              [swatchGroup?.swatchGroupMaterial]: {
                ...swatchGroupListElement?.swatches?.[swatchIndex],
                swatchGroupName: swatchGroupListElement?.swatchGroupName,
                groupMaterial: swatchGroupListElement?.groupMaterial
              }
            }));

            setChosenLineItemOptions(prevState => ({
              ...prevState,
              [swatchGroup?.swatchGroupMaterialType]:
                swatchGroupListElement?.swatches?.[swatchIndex]?.swatchId
            }));
          }
        });
      });
    };

    const resetToPreselectedOptions = () => {
      const { preselectedOptions, preselectedOptionIds } =
        getPreSelectedOptions(data?.productConfiguration, checked);

      setChosenLineItemUnavailableOptions({});
      setChosenLineItemOptionId(getFormattedOptionIds(preselectedOptionIds));
      setChosenLineItemOptions(preselectedOptions);
      getSelectedSwatch(preselectedOptions);

      updateAvailableOptionStatus(preselectedOptions);
    };

    useEffect(() => {
      if (data?.productConfiguration?.preselectedOptions?.length && checked) {
        resetToPreselectedOptions();
      }
      if (!checked) {
        updateAvailableOptionStatus(
          chosenLineItemOptions,
          chosenLineItemUnavailableOptions
        );
      }
    }, [data?.productConfiguration, checked]);

    useEffect(() => {
      if (data?.productConfiguration?.preselectedOptions?.length) {
        resetToPreselectedOptions();
      }
    }, [currencyCode, data?.productConfiguration?.preselectedOptions]);

    useEffect(() => {
      if (
        data?.productConfiguration?.inStockStatus?.toString()?.toUpperCase() ===
        "ALL"
      ) {
        setChecked(true);
      }
    }, [data?.productConfiguration?.inStockStatus]);

    return (
      <>
        <Grid
          container
          className={classes.instockGrid}
          id="instock-container"
          data-testid="instock-container"
        >
          {data?.productConfiguration?.inStockStatus
            ?.toString()
            ?.toUpperCase() !== "NONE" ? (
            <Grid
              container
              item
              direction="row"
              className={"flex items-start pb-[24px] justify-center"}
              id="instock-filter-grid"
              data-testid="instock-filter-grid"
            >
              <InstockOptionsFilter
                checked={checked}
                handleChecked={handleChecked}
              />
            </Grid>
          ) : null}
          {availableOptions?.length ||
          availableSwatch?.swatchGroups?.length ||
          availableSwatch?.finishSwatchGroups?.length ? (
            <Grid
              container
              item
              direction="column"
              className={"flex items-start pb-[24px]"}
              id="options-grid"
              data-testid="options-grid"
            >
              <ConfiguratorOptions
                data={data}
                checked={checked}
                handleSelection={handleSelectionConfigurator}
                handleFinishSwatchSelection={handleFinishSwatchSelection}
                availableOptions={availableOptions}
                availableSwatch={availableSwatch}
              />
            </Grid>
          ) : (
            <></>
          )}
          {/* <Grid
          container
          item
          direction="row"
          className={"flex items-start pb-[24px]"}
        >
          Filter component
        </Grid> */}
          {availableSwatch?.swatchGroups?.length > 0 ? (
            availableSwatch?.swatchGroups?.map(swatchGroup =>
              !HIDE_SWATCH_DETAILS_MODAL_FOR_OPTIONS?.includes(
                swatchGroup?.swatchGroupMaterialType?.toString()?.toLowerCase()
              ) ? (
                ["color", "countertop"].includes(
                  swatchGroup?.swatchGroupMaterialType
                    ?.toString()
                    ?.toLowerCase()
                ) ? (
                  <>
                    <Grid
                      container
                      item
                      className={classes?.FilterFabricConfiguratorGrid}
                      id="filter-fabric-configurator-grid"
                      data-testid="filter-fabric-configurator-grid"
                    >
                      <FinishOption
                        swatchGroupMaterial={
                          swatchGroup?.swatchGroupMaterialType
                        }
                        finishSwatches={swatchGroup?.swatchGroupList}
                        handleFinishSwatchSelection={e =>
                          handleSelection(e, swatchGroup?.swatchGroupMaterial)
                        }
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    {selection ? (
                      <Grid
                        item
                        xs={12}
                        id="selected-fabric-wrapper"
                        data-testid="selected-fabric-wrapper"
                      >
                        <Grid
                          className={classes?.SelectedFabricContainer}
                          id="selected-fabric-container"
                          data-testid="selected-fabric-container"
                        >
                          <SelectedFabricConfigurator
                            selection={
                              selection?.[swatchGroup?.swatchGroupMaterial]
                            }
                            checked={checked}
                            data={data}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}

                    <Grid
                      container
                      item
                      className={classes?.FilterFabricConfiguratorGrid}
                      id="filter-fabric-configurator-grid"
                      data-testid="filter-fabric-configurator-grid"
                    >
                      <FabricConfigurator
                        data={data}
                        swatchGroupMaterial={
                          swatchGroup?.swatchGroupMaterialType
                        }
                        handleSelection={e =>
                          handleSelection(e, swatchGroup?.swatchGroupMaterial)
                        }
                        selection={
                          selection?.[swatchGroup?.swatchGroupMaterial]
                        }
                        checked={checked}
                        availableSwatchGroups={swatchGroup?.swatchGroupList}
                      />
                    </Grid>
                  </>
                )
              ) : (
                <>
                  <Grid
                    container
                    style={{
                      gap: "32px",
                      paddingBottom: "24px",
                      justifyContent: mdDown && smUp ? "center" : "flex-start"
                    }}
                    id="finish-option"
                    data-testid="finish-option"
                  >
                    <FinishOption
                      swatchGroupMaterial={swatchGroup?.swatchGroupMaterialType}
                      finishSwatches={swatchGroup?.swatchGroupList}
                      handleFinishSwatchSelection={e =>
                        handleSelection(e, swatchGroup?.swatchGroupMaterial)
                      }
                    />
                    {data?.personalizeInfo?.monogrammable ? (
                      <Grid item xs={12} data-testid={"monogram-choice"}>
                        <MonogramChoice
                          fee={data?.personalizeInfo.feeHigh}
                          currencyLabel={
                            data?.priceRangeDisplay?.currencyLabel || ""
                          }
                          currencySymbol={
                            data?.priceRangeDisplay?.currencySymbol || ""
                          }
                          monogramOrder={monogramOrder}
                          openMonogramDialog={setMonogramDialog}
                          removeMonogram={() =>
                            setMonogramOrder(MONOGRAM_ORDER_DEFAULT)
                          }
                          data-testid={"monogram-choice"}
                        />
                      </Grid>
                    ) : null}
                    <MonogramDialog
                      monogramOrder={monogramOrder}
                      open={monogramDialog}
                      productId={data?.id}
                      setMonogramDialog={setMonogramDialog}
                      setMonogramOrder={setMonogramOrder}
                      data-testid={"monogram-dialog"}
                    />
                  </Grid>
                </>
              )
            )
          ) : (
            <></>
          )}
        </Grid>
      </>
    );
  }
);
