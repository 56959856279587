import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef
} from "react";
import {
  ListItem,
  Container,
  Grid,
  Theme,
  makeStyles,
  createStyles,
  useMediaQuery
} from "utils/material-ui-core";
import Promo from "component-promo";
import CollectionCard from "component-collection-card";
import theme from "theme";
import { ThemeProvider } from "@material-ui/styles";
import useAppData from "hooks/useAppData";
import { SaleContextFilter } from "graphql-client/queries/app";
import { bannerIsDisabled } from "utils/belowNavPromo";
import { UseRequestProvider } from "custom-providers/Provider";
import { RHImageContext } from "component-rh-image/RHImageContext";
import memoize from "utils/memoize";
import { useEnv } from "hooks/useEnv";
import { usePageContent } from "customProviders/LocationProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cgListItem: {
      paddingBottom: 15,
      [theme.breakpoints.up("sm")]: {
        paddingTop: 12,
        paddingBottom: 12
      },
      [theme.breakpoints.up("md")]: {
        paddingBottom: 20
      },
      [theme.breakpoints.up("xl")]: {
        paddingBottom: 25
      }
    },
    cgListItemCG: {
      [theme.breakpoints.up("sm")]: {
        paddingTop: 12
      }
    },
    rhrCgList: {
      paddingBottom: "0 !important",
      paddingTop: "0 !important"
    },
    rhrCgListCG: {
      paddingBottom: "0 !important",
      paddingTop: "0 !important"
    },
    rhrCgListXs: {
      marginBottom: "0 !important",
      paddingBottom: "0 !important"
    },
    rhrCgListSm: {
      paddingBottom: "0 !important",
      paddingTop: "0 !important",
      [theme.breakpoints.up("sm") && theme.breakpoints.down("md")]: {
        marginBottom: 9
      },
      [theme.breakpoints.up("md")]: {
        marginBottom: 10
      }
    },
    rhrCgListSmCG: {
      paddingBottom: "0 !important",
      paddingTop: "0 !important"
    }
  })
);

export interface GalleryCollectionCategorySelectorProps {
  collectionCategory: CollectionCategory;
  saleContextFilter?: SaleContextFilter;
  gridColumns?: Number;
  isImageEager?: boolean;
  saveSelectedCategory?: (cgId: string) => void;
  selectedProductId?: boolean;
  handleRemoveCookie?: (remove: string) => void;
  isSwatchSelected?: string[];
  setIsSwatchSelected?: Dispatch<SetStateAction<string[]>>;
}

export const ListItems = ({
  env,
  classes,
  isImageEager,
  collectionCategory,
  req,
  gridColumns,
  sale,
  saveSelectedCategory,
  xs,
  selectedProductId,
  selectedProductRef,
  handleRemoveCookie,
  isSwatchSelected,
  setIsSwatchSelected
}) => {
  return (
    <ListItem
      disableGutters
      data-testid={"gallery-collection-category-selector-test-id"}
      className={
        !env.FEATURE_RHR
          ? env.FEATURE_CG
            ? classes.cgListItemCG
            : classes.cgListItem
          : gridColumns === 6
          ? env.FEATURE_CG
            ? classes.rhrCgListCG
            : `sm:mb-[11px] md:mb-[10px] ${classes.rhrCgList}`
          : gridColumns === 4
          ? env.FEATURE_CG
            ? classes.rhrCgListSmCG
            : `sm:mb-[9px] md:mb-[10px] ${classes.rhrCgListSm}`
          : classes.rhrCgListXs
      }
    >
      <RHImageContext.Provider
        value={{ loading: isImageEager ? "eager" : "lazy" }}
      >
        {collectionCategory.cgBannerTemplate?.includes("bannerTheme=mo") &&
        !env.FEATURE_RHR &&
        !env.FEATURE_CG ? (
          <ThemeProvider
            theme={theme({
              type: "light",
              brand: "MO",
              reqContext: req
            })}
          >
            <CollectionCard
              data={{ ...collectionCategory, ...sale }}
              col={env.FEATURE_RHR ? gridColumns : undefined}
              isSwatchSelected={isSwatchSelected}
              setIsSwatchSelected={setIsSwatchSelected}
            />
          </ThemeProvider>
        ) : (
          <CollectionCard
            data={{ ...collectionCategory, ...sale }}
            col={gridColumns}
            hideDetails={env.FEATURE_RHR ? xs || gridColumns < 12 : false}
            saveSelectedCategory={saveSelectedCategory}
            selectedProductRef={selectedProductId ? selectedProductRef : null}
            handleRemoveCookie={handleRemoveCookie}
            isSwatchSelected={isSwatchSelected}
            setIsSwatchSelected={setIsSwatchSelected}
          />
        )}
      </RHImageContext.Provider>
    </ListItem>
  );
};

export const GalleryCollectionCategorySelector: FC<GalleryCollectionCategorySelectorProps> =
  memoize(
    ({
      collectionCategory,
      saleContextFilter,
      gridColumns,
      isImageEager,
      saveSelectedCategory,
      selectedProductId,
      handleRemoveCookie,
      isSwatchSelected,
      setIsSwatchSelected
    }) => {
      const { pageContent } = usePageContent();
      const classes = useStyles();
      const env = useEnv();
      const xs = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
      const selectedProductRef = useRef<HTMLDivElement | null>(null);

      useEffect(() => {
        const currentSelectedProductRef = selectedProductRef?.current;
        if (!currentSelectedProductRef) return;
        setTimeout(() => {
          currentSelectedProductRef.scrollIntoView({
            behavior: "auto",
            block: "center"
          });
        }, 250);
      }, []);

      const promotion = collectionCategory.promotionDisplayItems;
      const hasPromoBanner =
        (!!promotion.eventbanner || !!promotion.custombanner) &&
        !saleContextFilter;
      const hasBannerTemplate = !!collectionCategory.cgBannerTemplate;

      if (hasPromoBanner && !hasBannerTemplate) {
        return (
          <Container
            id={"component-gallery-collection-category-selector"}
            className={
              env.FEATURE_RHR
                ? "max-w-[1920px] !pl-0 !pr-0 lg:!pl-10 lg:!pr-10 xl:!pl-20 xl:!pr-20 !bg-[#F1F0ED]"
                : "max-w-[1920px] !pl-0 !pr-0 lg:!pl-10 lg:!pr-10 xl:!pl-20 xl:!pr-20"
            }
          >
            {!!promotion.addbanner &&
              !bannerIsDisabled(promotion.addbanner.id) && (
                <Promo data={promotion.addbanner} disableInView />
              )}

            {!!promotion.eventbanner &&
              !bannerIsDisabled(promotion.eventbanner.id) && (
                <Promo data={promotion.eventbanner} disableInView />
              )}

            {!!promotion.custombanner &&
              !bannerIsDisabled(promotion.custombanner.id) && (
                <Promo data={promotion.custombanner} disableInView />
              )}
          </Container>
        );
      } else if (hasBannerTemplate) {
        const { showSpecial, forceTextileSaleFlag, forceSaleFlag } =
          collectionCategory.uxAttributes || ({} as CategoryUxAttributes);
        const { onSale } = collectionCategory;
        let saleText = "";
        if (showSpecial === "true") {
          saleText = pageContent?.SPECIAL_SALE_TEXT;
        }
        if (forceTextileSaleFlag === "true") {
          saleText = pageContent?.SAVINGS_TEXTILES_SALE;
        }

        const sale =
          saleText !== ""
            ? {
                saleBannerCopy: {
                  text: saleText
                }
              }
            : {};
        const req = useContext(UseRequestProvider);
        return env.FEATURE_RHR ? (
          <ListItems
            env={env}
            classes={classes}
            isImageEager={isImageEager}
            collectionCategory={collectionCategory}
            req={req}
            gridColumns={gridColumns}
            sale={sale}
            saveSelectedCategory={saveSelectedCategory}
            xs={xs}
            selectedProductId={selectedProductId}
            selectedProductRef={selectedProductRef}
            handleRemoveCookie={handleRemoveCookie}
            isSwatchSelected={isSwatchSelected}
            setIsSwatchSelected={setIsSwatchSelected}
          />
        ) : (
          <Grid key={collectionCategory?.id} item xs={gridColumns}>
            <ListItems
              env={env}
              classes={classes}
              isImageEager={isImageEager}
              collectionCategory={collectionCategory}
              req={req}
              gridColumns={gridColumns}
              sale={sale}
              saveSelectedCategory={saveSelectedCategory}
              xs={xs}
              selectedProductId={selectedProductId}
              selectedProductRef={selectedProductRef}
              handleRemoveCookie={handleRemoveCookie}
              isSwatchSelected={isSwatchSelected}
              setIsSwatchSelected={setIsSwatchSelected}
            />
          </Grid>
        );
      }
      return null;
    }
  );

GalleryCollectionCategorySelector.defaultProps = {};

export default (
  props: Omit<GalleryCollectionCategorySelectorProps, "saleContextFilter">
) => {
  const { app } = useAppData();
  const {
    collectionCategory,
    gridColumns,
    isImageEager,
    saveSelectedCategory,
    selectedProductId,
    handleRemoveCookie,
    isSwatchSelected,
    setIsSwatchSelected
  } = props;

  return (
    <GalleryCollectionCategorySelector
      collectionCategory={collectionCategory}
      gridColumns={gridColumns}
      isImageEager={isImageEager}
      saveSelectedCategory={saveSelectedCategory}
      selectedProductId={selectedProductId}
      handleRemoveCookie={handleRemoveCookie}
      saleContextFilter={app.saleContextFilter}
      isSwatchSelected={isSwatchSelected}
      setIsSwatchSelected={setIsSwatchSelected}
    />
  );
};
