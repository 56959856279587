import React, { FC, useState, useCallback } from "react";
import {
  Grid,
  GridList,
  GridListTile,
  Typography,
  makeStyles,
  Theme,
  useMediaQuery,
  Popper,
  Box,
  ClickAwayListener,
  Button,
  PopperPlacementType
} from "@material-ui/core";
import memoize from "utils/memoize";
import RHImage from "components/RHImage";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
  BREAKPOINT_XS,
  HIDE_SWATCH_DETAILS_MODAL_FOR_OPTIONS,
  IMAGE_ASPECT_RATIO,
  SEE_ALL_OPTIONS_TOOLTIP,
  UPDATE_OTHER_OPTIONS_TOOLTIP
} from "utils/constants";
import RHCloseIcon from "icons/RHCloseIcon";
import classNames from "classnames";

import { Tooltip } from "utils/material-ui-core";
import AddToCartDialog from "dialogs/AddToCartDialog";
import { usePageContent } from "customProviders/LocationProvider";
import useUserPreferences from "hooks/useUserPreferences";
import { countryCurrencyMapper } from "resources/countries-config.json";
import { formatCurrency } from "utils/currencyHelpers";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import TailwindPopper from "component-tailwind-popper";

const useStyles = makeStyles((theme: Theme) => ({
  optionWrapper: {
    display: "flex",
    width: "347px",
    maxWidth: "347px",
    [theme.breakpoints.up("sm")]: {
      width: "100%"
    },
    "& > #finish-swatches": {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      maxWidth: "77.19%"
    }
  },
  optionKey: {
    color: "var(--Reimagine-Black, #000)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "RHSans-Light",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "13px",
    letterSpacing: "4%",
    textTransform: "uppercase",
    // minWidth: "22.81%",
    maxWidth: "97px",
    minWidth: "97px",
    marginRight: "8px",
    display: "flex",
    alignSelf: "center"
  },
  selectedFinishTitle: {
    color: "var(--Reimagine-Black, #000)",
    fontFamily: "RHSans",
    fontSize: "11px",
    fontWeight: 300,
    lineHeight: "13.2px",
    letterSpacing: "0.44px"
  },
  tootltipStyle: {
    flexGrow: 0,
    margin: "4px 0 -13px -4px",
    padding: "8px",

    backgroundColor: "var(--Reimagine-White,#fff)",
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: "0",
    [theme.breakpoints.up(BREAKPOINT_XS)]: {
      width: "339px !important"
    },
    [theme.breakpoints.up(BREAKPOINT_SM)]: {
      width: "339px !important"
    },
    [theme.breakpoints.up(BREAKPOINT_MD)]: {
      width: "319px !important"
    },
    [theme.breakpoints.up(BREAKPOINT_LG)]: {
      width: "347px !important"
    },
    [theme.breakpoints.up(BREAKPOINT_XL)]: {
      width: "371px !important"
    }
  },
  swatchImage: {
    flexGrow: 0,

    [theme.breakpoints.up(BREAKPOINT_XS)]: {
      width: "327px",
      height: "243px !important"
    },
    [theme.breakpoints.up(BREAKPOINT_SM)]: {
      width: "327px",
      height: "243px !important"
    },
    [theme.breakpoints.up(BREAKPOINT_MD)]: {
      width: "303px",
      height: "243px !important"
    },
    [theme.breakpoints.up(BREAKPOINT_LG)]: {
      width: "331px",
      height: "243px !important"
    },
    [theme.breakpoints.up(BREAKPOINT_XL)]: {
      width: "355px",
      height: "243px !important"
    }
  },
  tooltipTitle: {
    fontFamily: "RHSans-ExtraLight",
    fontSize: "13px",
    lineHeight: "14.3px",
    letterSpacing: "0.26px",
    color: "#393c41"
  },
  buttonStyle: {
    position: "absolute",
    top: "10px",
    zIndex: 9999,
    width: "26px",
    height: "26px",
    padding: "9px",
    borderRadius: "50px",
    backgroundColor: "white",
    border: "solid 0.5px var(--reimagine-warm-rh-medium)",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up(BREAKPOINT_XS)]: {
      right: "12px"
    },
    [theme.breakpoints.up(BREAKPOINT_SM)]: {
      right: "4px"
    },
    [theme.breakpoints.up(BREAKPOINT_MD)]: {
      right: "15px"
    },
    [theme.breakpoints.up(BREAKPOINT_LG)]: {
      right: "9.6px"
    },
    [theme.breakpoints.up(BREAKPOINT_XL)]: {
      right: "18px"
    }
  },
  imageGrid: {
    [theme.breakpoints.up(BREAKPOINT_XS)]: {
      width: "327px",
      height: "243px !important"
    },
    [theme.breakpoints.up(BREAKPOINT_SM)]: {
      width: "327px",
      height: "243px !important"
    },
    [theme.breakpoints.up(BREAKPOINT_MD)]: {
      width: "303px",
      height: "243px !important"
    },
    [theme.breakpoints.up(BREAKPOINT_LG)]: {
      width: "329.6px",
      height: "243px !important"
    },
    [theme.breakpoints.up(BREAKPOINT_XL)]: {
      width: "357px",
      height: "244px !important"
    }
  },
  titleGrid: {
    [theme.breakpoints.up(BREAKPOINT_XS)]: {
      maxWidth: "320px",
      marginTop: "8px"
    },
    [theme.breakpoints.up(BREAKPOINT_SM)]: {
      maxWidth: "327px"
    },
    [theme.breakpoints.up(BREAKPOINT_MD)]: {
      maxWidth: "303px"
    },
    [theme.breakpoints.up(BREAKPOINT_LG)]: {
      maxWidth: "329.6px"
    },
    [theme.breakpoints.up(BREAKPOINT_XL)]: {
      maxWidth: "357px"
    }
  },
  customTooltip: {
    padding: "8px 10px",
    color: "var(--reimagine-warm-rh-white, #FFF)",
    fontSize: "11px",
    fontFamily: "RHSans-Light",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "-0.11px",
    zIndex: 1,
    background: "#898886",
    fontWeight: 300
  },
  arrow: {
    color: "#898886"
  },
  diagonalStrick: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(-45deg, transparent 49.5%, #FFF 49.5%, #fff 50.5%, transparent 50.5%)"
  },
  addToCartButton: {
    backgroundColor: "#000000",
    width: "100%",
    height: "48px",
    fontSize: "12px",
    lineHeight: "14.4px",
    letterSpacing: "0.48px",
    color: "#fff",
    fontFamily: "RHSans-Roman",
    textTransform: "unset",
    border: "0",
    padding: "17px !important",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "#404040",
      borderColor: "#404040"
    },
    "& > span": {
      display: "flex",
      gap: "8px"
    }
  }
}));

interface FinishOptionProps {
  finishSwatches: Array<ProductSwatchGroupV2>;
  handleFinishSwatchSelection: (swatch) => void;
  swatchGroupMaterial?: any;
}

const FinishOption: FC<FinishOptionProps> = ({
  finishSwatches,
  handleFinishSwatchSelection,
  swatchGroupMaterial
}) => {
  const {
    previousState: { country }
  } = useUserPreferences();
  const currencyCode = countryCurrencyMapper[country];
  const env = useEnv();
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const DefaulPopper = FEATURE_TAILWIND_COMPONENTS ? TailwindPopper : Popper;

  const classes = useStyles();
  const mdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));

  const selectedSwatch = finishSwatches?.[0]?.swatches?.find(
    swatch => swatch?.status === "selected"
  );
  const swatchGroupName = finishSwatches?.[0]?.swatchGroupName?.split(" ")?.[0];

  const [openStatus, setOpenStatus] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const { pageContent } = usePageContent();
  const [cartDialog, setCartDialog] = useState(false);
  const [cartDetail, setCartDetail] = useState([]);

  const [swatchSelected, setSwatchSelected] = useState<any>(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [tooltipPlacement, setTooltipPlacement] =
    useState<PopperPlacementType>("top-start");
  const [isToolTipMessage, setIsToolTipMessage] = useState(
    SEE_ALL_OPTIONS_TOOLTIP
  );

  const handleClickSwatchs = () => {
    setIsToolTipMessage(UPDATE_OTHER_OPTIONS_TOOLTIP);
  };

  const handleMouseEnter = useCallback(
    index => {
      setActiveIndex(index);
      setIsToolTipMessage(SEE_ALL_OPTIONS_TOOLTIP);
    },
    [setActiveIndex]
  );

  const handleMouseLeave = useCallback(() => {
    setActiveIndex(null);
    setIsToolTipMessage(SEE_ALL_OPTIONS_TOOLTIP);
  }, []);

  const handleClickSwatch = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleSwatch = useCallback(
    (productSwatchData, event) => {
      if (event.clientY < 550) {
        setTooltipPlacement("bottom-start");
      } else {
        setTooltipPlacement("top-start");
      }
      if (
        !HIDE_SWATCH_DETAILS_MODAL_FOR_OPTIONS?.includes(
          swatchGroupMaterial?.toString()?.toLowerCase()
        )
      ) {
        setOpenStatus(prevStatus => {
          const newStatus = {};
          Object.keys(prevStatus).forEach(key => {
            newStatus[key] = false;
          });
          newStatus[productSwatchData.swatchId] = true;
          return newStatus;
        });
      }
      setSwatchSelected(productSwatchData);
    },
    [setOpenStatus]
  );
  const handleClosePopper = useCallback(() => {
    setOpenStatus({});
  }, [setOpenStatus]);

  return (
    <>
      <Grid
        container
        item
        alignItems="center"
        style={{
          justifyContent: mdDown && smUp ? "" : "flex-start",
          marginTop: "24px"
        }}
        wrap="nowrap"
        className={classes.optionWrapper}
        onClick={handleClickSwatch}
        onMouseEnter={handleClickSwatch}
        id="swatch-wrapper"
        data-testid="swatch-wrapper"
      >
        <ClickAwayListener onClickAway={handleClosePopper}>
          <div
            style={{ display: "flex", alignItems: "center" }}
            id="swatch-info-container"
            data-testid="swatch-info-container"
          >
            <Grid
              className={classes.optionKey}
              id="swatch-groupname-wrapper"
              data-testid="swatch-groupname-wrapper"
            >
              <Typography
                style={{
                  alignSelf: "center",
                  textTransform: "uppercase",
                  fontFamily: "RHSans-Light"
                }}
                id="swacth-group-name"
                data-testid="swacth-group-name"
              >
                {swatchGroupName}
              </Typography>
            </Grid>
            <DefaulPopper
              open={openStatus?.[swatchSelected?.swatchId]}
              anchorEl={anchorEl}
              disablePortal
              placement={tooltipPlacement}
              transition
              style={{ zIndex: "9999" }}
            >
              <Box className={classes.tootltipStyle}>
                <Grid
                  container
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  id="finish-popper"
                  data-testid="finish-popper"
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ position: "relative" }}
                    className={classes.imageGrid}
                    id="finish-swatch-image"
                    data-testid="finish-swatch-image"
                  >
                    <div
                      className={classes.buttonStyle}
                      onClick={handleClosePopper}
                      id="close-icon-container"
                      data-testid="close-icon-container"
                    >
                      <RHCloseIcon
                        style={{
                          width: "8px",
                          height: "8px",
                          color: "black",
                          cursor: "pointer"
                        }}
                      />
                    </div>

                    <RHImage
                      src={swatchSelected?.swatchImageLg?.url}
                      alt={`Swatch ${swatchSelected?.title}`}
                      aspectRatio={IMAGE_ASPECT_RATIO?.swatch}
                      imageClassName={classNames(classes.swatchImage)}
                      isSwatchTooltip="true"
                      isFinishOption="true"
                      imageStyles={{ height: "none" }}
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    md={12}
                    className={classes.titleGrid}
                    id="finish-swatch-title-grid"
                    data-testid="finish-swatch-title-grid"
                    style={{ paddingBottom: "12px" }}
                  >
                    <Typography
                      className={classes.tooltipTitle}
                      id="finsih-swatch-title"
                      data-testid="finsih-swatch-title"
                    >
                      {swatchSelected?.title}
                    </Typography>
                  </Grid>
                  {swatchSelected?.swatchSkuIds?.[0]?.swatchProductId ? (
                    <Button
                      className={classes.addToCartButton}
                      variant="contained"
                      onClick={() => {
                        setCartDialog(true);
                        setCartDetail(swatchSelected?.swatchSkuIds ?? []);
                      }}
                    >
                      <span>{pageContent?.ADD_TO_CART?.toUpperCase()}</span>
                      {swatchSelected?.swatchSkuIds?.[0]?.skuIds && (
                        <span>
                          <span>
                            {formatCurrency(
                              swatchSelected?.combinedSwatchSkuIds?.memberPrice,
                              currencyCode,
                              false,
                              true
                            )}
                          </span>
                          <span
                            style={{
                              fontFamily: "RHSans-Thin",
                              fontWeight: 100
                            }}
                          >
                            {" "}
                            /
                            {formatCurrency(
                              swatchSelected?.combinedSwatchSkuIds
                                ?.displayPrice,
                              currencyCode,
                              false,
                              true
                            )}{" "}
                            {pageContent?.regular}
                          </span>
                        </span>
                      )}
                    </Button>
                  ) : null}
                </Grid>
              </Box>
              {cartDialog && (
                <AddToCartDialog
                  open={cartDialog}
                  swatchAddToCartItemDetails={cartDetail}
                  fullSkuId={""}
                  qty={1}
                  productId={""}
                  confirmed={true}
                  onClose={() => {
                    handleClosePopper();
                    setCartDialog(false);
                  }}
                />
              )}
            </DefaulPopper>
            <Grid id="finish-swatches" data-testid="finish-swatches">
              <GridList
                cols={10}
                cellHeight="auto"
                spacing={3}
                style={{ gap: "3px" }}
              >
                <Grid
                  item
                  xs={12}
                  id="selected-swatch-title-grid"
                  data-testid="selected-swatch-title-grid"
                >
                  <Typography
                    style={{ alignSelf: "center" }}
                    className={classes.selectedFinishTitle}
                    id="selected-finish-title"
                    data-testid="selected-finish-title"
                  >
                    {selectedSwatch?.title}
                  </Typography>
                </Grid>
                {finishSwatches?.[0]?.swatches?.map((productSwatch, index) => {
                  return productSwatch?.status !== "unavailable" ? (
                    <GridListTile
                      key={index}
                      onClick={event => {
                        handleFinishSwatchSelection({
                          ...productSwatch,
                          groupMaterial: swatchGroupName
                        });
                        handleSwatch(productSwatch, event);
                      }}
                      id="finishSwatches-images"
                      data-testid="finishSwatches-images"
                      style={{
                        width: "32px",
                        height: "32px",
                        cursor: "pointer",
                        padding: 0
                      }}
                    >
                      <RHImage
                        src={productSwatch?.swatchImageLg?.url}
                        alt={`Swatch ${productSwatch?.title}`}
                        aspectRatio={IMAGE_ASPECT_RATIO?.swatch}
                        imageWrapperStyles={{
                          border:
                            productSwatch?.status === "selected"
                              ? "1px solid var(--ReimagineWarm-RH-Dark, #000)"
                              : "0.27px solid var(--ReimagineWarm-RH-Dark, #898886)"
                        }}
                        style={{
                          padding:
                            productSwatch?.status === "selected" ? "2px" : "0px"
                        }}
                      />
                    </GridListTile>
                  ) : (
                    <Tooltip
                      open={activeIndex === productSwatch?.swatchId}
                      title={
                        isToolTipMessage?.length
                          ? isToolTipMessage
                          : SEE_ALL_OPTIONS_TOOLTIP
                      }
                      classes={{
                        tooltip: classes.customTooltip,
                        arrow: classes.arrow
                      }}
                      arrow
                      placement={
                        tooltipPlacement === "bottom-start" ? "bottom" : "top"
                      }
                      enterDelay={500}
                      leaveDelay={2000}
                    >
                      <GridListTile
                        key={index}
                        id={`unavailable-finishSwatches-images-${productSwatch?.swatchId}`}
                        style={{
                          width: "32px",
                          height: "32px"
                        }}
                        onClick={event => {
                          event.stopPropagation();
                          handleSwatch(productSwatch, event);
                          handleClickSwatchs();
                          handleFinishSwatchSelection({
                            ...productSwatch,
                            groupMaterial: swatchGroupName
                          });
                        }}
                        onMouseEnter={() => {
                          handleMouseEnter(productSwatch?.swatchId);
                        }}
                        onMouseLeave={handleMouseLeave}
                      >
                        <RHImage
                          src={productSwatch?.swatchImageLg?.url}
                          alt={`Swatch ${productSwatch?.title}`}
                          aspectRatio={IMAGE_ASPECT_RATIO?.swatch}
                          style={{ width: "32px", height: "32px" }}
                        />
                        <div className={classes.diagonalStrick} />
                      </GridListTile>
                    </Tooltip>
                  );
                })}
              </GridList>
            </Grid>
          </div>
        </ClickAwayListener>
      </Grid>
    </>
  );
};

export default memoize(FinishOption);
