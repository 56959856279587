import React, { FC } from "react";
import useBrand, { brandCodeToHTMLTitle } from "hooks-use-brand/useBrand";
import Helmet from "react-helmet";
import he from "he";
import stripTags from "utils/stripTags";
import memoize from "utils/memoize";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";
import { trimDescription } from "utils/trimDescription";
import { getLocaleFromPath } from "utils/intlUtils";
import { useHistory, useLocation } from "react-router";
import { useEnv } from "hooks/useEnv";
import yn from "yn";

export interface HTMLHeadTagsProps {
  withTitleBrand?: boolean;
  title: string;
  description?: string;
  keywords?: string;
  displayName?: string;
  image?: string;
  showBrandFirst?: boolean;
  schema?: string;
}

export const HTMLHeadTags: FC<HTMLHeadTagsProps> = ({
  withTitleBrand,
  title,
  description,
  keywords,
  displayName,
  image,
  showBrandFirst,
  schema
}) => {
  const env = useEnv();
  const URL = useLocation();
  const history = useHistory();
  const brand = useBrand();
  if (!title) return null;
  const decodedTitle = he.decode(stripTags(title));
  const decodedDescription = he.decode(
    description
      ? `${stripTags(description)}`
      : "Honoring refined design and artisanal craftsmanship, our timeless collections celebrate the distinctive provenance and enduring quality of each piece."
  );
  const decodedKeywords = he.decode(keywords ? `${stripTags(keywords)}` : "");

  const isCSRLoad = isSSRToggledWithClientRender();
  const currentURL = window.location.href;

  const isShoppingPages =
    currentURL.includes("catalog/product/product.jsp") ||
    currentURL.includes("catalog/category/collections.jsp") ||
    currentURL.includes("catalog/category/products.jsp");

  if (
    !isShoppingPages ||
    (isShoppingPages && !isCSRLoad) ||
    history?.action === "PUSH"
  ) {
    const descriptionMeta = document.querySelector(
      'meta[name="description"]'
    ) as HTMLMetaElement;
    const keywordsMeta = document.querySelector(
      'meta[name="keywords"]'
    ) as HTMLMetaElement;
    const canonical = document.querySelector(
      'link[rel="canonical"]'
    ) as HTMLLinkElement;
    const ogImage = document.querySelector(
      'meta[property="og:image"]'
    ) as HTMLMetaElement;
    const ogURL = document.querySelector(
      'meta[property="og:url"]'
    ) as HTMLMetaElement;
    const ogDescription = document.querySelector(
      'meta[property="og:description"]'
    ) as HTMLMetaElement;
    const ogTitle = document.querySelector(
      'meta[property="og:title"]'
    ) as HTMLMetaElement;
    const scriptTag = document.getElementById("schema");
    if (scriptTag) {
      scriptTag.innerHTML = schema ?? "";
    }

    const trimmedDescription = trimDescription(decodedDescription);
    // Update the content attribute of the description meta tag
    if (descriptionMeta) {
      descriptionMeta.content = trimmedDescription;
    }
    if (ogDescription) {
      ogDescription.content = trimmedDescription;
    }
    if (ogImage && image) {
      ogImage.content = "https:" + image;
    }
    if (ogTitle) {
      ogTitle.content = withTitleBrand
        ? `${decodedTitle} | ${brandCodeToHTMLTitle[brand]}`
        : decodedTitle;
    }
    // Update the content attribute of the keywords meta tag
    if (keywordsMeta) {
      if (typeof keywords == "string" && keywords != "")
        keywordsMeta.content = decodedKeywords;
    }
    if (canonical && yn(env.FEATURE_CANONICAL_URLS)) {
      const baseURL = `https://${window.location.hostname}${
        getLocaleFromPath(window.location.href)?.locale
      }`;

      const queryParam = new URLSearchParams(URL?.search);
      if (currentURL.includes(".jsp/")) {
        const canonicalUrlIndex = currentURL.indexOf("?");

        if (canonicalUrlIndex !== -1) {
          canonical.href = currentURL.slice(0, canonicalUrlIndex);
        } else {
          canonical.href = currentURL.replace("&ct=true", "");
        }
        ogURL.content = canonical.href;
      } else {
        if (currentURL.includes("catalog/product/product.jsp")) {
          const productId = queryParam.get("productId") as string;
          canonical.href = `${baseURL}catalog/product/product.jsp/${productId}`;
          ogURL.content = canonical.href;
        } else if (currentURL.includes("catalog/category/collections.jsp")) {
          const categoryId = queryParam.get("categoryId");
          canonical.href = window.location.href.includes("outdoor/")
            ? `${baseURL}outdoor/catalog/category/collections.jsp/${categoryId}`
            : `${baseURL}catalog/category/collections.jsp/${categoryId}`;
          ogURL.content = canonical.href;
        } else if (currentURL.includes("catalog/category/products.jsp")) {
          const categoryId = queryParam.get("categoryId") as string;
          const pgterm = queryParam
            .get("pgterm")
            ?.replace(/[ +]/g, "-") as string;
          if (pgterm) {
            canonical.href = window.location.href.includes("outdoor/")
              ? `${baseURL}outdoor/catalog/category/products.jsp/${categoryId}/${pgterm?.toLowerCase()}`
              : `${baseURL}catalog/category/products.jsp/${categoryId}/${pgterm?.toLowerCase()}`;
            ogURL.content = canonical.href;
          } else {
            canonical.href = window.location.href.includes("outdoor/")
              ? `${baseURL}outdoor/catalog/category/products.jsp/${categoryId}`
              : `${baseURL}catalog/category/products.jsp/${categoryId}`;
            ogURL.content = canonical.href;
          }
        }
      }
    }
  }
  return (
    <Helmet>
      <title>
        {withTitleBrand
          ? !showBrandFirst
            ? `${decodedTitle} | ${brandCodeToHTMLTitle[brand]}`
            : `${brandCodeToHTMLTitle[brand]} | ${decodedTitle}`
          : decodedTitle}
      </title>
    </Helmet>
  );
};

HTMLHeadTags.defaultProps = {
  withTitleBrand: true,
  showBrandFirst: false
};

export default memoize(HTMLHeadTags);
