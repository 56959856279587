import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Theme,
  useMediaQuery,
  useTheme,
  Typography
} from "utils/material-ui-core";
import CollectionCardDetails from "component-collection-card-details";
import { CollectionCardProps } from "./";
import RHImage from "component-rh-image";
import RHSpinner from "component-rh-spinner";
import useMediaString from "hooks/useMediaString";
import { ProductImagePresetKeys } from "utils/getImageUrlWithPreset";
import { IMAGE_ASPECT_RATIO } from "utils/constants";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import { useFetchModel } from "hooks/useFetchModel";
import RHCarousel from "@RHCommerceDev/component-rh-carousel";
import Slider from "react-slick";
import NextArrow from "@RHCommerceDev/component-rh-carousel/NextArrow";
import PreviousArrow from "@RHCommerceDev/component-rh-carousel/PreviousArrow";
import { Hidden } from "@RHCommerceDev/hooks/useSsrHooks";

const fullHeightStyle = { style: { height: "100%" } };

export const Landscape: FC<CollectionCardProps> = ({
  data,
  linkUrl,
  readonly,
  col,
  handleOnSwatchClick,
  categorySwatchImage,
  categoryImageLoading,
  isSwatchSelected,
  swatchImageId
}) => {
  const [altImages, setAltImages] = useState(data?.altImages || []);
  const theme = useTheme();
  const wrap = theme.breakpoints.down("md") ? "wrap-reverse" : "nowrap";
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const lgUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("lg"));

  const ipadImage = theme.breakpoints.down("md") ? "unset" : "cover";
  const mediaString = useMediaString();
  const env = useEnv();
  const { pageContent } = useFetchModel("/admin/collections", true, false);
  const isCGColorizationEnabled = yn(env.FEATURE_CG_COLORIZATION);
  const isFeatureCGCarousel = yn(env.FEATURE_CG_CAROUSEL);

  const [isHeroImageUrlFailed, setIsHeroImageUrlFailed] =
    useState<boolean>(false);
  const productImageUrl = React.useMemo(() => {
    if (isHeroImageUrlFailed) {
      // return default image available soon url when url gets failed to load
      return "//media.restorationhardware.com/is/image/rhis/4KH?src=ir(rhir/prod27210510_E22519282534_TQ_CO22_RHR?src=rhir/obj=Fabric&src=rhir/1649004_E2219536411_O_Bsq_reapeat&res=300&illum=0&obj=Fabric&req=object&illum=0)&$PDP-IR-1600$";
    }
    return data?.cgBannerImage;
  }, [data?.cgBannerImage, isHeroImageUrlFailed]);

  const sliderSettings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: !!(altImages && altImages?.length > 1),
      nextArrow: <NextArrow className={"carouselArrow"} col={col} />,
      prevArrow: <PreviousArrow className={"carouselArrow"} col={col} />
    }),
    [altImages]
  );
  useEffect(() => {
    if (altImages) {
      const newAltImages = [{ imageUrl: productImageUrl }, ...altImages];
      setAltImages(newAltImages);
    }
  }, []);

  const swatchSelected = isSwatchSelected?.filter(
    swatch => swatch === data?.id
  );

  return (
    <div
      className={`flex ${
        lgUp && col === 12 ? "gap-11" : col === 4 ? "md:gap-0" : "md:gap-4"
      } ${!mdUp ? "flex-col-reverse" : col === 12 && !lgUp && "items-end"} ${
        col === 4 || col === 6 ? "flex-col-reverse" : ""
      }`}
    >
      <CollectionCardDetails
        col={col}
        linkUrl={linkUrl}
        data={data}
        handleOnSwatchClick={handleOnSwatchClick}
      />
      {!!data.cgBannerImage && (
        <div
          className={`
            place-items-end
            ${
              !mdUp
                ? col === 12 && yn(env.FEATURE_CG) && smUp
                  ? "!pb-6"
                  : col === 6 || !smUp
                  ? "!pb-0"
                  : "pb-5"
                : "!pb-0"
            }
            ${env.FEATURE_CG ? "" : "mb-4"}
            relative
            w-full
            h-full
            `}
        >
          {!data.colorizable &&
            isCGColorizationEnabled &&
            swatchSelected?.length !== 0 && (
              <div
                className={`
                  absolute
                  flex
                  justify-center
                  items-center
                  w-full
                  right-0
                  ${mdUp && col === 12 ? "md:left-5" : "left-0"}
                  ${mdUp && col === 12 ? "pr-5" : ""}
                  h-[15%]
                  bg-black-40
                  !z-[99]
                  `}
              >
                <Typography
                  className="
                    !uppercase
                    !text-[#fff]
                    !font-primary-rhthin
                    !text-[12px]
                    "
                >
                  {pageContent?.COLOR_PREVIEW_AVAILABLE_SOON ||
                    "Color preview available soon"}
                </Typography>
              </div>
            )}

          {isFeatureCGCarousel && !readonly && altImages?.length > 0 ? (
            <>
              <Hidden only={["xl", "lg", "md"]}>
                <RHCarousel infinite={true}>
                  {altImages?.map((altImage, index) => (
                    <>
                      {!categoryImageLoading ? (
                        <RHImage
                          rootProps={fullHeightStyle}
                          loadingBehavior="eager"
                          imageWrapperProps={fullHeightStyle}
                          style={{
                            height: "100%",
                            objectFit: ipadImage
                          }}
                          src={
                            (index === 0 && categorySwatchImage) ||
                            (altImage?.imageUrl as string)
                          }
                          setIsHeroImageUrlFailed={setIsHeroImageUrlFailed}
                          alt={data.displayName}
                          preset={
                            `cg-banner-${mediaString}` as ProductImagePresetKeys
                          }
                          width="100%"
                          aspectRatio={IMAGE_ASPECT_RATIO["collectionBanner"]}
                        />
                      ) : (
                        <div
                          className={`
                          relative
                          w-full
                          h-full
                          `}
                        >
                          <RHImage
                            rootProps={fullHeightStyle}
                            loadingBehavior="eager"
                            imageWrapperProps={fullHeightStyle}
                            style={{
                              height: "100%",
                              objectFit: ipadImage
                            }}
                            setIsHeroImageUrlFailed={setIsHeroImageUrlFailed}
                            preset={
                              `cg-banner-${mediaString}` as ProductImagePresetKeys
                            }
                            width="100%"
                            aspectRatio={IMAGE_ASPECT_RATIO["collectionBanner"]}
                          />
                          {categoryImageLoading &&
                            isCGColorizationEnabled &&
                            data.colorizable && (
                              <div
                                className={`
                                  absolute
                                  top-0
                                  left-0
                                  w-full
                                  h-full
                                  flex
                                  justify-center
                                  items-center
                                  `}
                              >
                                <div className="cardImages">
                                  <RHSpinner />
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                    </>
                  ))}
                </RHCarousel>
              </Hidden>

              <Hidden only={["sm", "xs"]}>
                <Slider {...sliderSettings}>
                  {altImages?.map((altImage, index) => (
                    <>
                      {!categoryImageLoading ? (
                        <RHImage
                          rootProps={fullHeightStyle}
                          loadingBehavior="eager"
                          imageWrapperProps={fullHeightStyle}
                          style={{
                            height: "100%",
                            objectFit: ipadImage
                          }}
                          src={
                            (index === 0 && categorySwatchImage) ||
                            (altImage?.imageUrl as string)
                          }
                          setIsHeroImageUrlFailed={setIsHeroImageUrlFailed}
                          alt={data.displayName}
                          preset={
                            `cg-banner-${mediaString}` as ProductImagePresetKeys
                          }
                          width="100%"
                          aspectRatio={IMAGE_ASPECT_RATIO["collectionBanner"]}
                          objectFit="contain"
                        />
                      ) : (
                        <div
                          className={`
                            relative
                            w-full
                            h-full
                            `}
                        >
                          <RHImage
                            rootProps={fullHeightStyle}
                            loadingBehavior="eager"
                            imageWrapperProps={fullHeightStyle}
                            style={{
                              height: "100%",
                              objectFit: ipadImage
                            }}
                            setIsHeroImageUrlFailed={setIsHeroImageUrlFailed}
                            preset={
                              `cg-banner-${mediaString}` as ProductImagePresetKeys
                            }
                            width="100%"
                            aspectRatio={IMAGE_ASPECT_RATIO["collectionBanner"]}
                          />
                          {categoryImageLoading &&
                            isCGColorizationEnabled &&
                            data.colorizable && (
                              <div
                                className={`
                                absolute
                                top-0
                                left-0
                                w-full
                                h-full
                                flex
                                justify-center
                                items-center
                                `}
                              >
                                <div className="cardImages">
                                  <RHSpinner />
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                    </>
                  ))}
                </Slider>
              </Hidden>
            </>
          ) : (
            !readonly && (
              <>
                {!categoryImageLoading ? (
                  <RHImage
                    rootProps={fullHeightStyle}
                    imageWrapperProps={fullHeightStyle}
                    style={{
                      height: "100%",
                      objectFit: ipadImage
                    }}
                    src={categorySwatchImage || data.cgBannerImage}
                    alt={data.displayName}
                    preset={
                      `cg-banner-${mediaString}` as ProductImagePresetKeys
                    }
                    width="100%"
                    aspectRatio={IMAGE_ASPECT_RATIO["collectionBanner"]}
                    loadingBehavior="eager"
                  />
                ) : (
                  <div className="relative w-full h-full">
                    <RHImage
                      rootProps={fullHeightStyle}
                      loadingBehavior="eager"
                      imageWrapperProps={fullHeightStyle}
                      style={{
                        height: "100%",
                        objectFit: ipadImage
                      }}
                      setIsHeroImageUrlFailed={setIsHeroImageUrlFailed}
                      preset={
                        `cg-banner-${mediaString}` as ProductImagePresetKeys
                      }
                      width="100%"
                      aspectRatio={IMAGE_ASPECT_RATIO["collectionBanner"]}
                    />
                    {categoryImageLoading &&
                      isCGColorizationEnabled &&
                      data.colorizable && (
                        <div
                          className={`
                          absolute
                          top-0
                          left-0
                          w-full
                          h-full
                          flex
                          justify-center
                          items-center
                          `}
                        >
                          <div className="cardImages">
                            <RHSpinner />
                          </div>
                        </div>
                      )}
                  </div>
                )}
              </>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default Landscape;
