import { useFetchModel } from "hooks/useFetchModel";
import React from "react";
import { AEMPublishPage } from "concierge-alias-aem/AEMPublishPage";
import { Constants } from "@adobe/aem-react-editable-components";
import graphqlClient from "graphql-client";
type AsyncContentProps = {
  path: string;
  isInEditor: boolean;
  model?: any;
};

export const AsyncContent: React.FC<AsyncContentProps> = props => {
  const { path, isInEditor, model } = props;
  const { pageContent } = useFetchModel(
    path,
    false,
    true,
    model,
    graphqlClient
  );

  return !isInEditor && pageContent ? (
    <AEMPublishPage
      cqChildren={{}}
      cqItems={pageContent?.[Constants.ITEMS_PROP] ?? {}}
      cqItemsOrder={pageContent?.[Constants.ITEMS_ORDER_PROP] ?? []}
      cqPath={path}
      isInEditor={isInEditor}
      pageModel={pageContent}
      isAsync={true}
    />
  ) : (
    <></>
  );
};

export default AsyncContent;
