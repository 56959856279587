import {
  Grid,
  Theme,
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import React, { FC, useCallback, useState, useEffect } from "react";
import { ProductNamePrice } from "@RHCommerceDev/component-product-name-price";
import { AvailableOptions } from "@RHCommerceDev/component-available-options";
import memoize from "utils/memoize";
import AddOnCheckboxProductV2 from "@RHCommerceDev/component-addon-checkbox-v2";
import DecrementIcon from "icons/DecrementIcon";
import IncrementIcon from "icons/IncrementIcon";
import { usePageContent } from "customProviders/LocationProvider";
import { processEnvServer } from "hooks/useSsrHooks";

import { useTypographyStyles } from "hooks/useTypographyStyles";
import {
  AddOnDataType,
  AddOnConfigModes,
  AddOnRenderButtonOptionInfo
} from "@RHCommerceDev/component-addon-checkbox-v2";
// import { addOnDataType } from "@RHCommerceDev/component-addon-panel-v2";
import RHLink from "component-rh-link";
import useIsoRedirect from "hooks-use-isoredirect";
import he from "he";
import { BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XS } from "utils/constants";
import classNames from "classnames";
import { MonogramOrderProps } from "page-monogram-dialog";

const useStyles = makeStyles((theme: Theme) => ({
  iconStyle: {
    width: "8px"
  },
  quantityDisplayContainer: {
    width: "24px",
    fontSize: "11px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  quantityIcon: {
    padding: "13px"
  },
  quantityContainer: {
    width: "79px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    userSelect: "none"
  },
  quantityContainerStyle: {
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    userSelect: "none",
    gap: "14px"
  },
  pricingContainerStyle: {
    width: "210px",
    margin: "40px 0 40px 0",
    display: "flex",
    alignItems: "center"
  },
  qtyLabel: {
    fontFamily: "RHSans-Light",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "110%",
    letterSpacing: "0.52px",
    textTransform: "uppercase",
    marginRight: "48px"
  },
  addToCartBox: {
    marginTop: "43px"
  },
  ul: {
    padding: theme.spacing(0, 0, 3, 0),
    margin: 0,
    listStyle: "initial",
    [`& li[style*="display: list-item"]`]: {
      marginLeft: theme.spacing(2)
    }
  },
  ctaGrid: {
    color: "#000 !important",
    display: "flex !important",
    gap: "16px",
    flexDirection: "column",
    marginBottom: "36px",
    "& a": {
      textDecoration: "underline",
      textUnderlineOffset: "2px"
    },
    "& p": {
      textTransform: "uppercase",
      fontSize: theme.typography.caption.fontSize
    },
    [theme.breakpoints.up(BREAKPOINT_XS)]: {
      textAlign: "center",
      width: "100%",
      justifyContent: "center"
    },
    [theme.breakpoints.up(BREAKPOINT_SM)]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridColumnGap: "30px",
      flexDirection: "row",
      textAlign: "center",
      width: "100%",
      justifyContent: "center",
      gap: "30px",
      "& > *:only-child": {
        gridColumn: "1 / span 2",
        justifySelf: "center"
      }
    },
    [theme.breakpoints.up(BREAKPOINT_MD)]: {
      flexDirection: "column",
      "&:not(.addToCart)": {
        textAlign: "left"
      },
      display: "block"
    }
  },
  linkText: {
    "& > #linkTextTypo": {
      color: "#000",
      fontFamily: "RHSans-Light",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: 300,
      letterSpacing: "0.44px",
      textTransform: "uppercase",
      lineHeight: "13.2px"
    }
  },

  detailDimensionContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "40px !important"
  },
  merchMessage: {
    alignSelf: "stretch",
    flexGrow: 0,
    fontFamily: "RHSans-ExtraLight",
    fontSize: "11px",
    fontWeight: 200,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "0.44px",
    textAlign: "left",
    color: "#000000",
    paddingTop: "40px"
  },

  quantityErrorText: {
    opacity: 1,
    transition: "opacity 0.3s ease"
  },
  quantityError: { opacity: 0 },
  quantityErrorTypo: {
    color: "var(--ReimagineWarm-RH-Red, #CA2022) !important",
    width: "130px",
    height: "15px",
    fontFamily: "RHSans-Light !important",
    fontSize: "11px !important",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "0.44px !important",
    textAlign: "left"
  },
  quantityWrapper: {
    width: "100%"
  },
  quantityTypoWrapper: {
    width: "79px",
    height: "36px",
    display: "grid"
  },
  quantityTypo: {
    color: "#000",
    cursor: "pointer",
    overflow: "hidden",
    fontSize: "13px",
    maxWidth: "200px",
    fontStyle: "normal",
    fontFamily: "RHSans-Light",
    fontWeight: 300,
    lineHeight: "120%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    letterSpacing: "0.52px"
  },
  quantityOperatorWrapper: {
    display: "grid",
    gap: "4px"
  },
  quantityInputField: {
    width: "36px",
    height: "36px"
  },
  quantityTextTypo: {
    width: "36px",
    height: "36px",
    fontSize: "11px",
    fontFamily: "RHSans-Light",
    lineHeight: "16.5px",
    letterSpacing: "0.17px",
    fontWeight: 300,
    textAlign: "center",
    border: "unset",
    outline: "none",
    backgroundColor: "inherit"
  }
}));

export interface ConfiguratorProps {
  data: Product;
  qty?: number;
  setQty?: Function;
  getQtyValue?: number;
  productPriceUi?: JSX.Element;
  addToCartButton?: JSX.Element;
  lineItem?: LineItemType;
  onPostalCode?: () => void;
  addOnsData?: AddOnDataType[];
  addOnOptionInfoToRenderButtons?: AddOnRenderButtonOptionInfo;
  handleAddOnData?: (
    addOnData: AddOnDataType,
    addOnConfigModes: AddOnConfigModes
  ) => void;
  analyticsModalStates: React.MutableRefObject<{
    dimensionModal: boolean;
    detailsModal: boolean;
  }>;
  romanceCopy?: JSX.Element;
  detailDimensionButtons?: JSX.Element;
  chosenLineItemOptionId?: any;
  chosenLineItemOptions?: any;
  setChosenLineItemOptionId: any;
  setChosenLineItemOptions: any;
  chosenLineItemUnavailableOptionId: any;
  setChosenLineItemUnavailableOptionId: any;
  chosenLineItemUnavailableOptions: any;
  setChosenLineItemUnavailableOptions: any;
  currencyCode: string;
  availableOptions?: ProductAvailableOptionSet[];
  setAvailableOptions?: any;
  setIsColorizable?: any;
  optionId?: any;
  setOptionId?: any;
  locale?: any;
  fetchOptionStatus?: (variable: any) => Promise<ProductLineItem>;
  skuSpoMessage?: JSX.Element;
  availableSwatch?: Maybe<ProductSwatchV2>;
  setAvailableSwatch?: React.Dispatch<React.SetStateAction<ProductSwatchV2>>;
  parentBaseId?: string;
  monogramOrder?: MonogramOrderProps;
  setMonogramOrder?: React.Dispatch<React.SetStateAction<MonogramOrderProps>>;
}

export const Configurator: FC<ConfiguratorProps> = memoize(props => {
  const {
    data: productData,
    productPriceUi,
    addToCartButton,
    qty,
    setQty,
    getQtyValue,
    lineItem,
    onPostalCode,
    analyticsModalStates,
    addOnsData,
    addOnOptionInfoToRenderButtons,
    handleAddOnData,
    romanceCopy,
    chosenLineItemOptionId,
    chosenLineItemOptions,
    setChosenLineItemOptionId,
    setChosenLineItemOptions,
    chosenLineItemUnavailableOptionId,
    setChosenLineItemUnavailableOptionId,
    chosenLineItemUnavailableOptions,
    setChosenLineItemUnavailableOptions,
    availableOptions,
    setAvailableOptions,
    setIsColorizable,
    fetchOptionStatus,
    skuSpoMessage,
    currencyCode,
    locale,
    availableSwatch,
    setAvailableSwatch,
    detailDimensionButtons,
    parentBaseId,
    monogramOrder,
    setMonogramOrder
  } = props;

  const baseId = `${parentBaseId}-configurator`;
  const classes = useStyles();
  const { pageContent } = usePageContent();
  const toogleModalState = booleanInput => !booleanInput;
  const typographyStyles = useTypographyStyles({
    keys: [
      "rhBaseH1",
      "rhBaseH3",
      "rhBaseBody1",
      "rhBaseCaption",
      "uppercaseText",
      "rhBaseBody2"
    ]
  });
  const [isQuantityError, setIsQuantityError] = useState(false);
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const addOnsExist =
    productData?.productAddons?.productAddonsInfo?.length &&
    ["Checkbox", "Checkbox and Panel"].includes(
      productData?.productAddonDisplayOptions || ""
    );

  useEffect(() => {
    setIsQuantityError(false);
  }, [availableOptions]);

  const quantityDecrement = useCallback(() => {
    setIsQuantityError(false);
    return setQty(qty >= 1 ? qty - 1 : 0);
  }, [qty, setQty]);

  const quantityIncrement = useCallback(() => {
    setIsQuantityError(false);
    return setQty(qty <= getQtyValue - 1 ? Number(qty) + 1 : qty);
  }, [getQtyValue, qty, setQty]);

  const quantityInput = useCallback(
    e => {
      if (!/^\d*\.?\d*$/.test(e.target.value) || e.target.value === "") {
        setQty(0);
        setIsQuantityError(false);
        return 0;
      }
      return setQty(() => {
        if (e.target.value && getQtyValue > 0) {
          if (e.target.value > getQtyValue) {
            setIsQuantityError(true);
            return getQtyValue;
          }
          setIsQuantityError(false);
          return parseInt(e.target.value, 10);
        }
        setIsQuantityError(false);
        return 0;
      });
    },
    [getQtyValue, setQty]
  );

  return (
    <>
      <Grid
        container
        className="flex items-center"
        data-testid="rh-configurator"
        id="rh-configurator"
      >
        <Grid container data-testid="productNamePriceGrid">
          <ProductNamePrice
            parentBaseId={baseId}
            key={`${baseId}-productNamePrice`}
            productData={productData}
            productPriceUi={productPriceUi}
            onPostalCode={onPostalCode}
            lineItem={lineItem}
            isNewPDPLayout={true}
          />
          {mdUp ? null : (
            <Grid
              className={"flex w-full justify-center items-center text-center"}
              data-testid="romanceCopyGrid"
            >
              {romanceCopy}
            </Grid>
          )}
          {smDown ? (
            <Grid
              className={classes?.detailDimensionContainer}
              data-testid="detailDimensionGrid"
            >
              {detailDimensionButtons}
            </Grid>
          ) : null}
          {productData?.isActive ? (
            <>
              <AvailableOptions
                parentBaseId={baseId}
                key={`${baseId}-productNamePrice`}
                currencyCode={currencyCode}
                data={productData}
                locale={locale}
                chosenLineItemOptionId={chosenLineItemOptionId}
                chosenLineItemOptions={chosenLineItemOptions}
                setChosenLineItemOptionId={setChosenLineItemOptionId}
                setChosenLineItemOptions={setChosenLineItemOptions}
                monogramOrder={monogramOrder}
                setMonogramOrder={setMonogramOrder}
                availableOptions={availableOptions}
                setAvailableOptions={setAvailableOptions}
                availableSwatch={availableSwatch}
                setAvailableSwatch={setAvailableSwatch}
                setIsColorizable={setIsColorizable}
                fetchOptionStatus={fetchOptionStatus}
                chosenLineItemUnavailableOptionId={
                  chosenLineItemUnavailableOptionId
                }
                setChosenLineItemUnavailableOptionId={
                  setChosenLineItemUnavailableOptionId
                }
                chosenLineItemUnavailableOptions={
                  chosenLineItemUnavailableOptions
                }
                setChosenLineItemUnavailableOptions={
                  setChosenLineItemUnavailableOptions
                }
              />
              {addOnsExist &&
                productData?.productAddons?.productAddonsInfo?.map(
                  (item, index) => {
                    const addOnData = addOnsData?.find(
                      prod => prod?.productId === item?.id
                    );

                    return (
                      <>
                        {index === 0 && (
                          <hr
                            key={`divider-start-${index}`}
                            data-testid="divider-start"
                            style={{
                              height: "1px",
                              width: "100%",
                              backgroundColor:
                                "var(--reimagine-warm-rh-medium)",
                              margin: "0px"
                            }}
                          />
                        )}
                        <AddOnCheckboxProductV2
                          // parentBaseId={baseId}
                          key={`${baseId}-productNamePrice`}
                          data={item}
                          productId={item?.id}
                          productConfiguration={item?.productConfiguration}
                          opts={item?.productLineItem?.availableOptions}
                          addOnData={addOnData}
                          addOnOptionInfoToRenderButtons={
                            addOnOptionInfoToRenderButtons
                          }
                          handleAddOnData={handleAddOnData}
                          fetchOptionStatus={fetchOptionStatus}
                          productLineItemOptions={
                            lineItem?.availableOptions as ProductAvailableOptionSet[]
                          }
                          mainProductAvailableOptions={availableOptions}
                          mainProductAvailableSwatch={availableSwatch}
                          mainProductChoosenOptions={chosenLineItemOptions}
                        />
                        <hr
                          key={`divider-end-${index}`}
                          data-testid="divider-end"
                          style={{
                            height: "1px",
                            width: "100%",
                            backgroundColor: "var(--reimagine-warm-rh-medium)",
                            margin: "0px"
                          }}
                        />
                      </>
                    );
                  }
                )}

              <div
                className={classes?.quantityWrapper}
                data-testid="quantity-wrapper"
              >
                <div
                  className={classes.pricingContainerStyle}
                  data-testid="price-containerGrid"
                >
                  <div
                    className={classes?.quantityTypoWrapper}
                    data-testid="qty-typoWrapper"
                  >
                    <Typography className={classes.quantityTypo}>
                      {pageContent?.QTY}
                    </Typography>
                  </div>
                  <div
                    className={classes.quantityOperatorWrapper}
                    data-testid="qty-operatorWrapper"
                  >
                    <div
                      className={classes.quantityContainerStyle}
                      data-testid="qty-container"
                    >
                      <div
                        className={classNames([
                          classes.quantityDisplayContainer,
                          classes.quantityIcon
                        ])}
                        data-testid="qty-decrement-icon"
                        onClick={quantityDecrement}
                      >
                        <Typography
                          className={classes.iconStyle}
                          variant="body2"
                        >
                          <DecrementIcon
                            stroke={qty === 0 ? "#d3d3d3" : "black"}
                            style={{ cursor: "pointer" }}
                            height={"10"}
                          />
                        </Typography>
                      </div>
                      <div
                        className={classNames([
                          classes.quantityDisplayContainer,
                          classes?.quantityInputField
                        ])}
                      >
                        <input
                          type="text"
                          pattern="^[0-9]*$"
                          inputMode="numeric"
                          data-testid="qty-input"
                          value={qty}
                          onChange={e => quantityInput(e)}
                          className={classes.quantityTextTypo}
                        />
                      </div>
                      <div
                        className={classNames([
                          classes.quantityDisplayContainer,
                          classes.quantityIcon
                        ])}
                        onClick={quantityIncrement}
                        data-testid="qty-increment-icon"
                      >
                        <Typography
                          className={classes.iconStyle}
                          variant="body2"
                        >
                          <IncrementIcon
                            stroke={qty === getQtyValue ? "#d3d3d3" : "black"}
                            style={{ cursor: "pointer" }}
                          />
                        </Typography>
                      </div>
                    </div>

                    <div
                      className={
                        isQuantityError
                          ? classes.quantityErrorText
                          : classes.quantityError
                      }
                      data-testid="qty-error-container"
                    >
                      <Typography
                        className={classes.quantityErrorTypo}
                        data-testid="qty-error-text"
                      >
                        Quantity Limited to {getQtyValue}
                      </Typography>
                    </div>
                  </div>
                </div>
                {addToCartButton}
              </div>

              <Typography
                className={classes.merchMessage}
                dangerouslySetInnerHTML={{ __html: productData?.merchMessage }}
                data-testid="merch-message"
              />

              {skuSpoMessage}

              {!!productData?.ctaLinks?.length && (
                <Grid className={classes.ctaGrid} data-testid="cta-grid">
                  {(productData?.ctaLinks || []).map((linkUrl, index) => (
                    <div>
                      <RHLink
                        className={classes.linkText}
                        onClick={() => {
                          useIsoRedirect(linkUrl.path, "windowOpen");
                          if (!processEnvServer) {
                            document.body.dispatchEvent(
                              new CustomEvent("pre-pdp-click", {
                                detail: {
                                  item: {
                                    action: he.decode(linkUrl.label || "")
                                  }
                                }
                              })
                            );
                          }
                        }}
                        underline="none"
                      >
                        <Typography
                          id="linkTextTypo"
                          data-testid="linkTextTypo"
                          className={typographyStyles.rhBaseBody1}
                        >
                          {he.decode(linkUrl.label || "")}
                        </Typography>
                      </RHLink>
                    </div>
                  ))}
                </Grid>
              )}
            </>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
});
