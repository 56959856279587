import React from "react";
import { Typography } from "utils/material-ui-core";
import RHLink from "component-rh-link";
import classNames from "classnames";
import memoize from "utils/memoize";

import template from "lodash/template";
import { useFetchModel } from "hooks/useFetchModel";
import { getMemberSavingsText } from "./utils";

const MemberSavingsText = memoize(
  ({
    showingPriceInfo,
    memberSavings,
    salePercentage,
    saleMessageEnabled,
    linkUrl,
    linkToSalePG,
    displaySaleCopy
  }) => {
    const salePercentageOutOfRange =
      salePercentage === 0 || !salePercentage || isNaN(salePercentage);

    const memberSavingsStandard =
      memberSavings?.memberSavingsMax === 25 &&
      memberSavings?.memberSavingsMin === 25;

    const { pageContent } = useFetchModel("/admin/collections", true, false);

    const memberSavingsMessage = pageContent?.MEMBER_SAVINGS_REGULAR;
    /* saleMessageEnabled === false ||
      memberSavings?.memberSavingsMax === memberSavings?.memberSavingsMin
        ? pageContent?.MEMBER_SAVINGS_REGULAR
        : salePercentage <= 0 || isNaN(salePercentage)
        ? pageContent?.MEMBER_SAVINGS_REGULAR
        : salePercentage > 0 && salePercentage < 100
        ? pageContent?.SAVE_UP_TO
        : pageContent?.MEMBER_SAVINGS;
      */

    const final = (
      <>
        <Typography
          className={classNames(
            "!text-[#CA2022] !font-primary-rhroman !text-[11px] !leading-[200%] !tracking-[0.195px]  xs:!uppercase sm:!normal-case md:!uppercase",
            "!underline hover:!cursor-pointer"
          )}
          data-testid={"collectionCardDetails-MemberSavingsText"}
        >
          {getMemberSavingsText(
            pageContent,
            salePercentage,
            memberSavings?.memberSavingsMin,
            memberSavings?.memberSavingsMax
          )}
        </Typography>
      </>
    );

    // Previous implementation - keeping it until above passes Q/A
    //
    // const final =
    //   showSaveUpto &&
    //   saleMessageEnabled &&
    //   showSaleBasedOnSalePercent &&
    //   displaySaleCopy === "true" ? (
    //     <>
    //       <Typography
    //         className={classNames(
    //           typographyStyles?.saveSelectedItems,
    //           classes.saleLink
    //         )}
    //         data-testid={"collectionCardDetails-MemberSavingsText"}
    //       >
    //         {getMemberSavingsText(pageContent, salePercentage, memberSavings?.memberSavingsMin, memberSavings?.memberSavingsMax)}
    //       </Typography>
    //       <Typography
    //         className={classNames(
    //           typographyStyles?.withMembershipMessage,
    //           classes.saleLink
    //         )}
    //       >
    //         {" "}
    //         {pageContent?.WITH_RH_MEMBERSHIP}
    //       </Typography>
    //     </>
    //   ) : (
    //     <Typography
    //       data-testid={"collectionCardDetails-MemberSavingsText"}
    //       className={classNames(
    //         salePercentage <= 0 ||
    //           isNaN(salePercentage) ||
    //           allSwatchIdsOnSale ||
    //           memberSavingsPercent === 25
    //           ? typographyStyles?.defaultMembershipMessage
    //           : [classes?.saveOffRegular, classes?.saleLink]
    //       )}
    //     >
    //       {template(memberSavingsMessage)({
    //         percent: memberSavingsPercent
    //       })}
    //     </Typography>
    //   );

    return (
      <>
        {/* {memberSavings?.percentSaleSkus == "0" && salePercentageOutOfRange ? (
          <Typography
            data-testid={"collectionCardDetails-MemberSavingsText"}
            className="!text-black !font-primary-rhthin !text-[11px] !font-thin !leading-[13.2px] !tracking-[0.165px] !uppercase"
          >
            {template(memberSavingsMessage)({
              percent: 25
            })}
          </Typography>
        ) : null} */}

        {memberSavings?.percentSaleSkus > 0 && displaySaleCopy === "true" ? (
          linkToSalePG ? (
            <RHLink
              className="hover:!cursor-pointer"
              to={`${linkUrl}&sale=true`}
            >
              {final}
            </RHLink>
          ) : (
            final
          )
        ) : (
          <Typography
            data-testid={"collectionCardDetails-MemberSavingsText"}
            className="!text-black !font-primary-rhthin !text-[11px] !font-thin !leading-[13.2px] !tracking-[0.165px] !uppercase"
          >
            {template(memberSavingsMessage)({
              percent: 25
            })}
          </Typography>
        )}
      </>
    );
  }
);

export default MemberSavingsText;
