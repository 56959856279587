import React, { FC } from "react";
import { RHLink, RHLinkProps } from "component-rh-link";
import { Brand } from "types";
import { getGlobal } from "utils-globals/getGlobal";

const { ENV_DOMAIN } = getGlobal("_env");

export interface BrandLinkProps extends RHLinkProps {
  brand: Brand;
}

export const BrandLink: FC<BrandLinkProps> = ({ brand, children, ...rest }) => {
  /*
   * now using the subdomains so is easier to manage
   * ie. rhbeachhouse.rh.com/ rhinteriors.rh.com/
   */

  return (
    <RHLink
      {...rest}
      to="/"
      data-testid={"brand-link"}
      id={"component-brand-link"}
    >
      {children}
    </RHLink>
  );
};

BrandLink.defaultProps = {};

export default BrandLink;
